import { useEffect } from "react";
import { createContainer } from "unstated-next";

import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import { useMutation, useQuery, useQueryClient } from "react-query";

import createNotification from "shared/@utils/createNotification";

export function useOrders(iboNumber) {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useOrders" });

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["newibo", iboNumber],
    async () => {
      if (iboNumber == null) {
        return null;
      }

      return makeAuthedRequest(`v1/events/orders/new/ibo/${iboNumber}`).then(
        async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }
      );
    }
  );

  useEffect(() => {
    if (isError) {
      logError(error);
    }
  }, [isError, error]);

  const revokeOrder = useMutation(
    async (orderId) => {
      const response = await makeAuthedRequest(
        `v1/events/order/revoke/orderId/${orderId}`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        const body = await response.json();
        throw new Error(
          body.error.message.message ||
            body.error.message ||
            "Failed to revoke order"
        );
      }

      return response.status;
    },
    {
      onSuccess: () => {
        // Just refresh the whole event for now
        // queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Updated",
          message: "Order has been revoked.",
          autoDismiss: true,
        });
      },
    }
  );

  const updateNote = useMutation(({ orderId, noteData }) =>
    makeAuthedRequest(`v1/events/order/note/orderId/${orderId}`, {
      method: "PUT",
      body: JSON.stringify(noteData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.error);
      }
    })
  );

  return {
    isLoading,
    isError,
    orders: data,
    revokeOrder,
    error,
    refreshIBOInfo: refetch,
    updateNote,
  };
}

export const Orders = createContainer(useOrders);
