import React, { useState } from "react";
import { Auth } from "shared/@auth/Auth";
import { useMount, useUnmount } from "react-use";
import TabActionBar from "shared/TabActionBar";
import Button from "shared/Button";
import styles from "./index.module.css";
import formatDate from "shared/@utils/formatDate";
import { descendingBy } from "shared/@utils/sort";
import { RECENT_EVENTS_IBOS_KEY } from "../OrdersByIBONew/index";
import moment from "moment-timezone";

import { Orders } from "../OrdersByIBONew/useOrders";
import { ENVIRONMENT_CONFIG } from "shared/globals";
import RefundOrderConfirmationModalNew from "Events/Components/RefundOrderConfirmationModalNew";
import SpecialNeedsModalNew from "Events/Components/SpecialNeedsModalNew";
import { useTicketSpecialNeeds } from "./useTicketSpecialNeeds";
import Loader from "shared/Loader";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWheelchair, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useRefundOrder } from "./useRefundOrder";
import { useEventPrintTicket } from "./useEventPrintTicket";
import { Modal } from "shared/Modal";
import NoteField from "../NoteField";
import { SelectUserModalNew } from "../SelectUserModalNew/SelectUserModalNew";
import Link from "shared/Link";
const { coreApiUrl } = ENVIRONMENT_CONFIG;

function appendToRecents(iboNumber, name) {
  const recentEventIbos = JSON.parse(
    sessionStorage.getItem(RECENT_EVENTS_IBOS_KEY) || "[]"
  );

  // Keep the latest 9 results.
  let newList = recentEventIbos.filter((item) => item.iboNumber !== iboNumber);
  newList.sort(descendingBy("timestamp"));
  newList = newList.slice(0, 9);

  // Prepend this one.
  newList.unshift({
    iboNumber,
    name,
    timestamp: new Date(),
  });

  sessionStorage.setItem(RECENT_EVENTS_IBOS_KEY, JSON.stringify(newList));
}

export default function OrderNew({ iboInfo, order }) {
  const paidTickets = order.events.flatMap((event) =>
    event.tickets.filter(
      (ticket) => ticket.attendeeTicketStatus === "PURCHASED" && ticket.cost > 0
    )
  );

  const unpaidTickets = order.events.flatMap((event) =>
    event.tickets.filter(
      (ticket) =>
        ticket.attendeeTicketStatus === "PURCHASED" && ticket.cost <= 0
    )
  );

  const { refreshIBOInfo, updateNote } = Orders.useContainer();
  const [applyFee, setApplyFee] = useState(false);

  const { iboNumber, displayName } = iboInfo;

  const { hasPermission } = Auth.useContainer();

  useMount(() => {
    appendToRecents(iboNumber, displayName);
  });

  useUnmount(() => {
    appendToRecents(iboNumber, displayName);
  });

  const { revokeOrder, orders } = Orders.useContainer();
  const { orderInfo, events, payments } = order;
  const users = orders.users;
  const [noteInitialText, setNoteInitialText] = useState(orderInfo.notes);
  useEffect(() => {
    setNoteInitialText(orderInfo.note ?? "");
  }, [order]);

  const saveNote = async (noteText) => {
    const orderId = orderInfo.orderId;
    const noteData = { note: noteText };
    try {
      setIsLoading(true);
      await updateNote
        .mutateAsync({ orderId, noteData })
        .then(() => refreshIBOInfo().then(() => setIsLoading(false)));
    } catch (error) {
      console.error("Error updating note: ", error);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  function onRevokeOrder() {
    let orderToRevoke = {
      orderId: orderInfo.orderId,
    };

    revokeOrder.mutate(orderInfo.orderId, {
      onSuccess: () => {
        orderInfo.status = "REVOKED";

        events.forEach((event) => {
          event.tickets.forEach((ticket) => {
            if (ticket.attendeeTicketStatus === "PURCHASED") {
              ticket.attendeeTicketStatus = "REVOKED";
            }
          });
        });

        setRevokeError(null);
        setIsRevokeConModalOpen(false);
      },
      onError: (error) => {
        setRevokeError(error.toString() || "Failed to revoke order");
      },
    });
  }

  const currentYear = formatDate(new Date(), "YY");
  const createdOnYear = formatDate(orderInfo.createdOn, "YY");

  let canRevoke =
    currentYear === createdOnYear && orderInfo.status === "PURCHASED";
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModals, setIsLoadingModals] = useState(false);
  const [refundCompleted, setRefundCompleted] = useState(false);
  const [refundMessage, setRefundMessage] = useState("");
  const [refundMessageType, setRefundMessageType] = useState("success"); // 'error' as another possible value
  const [isRevokeConModalOpen, setIsRevokeConModalOpen] = useState(false);
  const [refundMessage2, setRefundMessage2] = useState("");
  const [refundMessageType2, setRefundMessageType2] = useState("success"); // 'error' as another possible value
  const [isRefFailedModalOpen, setIsRefFailedModalOpen] = useState(false);
  const [revokeError, setRevokeError] = useState("");
  const { refundOrder, cancelOrder } = useRefundOrder();

  const onRefundOrder = async (applyFee, ticket = null) => {
    setIsLoadingModals(true);

    try {
      let response;
      let response2;
      let isCost;

      if (ticket) {
        if (ticket.cost > 0) {
          isCost = true;
          const refundData = {
            orderId: orderInfo.orderId,
            ibo: iboInfo.iboNumber,
            addFee: applyFee || false,
            tickets: [
              {
                ticketId: ticket.ticketId,
                contactId: ticket.userId,
                cost: ticket.cost,
              },
            ],
          };

          response = await refundOrder.mutateAsync(refundData);
        } else {
          isCost = false;
          const freeTicket = order.events
            .flatMap((event) => event.tickets)
            .find((t) => t.ticketId === ticket.ticketId);

          if (!freeTicket) {
            console.warn("No matching free ticket found for cancellation.");
            return;
          }

          const cancelData = [{ ticketId: freeTicket.ticketId }];
          response = await cancelOrder.mutateAsync(cancelData);
        }
      } else {
        // Process an entire order
        if (paidTickets.length > 0) {
          isCost = true;
          const refundData = {
            orderId: orderInfo.orderId,
            ibo: iboInfo.iboNumber,
            addFee: applyFee || false,
            tickets: paidTickets.map((ticket) => ({
              ticketId: ticket.ticketId,
              contactId: ticket.userId,
              cost: ticket.cost,
            })),
          };

          response = await refundOrder.mutateAsync(refundData);
          if (response && unpaidTickets.length > 0) {
            const cancelData = unpaidTickets.map((ticket) => ({
              ticketId: ticket.ticketId,
            }));

            response2 = await cancelOrder.mutateAsync(cancelData);
          }
        } else if (unpaidTickets.length > 0 && paidTickets.length == 0) {
          isCost = false;
          const cancelData = unpaidTickets.map((ticket) => ({
            ticketId: ticket.ticketId,
          }));

          response = await cancelOrder.mutateAsync(cancelData);
        }
      }

      if (response?.status === 204 || response?.status === 200) {
        setRefundMessage(
          isCost ? "Refund successful!" : "Cancellation successful!"
        );
        setRefundMessageType("success");
        setRefundCompleted(true);
      } else {
        setRefundMessage(response?.message || "Failed to process request.");
        setRefundMessageType("error");
        setRefundCompleted(true);
      }
      if (response2?.status === 204 || response2?.status === 200) {
        setRefundMessage2("Cancellation successful!");
        setRefundMessageType2("success");
      } else if (response2) {
        setRefundMessage2(response2?.message || "Failed to process request.");
        setRefundMessageType2("error");
      }
    } catch (error) {
      setRefundMessage("An unexpected error occurred.");
      setRefundMessageType("error");
    } finally {
      setIsLoadingModals(false);
    }
  };

  const [orderData, setOrderData] = useState(order);
  // useEffect(() => {
  //   // This effect will run whenever the `order` prop changes.
  //   setOrderData(order);
  // }, [order]);
  const handleCloseModal = () => {
    setIsOpen(false);
    if (refundMessage) {
      setIsLoading(true);
      setRefundCompleted(false);
      setRefundMessage("");
      setRefundMessageType("success");
      setRefundMessage2("");
      setRefundMessageType2("success");
      refreshIBOInfo().then(() => setIsLoading(false));
      // window.location.reload();
    }
  };

  const getCancelOrRefund = () => {
    let totalAmount = 0;

    order.events.map((event) =>
      event.tickets.map((tkt) => {
        if (tkt.cost > 0 || tkt.tax > 0) {
          totalAmount = 1;
        }
      })
    );

    return totalAmount;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.sectionTopMargin}>
            <TabActionBar
              style={{
                justifyContent: "space-between",
              }}
            >
              <div className={styles.headerText}>
                {orderInfo.eventType} {orderInfo.year}
              </div>
              {canRevoke && (
                <div className={styles.headerButtonGroup}>
                  <Button
                    onClick={() => {
                      setIsRevokeConModalOpen(true);
                    }}
                  >
                    Revoke
                  </Button>
                  <div className={styles.refundContainer}>
                    <Button
                      className={styles["refund-button"]}
                      onClick={() => {
                        if (order.orderInfo.fnOrderId) {
                          setIsRefFailedModalOpen(true);
                        } else {
                          setIsOpen(!isOpen);
                        }
                      }}
                    >
                      {getCancelOrRefund() == 1
                        ? "Refund Order"
                        : "Cancel Order"}
                    </Button>
                  </div>
                </div>
              )}
            </TabActionBar>
            <div className={styles.containerOrderConf}>
              <div className={styles.orderConf}>
                <span className={styles.orderConfTitle}>
                  Order Confirmation
                </span>
                <span className={styles.confirmationCode}>
                  Confirmation Code: {iboNumber}-{orderInfo.orderId}
                </span>
                <span className={styles.purchaseDate}>
                  Purchased: {formatDate(orderInfo.createdOn)}
                </span>
                <span>Order Status: {orderInfo.status}</span>
                <div className={styles.noteArea}>
                  <NoteField
                    initialText={noteInitialText}
                    onSave={(noteText) => saveNote(noteText)}
                  />
                </div>
              </div>
            </div>
            <div className="padding-top border-bottom"></div>
            {events &&
              events.map((event, index) => {
                return (
                  <div className={styles.containerTicketSum} key={index}>
                    <div className={styles.ticketSummary}>
                      <div className={styles.containerEvent}>
                        <div className={styles.containereventId}>
                          {/* Event Name */}
                          <span className={styles.eventId}>
                            {!event.city && !event.state
                              ? "Virtual Event"
                              : `${event.city}, ${event.state}`}
                          </span>
                        </div>
                        <div className={styles.containerstartsOn}>
                          {/* Start Date */}
                          <span className={styles.startsOn}>
                            {formatDate(event.startsOn, "MM.DD.YY")}
                          </span>
                        </div>
                        <div className={styles.containerDateSeparator}>
                          {/* Date Seperator */}
                          <span className={styles.dateSeparator}>
                            &nbsp;-&nbsp;
                          </span>
                        </div>
                        <div className={styles.containerendsOn}>
                          {/* End Date */}
                          <span className={styles.endsOn}>
                            {formatDate(event.endsOn, "MM.DD.YY")}
                          </span>
                        </div>
                      </div>
                      <div className={styles.containerTickets}>
                        {[...event.tickets]
                          .sort((a, b) => {
                            const firstName = displayName.split("&")[0].trim();

                            const aMatches = a.name.startsWith(firstName);
                            const bMatches = b.name.startsWith(firstName);

                            if (aMatches && !bMatches) return -1;
                            if (!aMatches && bMatches) return 1;

                            return a.name.localeCompare(b.name);
                          })
                          .map((ticket, ticketId) => {
                            let ticketColor = styles.ticketActive;
                            if (ticket.attendeeTicketStatus === "REFUNDED") {
                              ticketColor = styles.ticketRefunded;
                            } else if (
                              ticket.attendeeTicketStatus === "REVOKED"
                            ) {
                              ticketColor = styles.ticketRevoked;
                            }

                            return (
                              <div
                                key={ticketId}
                                className={styles.ticket + " " + ticketColor}
                              >
                                <div className={styles.left}>
                                  {/* Ticket > ID */}
                                  <div className={styles.ticketId}>
                                    Ticket #{ticket.ticketId}
                                  </div>
                                  {/* Ticket > Price */}
                                  <div className={styles.ticketPrice}>
                                    ${ticket.cost.toFixed(2)}{" "}
                                    {ticket.tax !== 0
                                      ? `+ $${ticket.tax.toFixed(2)} Tax`
                                      : ""}
                                  </div>
                                  {/* Ticket > Type */}
                                  <div className={styles.ticketId}>
                                    {ticket.ticketName}
                                  </div>
                                  {/* Ticket Type */}
                                  <div className={styles.ticketType}>
                                    {ticket.attendeeTicketStatus !==
                                      "PURCHASED" && (
                                      <span>
                                        *** {ticket.attendeeTicketStatus} ***
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className={styles.right}>
                                  {ticket.attendeeTicketStatus ===
                                    "PURCHASED" && (
                                    <CheckInPrint
                                      ticket={ticket}
                                      order={order}
                                      iboInfo={iboInfo}
                                      isLoading={isLoading}
                                      setIsLoading={setIsLoading}
                                      event={event}
                                      onRefundOrder={onRefundOrder}
                                      refundCompleted={refundCompleted}
                                      setRefundCompleted={setRefundCompleted}
                                      refundMessage={refundMessage}
                                      setRefundMessage={setRefundMessage}
                                      refundMessageType={refundMessageType}
                                      setRefundMessageType={
                                        setRefundMessageType
                                      }
                                      refundMessage2={refundMessage2}
                                      setRefundMessage2={setRefundMessage2}
                                      refundMessageType2={refundMessageType2}
                                      setRefundMessageType2={
                                        setRefundMessageType2
                                      }
                                      setIsRefFailedModalOpen={
                                        setIsRefFailedModalOpen
                                      }
                                    />
                                  )}
                                  {/* <span></span> */}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="padding-top border-bottom"></div>
            {payments.map((payment, ctr) => {
              return (
                <div className={styles.containerPaymentConf}>
                  <div className={styles.paymentConf}>
                    <span className={styles.paymentConfTitle}>
                      Transaction #{ctr + 1}
                    </span>
                    <span className={styles.paymentConfTitle}>
                      {payment.ccNickname}
                    </span>
                    <span className={styles.amountPaid}>
                      Amount Paid:{" "}
                      <span className={styles.amountPaidValue}>
                        ${(payment?.paid ?? 0).toFixed(2)}
                      </span>
                    </span>
                    <span className={styles.paymentMethod}>
                      ************{payment.ccLastFour}
                    </span>
                    <span className={styles.paymentMethod}>
                      Status: {payment.transactionStatus}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="padding-top border-bottom"></div>

          <Modal
            isOpen={isRevokeConModalOpen}
            title={"Revoke Confirmation"}
            onClose={() => setIsRevokeConModalOpen(false)}
            className={styles.modal}
            children={
              <div className={styles.container}>
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {revokeError && (
                        <div
                          className={`${styles.responseAlerts} ${styles.warningMargin} ${styles.responseAlertError}`}
                        >
                          {revokeError}
                        </div>
                      )}
                      {!revokeError && (
                        <>
                          <div className={styles.refundConfirmationText}>
                            Are you sure you want to revoke this order? This
                            action cannot be undone.
                          </div>
                          <div>
                            <button
                              className={`${styles.button} ${styles.refundBtn}`}
                              onClick={() => onRevokeOrder()}
                            >
                              REVOKE
                            </button>
                            <button
                              className={styles.button}
                              onClick={() => setIsRevokeConModalOpen(false)}
                            >
                              CLOSE
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            }
          />

          <Modal
            isOpen={isRefFailedModalOpen}
            title={`Cancellation Failed`}
            onClose={() => setIsRefFailedModalOpen(false)}
            className={styles.modal}
            children={
              <div className={styles.container}>
                <>
                  {!revokeError && (
                    <>
                      <div
                        className={`${styles.responseAlerts} ${styles.warningMargin} ${styles.responseAlertError}`}
                      >
                        This order originated in the legacy system and must be
                        canceled there.
                        <br /> <br /> *Note: If cancelling ticket for one IBO on
                        an order that contains tickets for two IBOs, make sure
                        to come back to this page and set the remaining ticket
                        to the proper IBO
                      </div>
                      {/* <div className={styles.refundConfirmationText}>
                        Are you sure you want to revoke this order? This action
                        cannot be undone.
                      </div> */}
                      <div>
                        <Link
                          to={`/events/orders/legacy/ibo/${iboNumber}`}
                          onClick={() => {}}
                        >
                          <button
                            className={`${styles.goToButton}`}
                            onClick={() => {}}
                          >
                            GO TO ORDER
                          </button>
                        </Link>
                        <button
                          className={styles.button}
                          onClick={() => setIsRefFailedModalOpen(false)}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}
                </>
              </div>
            }
          />

          <RefundOrderConfirmationModalNew
            isOpen={isOpen}
            onCollapse={() => {
              setIsOpen(!isOpen);
              setIsLoading(true);
              setRefundCompleted(false);
              setRefundMessage("");
              setRefundMessageType("success");
              setRefundMessage2("");
              setRefundMessageType2("success");
              refreshIBOInfo().then(() => setIsLoading(false));
            }}
            onClose={handleCloseModal}
            onClick={onRefundOrder}
            isLoading={isLoadingModals}
            refundCompleted={refundCompleted}
            applyFee={applyFee}
            setApplyFee={setApplyFee}
            refundMessage={refundMessage}
            refundMessageType={refundMessageType}
            refundMessage2={refundMessage2}
            refundMessageType2={refundMessageType2}
            titleType={getCancelOrRefund()}
          />
        </>
      )}
    </>
  );
}

function CheckInPrint({
  ticket,
  order,
  iboInfo,
  isLoading,
  setIsLoading,
  event,
  onRefundOrder,
  refundCompleted,
  setRefundCompleted,
  refundMessage,
  setRefundMessage,
  refundMessageType,
  setRefundMessageType,
  refundMessage2,
  setRefundMessage2,
  refundMessageType2,
  setRefundMessageType2,
  setIsRefFailedModalOpen,
}) {
  //
  const { fetchPrintTicket } = useEventPrintTicket();
  const { orders, refreshIBOInfo } = Orders.useContainer();
  const [contactId, setContactId] = useState(ticket.contactId);
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [showADAModal, setShowADAModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const { hasPermission } = Auth.useContainer();
  const [applyFee, setApplyFee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ticketScans, setTicketScans] = useState(ticket.scans ?? []);
  useEffect(() => {
    if (ticket) {
      setTicketScans(ticket.scans);
      setContactId(ticket.contactId);
    }
  }, [order, ticket, orders]);

  const users = orders.users;

  const tickets = orders.orders
    .filter((a) => a.orderInfo.orderId == order.orderInfo.orderId)
    .flatMap((a) => a.events)
    .filter((a) => a.eventId == ticket.eventId)
    .flatMap((a) => a.tickets);

  function shouldShowContact(u) {
    return (
      tickets
        .filter((a) => a.contactId != null)
        .map((a) => a.contactId)
        .indexOf(u.contactId) < 0
    );
  }

  async function printTicket() {
    try {
      const response = await fetchPrintTicket.mutateAsync(ticket.ticketId);

      if (!response.ok) {
        console.error("Failed to fetch ticket:", response.statusText);
        return;
      }

      const jsonData = await response.json();

      const base64Data = jsonData.ticket;

      if (!base64Data.startsWith("data:application/pdf")) {
        console.error("Unexpected format, received:", base64Data);
        return;
      }

      const base64String = base64Data.split(",").pop();

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error printing ticket:", error);
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (refundMessage) {
      setIsLoading(true);
      setRefundCompleted(false);
      setRefundMessage("");
      setRefundMessageType("success");
      setRefundMessage2("");
      setRefundMessageType2("success");
      refreshIBOInfo().then(() => setIsLoading(false));
    }
  };

  const [isScanModalOpen, setIsScanModalOpen] = useState(false);
  const [isSpecialNeedsModalOpen, setIsSpecialNeedsModalOpen] = useState(false);
  const handleQRCodeClick = () => {
    setIsScanModalOpen(true);
  };
  const handleAddSpecialNeedsClick = () => {
    setIsSpecialNeedsModalOpen(true);
  };
  const [specialNeedsMessage, setSpecialNeedsMessage] = useState(null);
  const [clearLoader, setClearLoader] = useState(false);

  const { updateSpecialNeedsMutation } = useTicketSpecialNeeds();
  const [isLoadingModals, setIsLoadingModals] = useState(false);

  const [messageType, setMessageType] = useState("success"); // 'error' as another possible value
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetchData = async () => {
      await queryClient.refetchQueries(
        `v1/events/view/events/sale/ibo/${iboInfo.iboNumber}`
      );
    };

    // Call refetchData function after closing the modal
    if (!isSpecialNeedsModalOpen) {
      refetchData();
    }
  }, [isSpecialNeedsModalOpen, iboInfo.iboNumber, queryClient]);

  const handleSpecialNeedsSave = async (updatedTicket) => {
    setSpecialNeedsMessage("");
    setIsLoadingModals(true);
    try {
      const body = {
        eventId: event.eventId,
        userId: updatedTicket.userId,
        adaValues: [
          ...updatedTicket.adaValues.map((ada) => {
            return {
              component: ada.component,
              eventAdaID: ada.eventAdaID,
              parameter1: ada.parameter1 || ada.parameter2,
            };
          }),
        ],
      };
      const response = await updateSpecialNeedsMutation.mutateAsync({
        iboNumber: iboInfo.iboNumber,
        body: body,
      });

      if (response.status === 200) {
        setSpecialNeedsMessage("Special needs successfully updated.");
        setMessageType("success");
      } else if (response.status === 409) {
        setSpecialNeedsMessage(
          "Conflict: Your changes conflict with existing data."
        );
        setMessageType("error");
      } else {
        setSpecialNeedsMessage(
          "Error updating special needs. Please try again later."
        );
        setMessageType("error");
      }
    } catch (error) {
      setSpecialNeedsMessage(
        "Error updating special needs. Please try again later."
      );
      setMessageType("error");
      console.error("Error updating special needs:", error);
    } finally {
      await refreshIBOInfo().then(() => setClearLoader(true));
      setIsLoadingModals(false);
    }
  };

  const handleSpecialNeedsCancel = () => {
    setSpecialNeedsMessage("");
    setIsSpecialNeedsModalOpen(false);
  };

  const primaryUser = users.filter((user) => {
    const firstSegment = iboInfo.displayName.split("&")[0].trim();
    let firstName;
    if (firstSegment.includes(",")) {
      firstName = firstSegment.split(",")[1]?.trim();
    } else {
      firstName = firstSegment.split(" ")[0].trim();
    }
    // console.log("Checking User:", user.strFirst, "against", firstName);
    return (
      firstName &&
      user.strFirst.toLowerCase().startsWith(firstName.toLowerCase())
    );
  });

  return (
    <>
      <div className={styles.buttonGroups}>
        {ticket.name}
        &nbsp;
        {users.length > 1 && (
          <>
            <Button onClick={() => setIsSelectModalOpen(true)}>
              <svg
                width="16"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="7" r="4" />
                <path d="M5 21c0-4 3-6 7-6s7 2 7 6" />
              </svg>
            </Button>
            &nbsp;
          </>
        )}
        {event.city && event.state && event.adaOptions.length > 0 && (
          <>
            <Button onClick={handleAddSpecialNeedsClick}>
              <FontAwesomeIcon icon={faWheelchair} />
            </Button>
            &nbsp;
          </>
        )}
        {event.city && event.state && (
          <>
            <Button onClick={handleQRCodeClick}>
              <FontAwesomeIcon icon={faQrcode} />
            </Button>
            &nbsp;
            <Button onClick={printTicket}>Print</Button>
            &nbsp;
          </>
        )}
        <Button
          className={styles["refund-button"]}
          onClick={() => {
            if (order.orderInfo.fnOrderId) {
              setIsRefFailedModalOpen(true);
            } else {
              setIsModalOpen(!isModalOpen);
            }
          }}
        >
          {ticket.cost <= 0 ? "Cancel" : "Refund"}
        </Button>
      </div>

      {isScanModalOpen && (
        <div className="modalBackdrop">
          <Modal
            title="Tickets Scanned"
            isOpen={isScanModalOpen}
            onClose={() => setIsScanModalOpen(false)}
            children={
              <>
                <div className={styles["table-container"]}>
                  {ticketScans.length <= 0 ? (
                    <div>There are no scans for this ticket</div>
                  ) : (
                    <>
                      <div className={styles.scansContainer}>
                        {/* {ticketScans.map((scan, index) => {
                          const previousScan = ticketScans[index - 1] || {};

                          // Always render session, location, and station as separate units
                          const isSameSessionLocationStation =
                            previousScan.session === scan.session &&
                            previousScan.location === scan.location &&
                            previousScan.station === scan.station;

                          return (
                            <div key={index} className={styles.scan}>
                              {!isSameSessionLocationStation && (
                                <>
                                  <div className={styles.session}>
                                    {scan.session}
                                  </div>
                                  <div className={styles.location}>
                                    {scan.location}
                                  </div>
                                  <div className={styles.station}>
                                    Station {scan.station}
                                  </div>
                                </>
                              )}

                              <div className={styles.scannedOn}>
                                {moment
                                  .tz(scan.scannedOn, moment.tz.guess())
                                  .format("MM/DD/YYYY h:mm A z")}
                              </div>
                            </div>
                          );
                        })} */}
                        {ticketScans.map((scan, index) => {
                          const previousScan = ticketScans[index - 1] || {};

                          // Display the session only if it's different from the previous scan
                          const displaySession =
                            previousScan.session !== scan.session;

                          // Always display location if it's different from the previous scan OR if it's the same session but a new location
                          const displayLocation =
                            previousScan.location !== scan.location ||
                            displaySession;

                          // Always display station if it's a new station within the same location or a new location
                          const displayStation =
                            previousScan.station !== scan.station ||
                            displayLocation;

                          return (
                            <div key={index} className={styles.scan}>
                              {displaySession && (
                                <div className={styles.session}>
                                  {scan.session}
                                </div>
                              )}

                              {displayLocation && (
                                <div className={styles.location}>
                                  {scan.location}
                                </div>
                              )}

                              {displayStation && (
                                <div className={styles.station}>
                                  Station {scan.station}
                                </div>
                              )}

                              <div className={styles.scannedOn}>
                                {moment
                                  .tz(scan.scannedOn, moment.tz.guess())
                                  .format("MM/DD/YYYY h:mm A z")}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* <table className={styles["custom-table"]}>
                        <thead>
                          <tr>
                            <th>Session</th>
                            <th>Location</th>
                            <th>Station</th>
                            <th>Scanned On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketScans.map((scan, index) => (
                            <tr key={index}>
                              <td>{scan.session}</td>
                              <td>{scan.location}</td>
                              <td>{`Station ${scan.station}`}</td>
                              <td>
                                {moment
                                  .tz(scan.scannedOn, moment.tz.guess())
                                  .format("MM/DD/YYYY h:mm A z")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table> */}
                    </>
                  )}
                </div>
              </>
            }
          />
        </div>
      )}

      {isSpecialNeedsModalOpen && (
        <div className="modalBackdrop">
          <SpecialNeedsModalNew
            ticket={ticket}
            onSave={handleSpecialNeedsSave}
            onCancel={handleSpecialNeedsCancel}
            message={specialNeedsMessage}
            messageType={messageType}
            isLoading={isLoadingModals}
            iboNum={iboInfo.iboNumber}
            adaDescription={event.adaDescription}
            adaOptions={event.adaOptions}
            clearLoader={clearLoader}
          />
        </div>
      )}

      <RefundOrderConfirmationModalNew
        isOpen={isModalOpen}
        onCollapse={() => {
          setIsModalOpen(!isModalOpen);
          setIsLoading(true);
          setRefundCompleted(false);
          setRefundMessage("");
          setRefundMessageType("success");
          setRefundMessage2("");
          setRefundMessageType2("success");
          refreshIBOInfo().then(() => setIsLoading(false));
        }}
        onClose={handleCloseModal}
        onClick={(applyFee) => onRefundOrder(applyFee, ticket)}
        isLoading={isLoadingModals}
        refundCompleted={refundCompleted}
        applyFee={applyFee}
        setApplyFee={setApplyFee}
        refundMessage={refundMessage}
        refundMessageType={refundMessageType}
        refundMessage2={refundMessage2}
        refundMessageType2={refundMessageType2}
        ticketCost={ticket.cost}
        titleType={ticket.cost > 0 ? 1 : 0}
      />
      {isSelectModalOpen && (
        <SelectUserModalNew
          isOpen={isSelectModalOpen}
          onCollapse={() => {
            setIsSelectModalOpen(false);
            // setIsLoading(true);
            // refreshIBOInfo().then(() => setIsLoading(false));
          }}
          onClose={() => {
            setIsSelectModalOpen(false);
            // setIsLoading(true);
            // refreshIBOInfo().then(() => setIsLoading(false));
          }}
          onClick={(tickets) => {
            setIsLoading(true);
            refreshIBOInfo().then(() => setIsLoading(false));
          }}
          iboNumber={iboInfo.iboNumber}
          eventId={event.eventId}
          users={users}
          primaryUser={primaryUser[0]?.contactId}
        />
      )}

      {/* {isSpecialNeedsModalOpen && (
        <div className="modalBackdrop">
          <SpecialNeedsModalNew
            ticket={{
              ...ticket,
              adaValues: bitwiseToArray(ticket.adaValue),
            }}
            onSave={handleSpecialNeedsSave}
            onCancel={handleSpecialNeedsCancel}
            message={specialNeedsMessage}
            messageType={messageType}
            isLoading={isLoading}
            iboNum={iboInfo.iboNumber}
          />
        </div>
      )} */}
    </>
  );
}
