import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Button from "shared/Button";
import AddButton from "../AddButton";
import Checkbox from "shared/@forms/Checkbox";
// import Select from "../Select";
import { Select } from "shared/Select/Select";
import Input from "shared/@forms/Input";
import InputItem from "../InputItem";

import styles from "./TicketGroupItem.module.css";
import classNames from "classnames";

/**Displays the information about a ticket option. When edit is clicked it will open the edit ticket modal. */
export function TicketGroupItem({
  ticket,
  onEdit,
  isMajorV1,
  isV2,
  onAddGroup,
  onRemoveGroup,
  onRemoveCreditType,
  onCheckbox,
  onSelect,
  onCreditChange,
  onEditDates,
  isSub,
  onInputChange,
  onRemoveTicket,
  isPublished,
  isVirtual,
}) {
  return isMajorV1 ? (
    <div className={styles.ticketGroupItemContainerV1}>
      <span>{ticket.ticketId}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span className={styles.price}>{ticket.cancelPrice}</span>
      {/* <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button> */}
    </div>
  ) : isV2 ? (
    <div
      className={
        isVirtual
          ? styles.ticketGroupItemContainerV2
          : styles.ticketGroupItemContainerV2Virtual
      }
    >
      <span>
        {ticket.name?.permission == "write" && !isPublished ? (
          <div className={styles.subItemName}>
            <Input
              value={ticket.name?.saved ?? ticket.name?.value ?? ""}
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "name")}
            />
          </div>
        ) : ticket.name?.permission !== "hidden" ? (
          ticket.name?.saved ?? ticket.name?.value
        ) : (
          <></>
        )}
      </span>
      <span>
        {ticket.price?.permission == "write" && !isPublished ? (
          <div className={styles.subItem}>
            <Input
              type="number"
              value={
                (ticket.price?.saved ?? ticket.price?.value) == null
                  ? ""
                  : ticket.price?.saved ?? ticket.price?.value
              }
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "price")}
            />
          </div>
        ) : ticket.price?.permission !== "hidden" ? (
          ` $${ticket.price?.saved ?? ticket.price?.value} USD`
        ) : (
          <></>
        )}
      </span>
      <span>
        {ticket.commonItemId?.permission == "write" && !isPublished ? (
          <div className={styles.selectContainer}>
            <Select
              options={[
                "FE_ADM",
                "FE_CANCEL",
                "FE_COMP",
                "FE_FPL",
                "FE_STD",
                "FE_STDPL",
                "FE_VIP",
                "FE_VR_LS",
                "FE_VR_PR",
                "FR_ADM",
                "FR_CANCEL",
                "FR_COMP",
                "FR_FPL",
                "FR_STD",
                "FR_STDPL",
                "FR_VIP",
                "FR_VR_LS",
                "FR_VR_PR",
                "LF_ADM",
                "LF_CAD",
                "LF_USD",
                "LF_VIP",
                "LF_VR_LS",
                "LF_VR_PR",
                "LS_ADM",
                "LS_CANCEL",
                "LS_COMP",
                "LS_FPL",
                "LS_STD",
                "LS_STDPL",
                "LS_VIP",
                "LS_VR_LS",
                "LS_VR_PR",
                "SL_CANCEL",
                "SL_FPL",
                "SL_STD",
                "SL_STDPL",
                "SL_VIP",
                "SL_VR_LS",
                "SL_VR_PR",
              ]}
              placeholder={
                ticket?.commonItemId?.saved ??
                ticket?.commonItemId?.value ??
                "Select"
              }
              onChange={(newValue) =>
                onSelect(newValue, ticket.id, "commonItemId")
              }
              removeSelectedOption={false}
            />
          </div>
        ) : ticket.commonItemId?.permission !== "hidden" ? (
          ticket?.commonItemId.saved ?? ticket?.commonItemId.value
        ) : (
          <></>
        )}
      </span>

      {ticket.cancelFee?.permission == "write" && !isPublished ? (
        <span>
          <div className={styles.subItem}>
            <Input
              type="number"
              value={
                (ticket.cancelFee?.saved ?? ticket.cancelFee?.value) == null
                  ? ""
                  : ticket.cancelFee?.saved ?? ticket.cancelFee?.value
              }
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "cancelFee")}
            />
          </div>
        </span>
      ) : ticket.cancelFee?.permission !== "hidden" ? (
        <span>{ticket.cancelFee.saved ?? ticket.cancelFee.value}</span>
      ) : (
        <></>
      )}
      <span>
        {ticket.cancelFeeItemId?.permission == "write" && !isPublished ? (
          <div className={styles.selectContainer}>
            <Select
              options={[
                "FE_ADM",
                "FE_CANCEL",
                "FE_COMP",
                "FE_FPL",
                "FE_STD",
                "FE_STDPL",
                "FE_VIP",
                "FE_VR_LS",
                "FE_VR_PR",
                "FR_ADM",
                "FR_CANCEL",
                "FR_COMP",
                "FR_FPL",
                "FR_STD",
                "FR_STDPL",
                "FR_VIP",
                "FR_VR_LS",
                "FR_VR_PR",
                "LF_ADM",
                "LF_CAD",
                "LF_USD",
                "LF_VIP",
                "LF_VR_LS",
                "LF_VR_PR",
                "LS_ADM",
                "LS_CANCEL",
                "LS_COMP",
                "LS_FPL",
                "LS_STD",
                "LS_STDPL",
                "LS_VIP",
                "LS_VR_LS",
                "LS_VR_PR",
                "SL_CANCEL",
                "SL_FPL",
                "SL_STD",
                "SL_STDPL",
                "SL_VIP",
                "SL_VR_LS",
                "SL_VR_PR",
              ]}
              placeholder={
                ticket?.cancelFeeItemId?.saved ??
                ticket?.cancelFeeItemId?.value ??
                "Select"
              }
              onChange={(newValue) =>
                onSelect(newValue, ticket.id, "cancelFeeItemId")
              }
              removeSelectedOption={false}
            />
          </div>
        ) : ticket.cancelFeeItemId?.permission !== "hidden" ? (
          ticket?.cancelFeeItemId.saved ?? ticket?.cancelFeeItemId.value
        ) : (
          <></>
        )}
      </span>

      {!isVirtual && (
        <span>
          {ticket.virtualCommonItemId?.permission == "write" && !isPublished ? (
            <div className={styles.selectContainer}>
              <Select
                options={[
                  "FE_ADM",
                  "FE_CANCEL",
                  "FE_COMP",
                  "FE_FPL",
                  "FE_STD",
                  "FE_STDPL",
                  "FE_VIP",
                  "FE_VR_LS",
                  "FE_VR_PR",
                  "FR_ADM",
                  "FR_CANCEL",
                  "FR_COMP",
                  "FR_FPL",
                  "FR_STD",
                  "FR_STDPL",
                  "FR_VIP",
                  "FR_VR_LS",
                  "FR_VR_PR",
                  "LF_ADM",
                  "LF_CAD",
                  "LF_USD",
                  "LF_VIP",
                  "LF_VR_LS",
                  "LF_VR_PR",
                  "LS_ADM",
                  "LS_CANCEL",
                  "LS_COMP",
                  "LS_FPL",
                  "LS_STD",
                  "LS_STDPL",
                  "LS_VIP",
                  "LS_VR_LS",
                  "LS_VR_PR",
                  "SL_CANCEL",
                  "SL_FPL",
                  "SL_STD",
                  "SL_STDPL",
                  "SL_VIP",
                  "SL_VR_LS",
                  "SL_VR_PR",
                ]}
                placeholder={
                  ticket?.virtualCommonItemId?.saved ??
                  ticket?.virtualCommonItemId?.value ??
                  "Select"
                }
                onChange={(newValue) =>
                  onSelect(newValue, ticket.id, "virtualCommonItemId")
                }
                removeSelectedOption={false}
              />
            </div>
          ) : ticket.virtualCommonItemId?.permission !== "hidden" ? (
            ticket?.virtualCommonItemId.saved ??
            ticket?.virtualCommonItemId.value
          ) : (
            <></>
          )}
        </span>
      )}

      <span className={styles.creditTypesContainer}>
        {ticket.acceptedCreditTypes?.permission == "write" && !isPublished ? (
          <>
            <div className={styles.editCreditTypesButtonContainer}>
              <Button
                className={styles.editCreditTypesButton}
                children={"Edit"}
                onClick={() => onCreditChange(ticket.id)}
              />
            </div>
            {/* <div className={styles.creditTypesList}>
          {(ticket.acceptedCreditTypes.saved
            ? ticket.acceptedCreditTypes.saved
            : ticket.acceptedCreditTypes?.value || []
          )?.map((type) => (
            <span key={type.id} className={styles.creditTypesItem}>
              {type.creditTypeId}
              <div
                className={styles.removeCreditType}
                onClick={() => onRemoveCreditType(type, ticket.id)}
              >
                &times;
              </div>
            </span>
          ))}
        </div> */}
          </>
        ) : ticket.acceptedCreditTypes?.permission !== "hidden" ? (
          <div className={styles.creditTypesList}>
            {(ticket.acceptedCreditTypes.saved
              ? ticket.acceptedCreditTypes.saved
              : ticket.acceptedCreditTypes?.value || []
            )?.map((type) => (
              <span key={type.id} className={styles.creditTypesItem}>
                {type.creditTypeId}
                {/* <div
                className={styles.removeCreditType}
                onClick={() => onRemoveCreditType(type, ticket.id)}
              >
                &times;
              </div> */}
              </span>
            ))}
          </div>
        ) : (
          <></>
        )}
      </span>

      <span className={styles.groups}>
        {ticket.limitedToGroups?.permission == "write" && !isPublished ? (
          <div className={styles.groupsContainer}>
            <div className={styles.addButtonWrapper}>
              <div
                className={styles.addGroupsButton}
                onClick={() => onAddGroup(ticket.id)}
              >
                <AddButton description={"Add Groups"} color="#2A3847" />
              </div>
            </div>
            <div className={styles.groupsGrid}>
              {(ticket?.limitedToGroups?.saved
                ? ticket.limitedToGroups?.saved
                : ticket.limitedToGroups?.value
              )?.map((group) => (
                <div
                  className={styles.groupItem}
                  key={group?.id || group?.name}
                >
                  {group?.name || "No Name For Group"}
                  <div
                    className={styles.removeGroup}
                    onClick={() => onRemoveGroup(group, ticket.id)}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : ticket.limitedToGroups?.permission !== "hidden" ? (
          <div className={styles.groupsContainer}>
            <div className={styles.groupsGrid}>
              {(ticket?.limitedToGroups?.saved
                ? ticket.limitedToGroups?.saved
                : ticket.limitedToGroups?.value
              )?.map((group) => (
                <div
                  className={styles.groupItem}
                  key={group?.id || group?.name}
                >
                  {group?.name || "No Name For Group"}
                  <div
                    className={styles.removeGroup}
                    onClick={() => onRemoveGroup(group, ticket.id)}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </span>

      <span className={styles.checkbox}>
        <div className={styles.editCreditTypesButtonContainer}>
          <Button
            className={styles.editCreditTypesButton}
            children={!isPublished ? "Edit" : "View"}
            onClick={() => onEditDates(ticket.id)}
          />
        </div>
      </span>

      <span className={styles.checkbox}>
        <div className={styles.editCreditTypesButtonContainer}>
          <Button
            className={styles.removeButton}
            children={"Remove"}
            onClick={() => onRemoveTicket(ticket.id)}
          />
        </div>
      </span>
    </div>
  ) : (
    <div className={styles.ticketGroupItemContainer}>
      <span>{ticket.id}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span>{`${ticket.startDate} ${ticket.startTime}`}</span>
      <span>{`${ticket.endDate} ${ticket.endTime}`}</span>
      <span className={styles.timezone}>{ticket.timeZone}</span>
      <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button>
    </div>
  );
}

TicketGroupItem.propTypes = {
  /**Object with the tickets info (id-String, name-String, price-Number, timeZone-String, startDate-String, endDate-String, startTime-String, endTime-String)  */
  ticket: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    timeZone: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  /**Function called to edit the ticket information. Opens the edit ticket modal */
  onEdit: PropTypes.func,
  isMajorV1: PropTypes.bool,
};
