import React, { useState, useEffect } from "react"; // Import useState
import PropTypes from "prop-types";
import styles from "./RefundOrderConfirmationModalNew.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";
import Loader from "shared/Loader";

/**
 * Modal that opens when the user clicks the refund button for an order.
 */

export function RefundOrderConfirmationModalNew({
  isOpen,
  onCollapse,
  onClose,
  onClick,
  isLoading,
  refundCompleted,
  refundMessage,
  refundMessageType,
  refundMessage2,
  refundMessageType2,
  titleType = 1,
}) {
  const [localRefundMessage, setLocalRefundMessage] = useState("");
  const [localRefundMessage2, setLocalRefundMessage2] = useState("");
  useEffect(() => {
    if (refundCompleted && refundMessage) {
      setLocalRefundMessage(refundMessage);
    }
    if (refundCompleted && refundMessage2) {
      setLocalRefundMessage2(refundMessage2);
    }
  }, [refundCompleted, refundMessage, refundMessage2]);
  const [applyFee, setApplyFee] = useState(false);

  const handleRefundClick = () => {
    onClick(applyFee);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCollapse();
    }
  };

  return (
    <div className={styles.container}>
      <Modal
        title={
          titleType == 1 ? "Refund Confirmation" : "Cancellation Confirmation"
        }
        isOpen={isOpen}
        onClose={handleClose}
        className={styles.modal}
      >
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {localRefundMessage && (
                <div
                  className={
                    refundMessageType === "success"
                      ? styles.success
                      : styles.danger
                  }
                >
                  {localRefundMessage}
                </div>
              )}
              {localRefundMessage2 && (
                <div
                  className={
                    refundMessageType2 === "success"
                      ? styles.success
                      : styles.danger
                  }
                >
                  {localRefundMessage2}
                </div>
              )}
              {!refundCompleted && (
                <>
                  {titleType == 1 && (
                    <>
                      <label className={styles.label}>
                        Apply a cancellation fee:
                      </label>
                      <input
                        type="checkbox"
                        checked={applyFee}
                        onChange={() => setApplyFee(!applyFee)}
                      />
                    </>
                  )}
                  <div className={styles.confirm}>
                    Are you sure you want to{" "}
                    {titleType == 1 ? "refund" : "cancel"} this order?
                  </div>
                  <div>
                    <button
                      className={classNames(styles.button, styles.refundBtn)}
                      onClick={handleRefundClick}
                    >
                      {titleType == 1 ? "REFUND" : "CANCEL"}
                    </button>
                    <button className={styles.button} onClick={onCollapse}>
                      CLOSE
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </Modal>
    </div>
  );
}

RefundOrderConfirmationModalNew.propTypes = {
  /**
   * Determines if the modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Function called when cancel is clicked
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when refund is clicked
   */
  onClick: PropTypes.func,
};
