import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import { useQuery, useQueryClient, useMutation } from "react-query";
import createNotification from "shared/@utils/createNotification";
import env from "../../shared/regionConfig";

export function useEvent(eventId) {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useEvent" });

  const [resolvedEventId, setResolvedEventId] = useState(eventId);
  const [loggedError, setLoggedError] = useState(null);
  const API_BASE_URL = env.apiBase;

  useEffect(() => {
    let isMounted = true;

    async function resolveEventId() {
      if (!eventId) return;

      try {
        const response = await makeAuthedRequest(
          `${API_BASE_URL}/v1/events/scanner/event/id/${encodeURIComponent(
            eventId
          )}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok)
          throw new Error(
            `Failed to fetch correct eventId. Status: ${response.status}`
          );

        const eventData = await response.json();
        if (isMounted) setResolvedEventId(eventData?.eventId || eventId);
      } catch (error) {
        logError(error);
        if (isMounted) setResolvedEventId(eventId);
      }
    }

    resolveEventId();

    return () => {
      isMounted = false;
    };
  }, [eventId]);

  const fetchEvent = async () => {
    if (!resolvedEventId) return null;

    try {
      const response = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/scanner/event/id/${resolvedEventId}`,
        {
          method: "GET",
          headers: { Accept: "application/json" },
        }
      );

      if (!response.ok) {
        const body = await response.json().catch(() => ({}));
        throw new Error(
          body.message || `API Error: ${response.status} ${response.statusText}`
        );
      }

      const eventData = await response.json();
      if (!eventData || !eventData.eventId) return null;

      return {
        ...eventData,
        displayName: eventData.title || "Unnamed Event",
        company: eventData.company || { name: "Unknown Company", address: {} },
      };
    } catch (error) {
      logError(error);
      return null;
    }
  };
  const updateGeneralInfo = useMutation(
    async (updatedInfo) => {
      if (!updatedInfo.eventId) {
        throw new Error("Event ID is required for updating general info.");
      }

      try {
        const response = await makeAuthedRequest(
          `${API_BASE_URL}/v1/events/scanner/event/update`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedInfo),
          }
        );

        if (!response.ok) {
          const errorBody = await response.json().catch(() => ({}));
          throw new Error(
            errorBody.message ||
              `API Error: ${response.status} ${response.statusText}`
          );
        }

        return await response.json();
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["event", resolvedEventId]);
        createNotification("Event updated successfully!", "success");
      },
      onError: (error) => {
        createNotification(error.message || "Failed to update event.", "error");
      },
    }
  );

  const {
    isLoading,
    isError,
    data: eventData,
    error,
    refetch,
  } = useQuery(["event", resolvedEventId], fetchEvent, {
    enabled: !!resolvedEventId,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isError && error && error !== loggedError) {
      logError(error);
      setLoggedError(error);
    }
  }, [isError, error, loggedError, logError]);

  const saveSession = useMutation((newSession) => Promise.resolve(), {
    retry: 0,
  });
  const deleteSession = useMutation((sessionId) => Promise.resolve(), {
    retry: 0,
  });

  return {
    isLoading,
    isError,
    eventData,
    refreshEvent: refetch,
    fetchEvent,
    error,
    saveSession,
    deleteSession,
    updateGeneralInfo,
  };
}

export const Event = createContainer(useEvent);
