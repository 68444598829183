import React, { useState, useEffect } from "react"; // Import useState
import PropTypes from "prop-types";
import styles from "./SelectUserModalNew.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";
import Loader from "shared/Loader";
import { useSelectUserModal } from "./useSelectUserModal";
import Checkbox from "shared/@forms/Checkbox";

/**
 * Modal that opens when the user clicks the refund button for an order.
 */

export function SelectUserModalNew({
  isOpen,
  onCollapse,
  onClose,
  onClick,
  iboNumber,
  eventId,
  users,
  primaryUser,
}) {
  const { data, isLoading, refetch, updateTicketUser } = useSelectUserModal(
    iboNumber,
    eventId
  );

  // const ticketData = [
  //   {
  //     ticketId: 502261,
  //     contactId: 1534973,
  //     firstName: "ANGELA",
  //     lastName: "PENDLETON",
  //   },
  //   {
  //     ticketId: 502263,
  //     contactId: 1535297,
  //     firstName: "RANDY",
  //     lastName: "JASSMAN",
  //   },
  // ];
  const [tickets, setTickets] = useState([]);
  const [originalTickets, setOriginalTickets] = useState([]);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setTickets(data);
      setOriginalTickets(data);
    }
  }, [data]);

  useEffect(() => {
    if (data && !isLoading) {
      refetch();
    }
  }, [iboNumber, eventId]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  function getChangedTickets() {
    const changedTickets = tickets.filter((ticket) => {
      const originalTicket = originalTickets.find(
        (tkt) => tkt.ticketId === ticket.ticketId
      );

      return originalTicket && ticket.contactId !== originalTicket.contactId;
    });

    const formattedTickets = changedTickets.map((ticket) => {
      const originalTicket = originalTickets.find(
        (tkt) => tkt.ticketId === ticket.ticketId
      );

      return {
        ticketId: ticket.ticketId,
        currentContactId: originalTicket?.contactId || null,
        newContactId: ticket.contactId,
      };
    });

    return formattedTickets;
  }

  const handleCheckboxClick = (ticket, contact) => {
    // console.log(ticket, contact);

    const updatedTickets = tickets.map((tkt) =>
      tkt.ticketId === ticket.ticketId
        ? {
            ...tkt,
            contactId: contact.contactId,
            firstName: contact.strFirst,
            lastName: contact.strLast,
          }
        : tkt
    );

    setTickets(updatedTickets);
  };

  const handleUpdateClick = async () => {
    setLoading(true);
    const ticketsToSend = getChangedTickets();

    try {
      await updateTicketUser.mutateAsync(ticketsToSend).then(() =>
        refetch().then(() => {
          onClick(ticketsToSend);
          setLoading(false);
        })
      );
    } catch (error) {
      console.error("Failed to update ticket user:", error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCollapse();
    }
  };

  return (
    <div className={styles.container}>
      <Modal
        title={"Change Ticket Contact"}
        isOpen={isOpen}
        onClose={handleClose}
        className={styles.modal}
      >
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.container}>
                <div className={styles.listContainerA}>
                  {tickets
                    .filter((item) => {
                      const originalTicket = originalTickets.find(
                        (tkt) => tkt.ticketId === item.ticketId
                      );
                      return originalTicket.contactId == primaryUser;
                    })
                    .map((ticket) => {
                      return (
                        <div className={styles.list}>
                          <div className={styles.listHeader}>
                            {ticket.ticketName} #{ticket.ticketId}
                          </div>
                          {users.map((contact) => (
                            <div className={styles.checkbox}>
                              <Checkbox
                                checked={contact.contactId == ticket.contactId}
                                onClick={() =>
                                  handleCheckboxClick(ticket, contact)
                                }
                              />
                              <div className={styles.checkboxText}>
                                {contact.strFirst} {contact.strLast}
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>

                <div className={styles.listContainerB}>
                  {tickets
                    .filter((item) => {
                      const originalTicket = originalTickets.find(
                        (tkt) => tkt.ticketId === item.ticketId
                      );
                      return originalTicket.contactId !== primaryUser;
                    })
                    .map((ticket) => {
                      return (
                        <div className={styles.list}>
                          <div className={styles.listHeader}>
                            {ticket.ticketName} #{ticket.ticketId}
                          </div>
                          {users.map((contact) => (
                            <div className={styles.checkbox}>
                              <Checkbox
                                checked={contact.contactId == ticket.contactId}
                                onClick={() =>
                                  handleCheckboxClick(ticket, contact)
                                }
                              />
                              <div className={styles.checkboxText}>
                                {contact.strFirst} {contact.strLast}
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={classNames(
                    styles.button,
                    styles.updateBtn,
                    (getChangedTickets().length == 0 || loading) &&
                      styles.disabled
                  )}
                  onClick={handleUpdateClick}
                  disabled={getChangedTickets().length == 0 || loading}
                >
                  UPDATE
                </button>
                <button
                  className={classNames(styles.button, styles.closeButton)}
                  onClick={onCollapse}
                >
                  CLOSE
                </button>
              </div>
            </>
          )}
        </>
      </Modal>
    </div>
  );
}

SelectUserModalNew.propTypes = {
  /**
   * Determines if the modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Function called when cancel is clicked
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when refund is clicked
   */
  onClick: PropTypes.func,
};
