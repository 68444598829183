import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./NewEventModal.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";
import { Select } from "shared/Select/Select";
import { useHistory } from "react-router-dom";
import NewEventNameModal from "Events/Components/NewEventNameModal";
import { useCreateEvent } from "./useCreateEvent";
import { useCurrentRoute } from "../../../../src/shared/@hooks/useCurrentRoute";
import { useEditEventsPage } from "../../Pages/EditLocalV1EventPage/useEditEventPage";
import { useEventsVirtualGet } from "./useEventsVirtualGet";
import DatePicker from "../DatePicker";
import moment from "moment-timezone";
import { setConsoleOptions } from "@storybook/addon-console";
import Checkbox from "shared/@forms/Checkbox";
import Loader from "shared/Loader";

/**
 * Modal used to create a new event. User must select ruleset before the event name select box will enable. The user also has the option to create a new event name. If they choose to the New Event Name Modal will open. Once all fields are selected the create button is enabled.
 */
export function NewEventModal({
  isOpen,
  onCollapse,
  onChange,
  selectedRuleset,
  selectedEventName,
  isFromRuleset,
  isFromEventDetails,
}) {
  const history = useHistory();

  const {
    // data: rulesets,
    createEvent,
    createNewEvent,
    eventUpdatePut,
    // isLoading,
    // isError,
  } = useCreateEvent(isOpen);
  const {
    rulesetData,
    rulesetStatus,
    refetchRulesetData,
    isRulesetDataLoading,
  } = useCurrentRoute();

  const [virtualEvents, setVirtualEvents] = useState(null);
  const [selectedVirtualEventId, setSelectedVirtualEventId] = useState(null);

  const [ruleset, setRuleset] = useState(selectedRuleset || null);
  const [eventName, setEventName] = useState(selectedEventName || null);
  const {
    data: virtualEventsData,
    isLoading: isVirtualEventsLoading,
    refetch: refetchVirtualEvents,
  } = useEventsVirtualGet({ ruleset: ruleset, eventName: eventName });

  useEffect(() => {
    if (!isVirtualEventsLoading && virtualEventsData) {
      setVirtualEvents(virtualEventsData);
    }
  }, [isVirtualEventsLoading, virtualEventsData]);

  useEffect(() => {
    if (ruleset && eventName) {
      refetchVirtualEvents();
    }
  }, [ruleset, eventName]);

  const initialEventType = {
    ruleset: "",
    eventName: "",
    location: "Onsite",
  };

  const [eventType, setEventType] = useState(initialEventType);
  const [openNewEventModal, setOpenNewEventModal] = useState(false);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [eventsOptions, setEventsOptions] = useState([]);
  const [isChooseDateModalOpen, setIsChooseDateModalOpen] = useState(false);
  const [createdEventData, setCreatedEventData] = useState(null);
  const todaysDate = new Date();
  const [date, setDate] = useState(todaysDate);
  const [date2, setDate2] = useState("");

  useEffect(() => {
    if (eventType.ruleset && eventType.eventName) {
      setRuleset(eventType.ruleset);
      setEventName(eventType.eventName);
    }
  }, [eventType]);

  //Handles ruleset selection
  useEffect(() => {
    if (!rulesetData || !Array.isArray(rulesetData)) {
      setEventsOptions([]);
      return;
    }

    const newEventsOptions = rulesetData.reduce((acc, ruleset) => {
      if (ruleset.events && Array.isArray(ruleset.events)) {
        const filteredEvents = ruleset.events
          .filter((event) => event.name)
          .map((event) => ({
            eventName: event.name.trim(),
            rulesetName: ruleset.name.trim(),
          }));
        acc.push(...filteredEvents);
      }
      return acc;
    }, []);
    setEventsOptions(newEventsOptions);
  }, [rulesetData]);

  const rulesetOptions = useMemo(() => {
    return [...new Set(eventsOptions.map((event) => event.rulesetName))];
  }, [eventsOptions]);

  const handleRulesetChange = (selectedOption) => {
    if (!rulesetData) {
      return;
    }

    setEventType((prev) => ({
      ...prev,
      ruleset: selectedOption,
      eventName: "",
    }));

    //Filter event names for the selected ruleset
    const filteredEventNames = eventsOptions
      .filter((eventOption) => eventOption.rulesetName === selectedOption)
      .map((eventOption) => eventOption.eventName);

    const selectedRuleset = rulesetData.find(
      (ruleset) => ruleset.name === selectedOption
    );
    if (
      selectedRuleset &&
      selectedRuleset.name &&
      selectedRuleset.name.includes("2")
      // selectedRuleset.event &&
      // selectedRuleset.event.name &&
      // selectedRuleset.event.name.permission === "write"
    ) {
      filteredEventNames.push("+ Create New");
    }

    setEventNameOptions(filteredEventNames);
  };

  const handleLocation = () => {
    setEventType((prevData) => ({
      ...prevData,
      location: prevData.location === "Onsite" ? "Virtual" : "Onsite",
    }));
  };

  //Handles event name change
  const handleEventNameChange = (selectedRuleset, newEventName) => {
    if (newEventName === "+ Create New") {
      setOpenNewEventModal(true);
    } else {
      setEventType((prevData) => ({ ...prevData, eventName: newEventName }));
    }
  };

  useEffect(() => {
    if (selectedRuleset && rulesetData) {
      setEventNameOptions([
        ...rulesetData
          .find((set) => set.name == selectedRuleset)
          ?.events?.map((event) => event.name),
        "+ Create New",
      ]);
    }
  }, [selectedRuleset, rulesetData]);

  //Handles the preselection of ruleset and event name in ruleset page
  useEffect(() => {
    if (isOpen && selectedEventName) {
      const foundEventOption = eventsOptions.find(
        (option) => option.eventName === selectedEventName
      );

      if (foundEventOption) {
        setEventType({
          ...initialEventType,
          ruleset: selectedRuleset || foundEventOption.rulesetName,
          eventName: foundEventOption.eventName,
        });
      }
    }
  }, [isOpen, selectedRuleset, selectedEventName, eventsOptions]);

  //Handles creating a new event name
  const onSaveNewEvent = async (newEventName, image) => {
    if (!rulesetData) {
      return "Ruleset data is missing.";
    }

    let error = null;
    let rulesetIds = {};

    rulesetData.forEach((ruleset) => {
      rulesetIds[ruleset.name] = ruleset.id;
    });

    const rulesetId = rulesetIds[eventType.ruleset];

    if (rulesetId) {
      const newEventData = {
        rulesetId: rulesetId,
        eventName: newEventName,
        eventIcon: image,
      };

      try {
        await createNewEvent.mutateAsync(newEventData);
      } catch (err) {
        error = err.message;
      }
    } else {
      error = "Invalid ruleset ID.";
    }

    if (error) {
      return error; // Return error to the caller
    }

    // Update UI only if there's no error
    setEventNameOptions((prevState) => {
      const filteredOptions = prevState.filter(
        (option) => option !== "+ Create New"
      );

      if (!filteredOptions.includes(newEventName)) {
        filteredOptions.push(newEventName);
      }

      return [...filteredOptions, "+ Create New"];
    });

    setEventType((prev) => ({ ...prev, eventName: newEventName }));
    setOpenNewEventModal(false);

    return null;
  };

  //Handles create button and redirect page
  const handleCreate = async () => {
    if (!rulesetData) {
      return;
    }

    const newEvent = {
      name: eventType.eventName,
      location: eventType.location.toLowerCase(),
      ruleset: eventType.ruleset,
    };

    const createdEvent = await createEvent(newEvent);

    if (selectedVirtualEventId) {
      const virtualEvent = virtualEvents.find(
        (event) => event.id === selectedVirtualEventId
      );

      const object = {
        virtual: {
          eventId: selectedVirtualEventId || "",
          eventName: virtualEvent?.marketing?.title?.saved || "",
          eventLink: virtualEvent?.link || "",
          eventType: virtualEvent?.type || "",
        },
      };
      const id = createdEvent.id;
      await eventUpdatePut.mutateAsync({
        eventObject: object,
        eventId: id,
      });
    }

    if (newEvent.ruleset === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${createdEvent.id}`);
    } else if (newEvent.ruleset === "Local v1.0") {
      setIsChooseDateModalOpen(true);
      setCreatedEventData(createdEvent);
    } else if (newEvent.ruleset === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${createdEvent.id}`);
    } else if (newEvent.ruleset === "Major v2.0") {
      history.push(`/events/edit/event/majorv2/${createdEvent.id}`);
    } else if (newEvent.ruleset === "Local v2.0") {
      // setIsChooseDateModalOpen(true);
      setCreatedEventData(createdEvent);
      if (date2 && date2 !== "") {
        const newSessions = [];

        const eventStartDate = moment.utc(date2).startOf("day").toISOString();
        if (eventType.eventName == "Regional") {
          newSessions.push({
            type: "",
            name: "Seminar",
            description: "",
            speakers: [],
            location: "",
            capacity: "",
            startDate: moment
              .utc(eventStartDate)
              .add(15, "hours")
              .toISOString(),
            endDate: moment
              .utc(eventStartDate)
              .add(17.5, "hours")
              .toISOString(),
            checkInStartDate: moment
              .utc(eventStartDate)
              .add(14, "hours")
              .toISOString(),
            checkInEndDate: moment
              .utc(eventStartDate)
              .add(17.5, "hours")
              .toISOString(),
            doorsOpenStartDate: moment
              .utc(eventStartDate)
              .add(14, "hours")
              .toISOString(),
            visibleTo: [],
            visibility: "All",
          });
          newSessions.push({
            type: "",
            name: "SLM",
            description: "",
            speakers: [],
            location: "",
            capacity: "",
            startDate: moment
              .utc(eventStartDate)
              .add(20, "hours")
              .toISOString(),
            endDate: moment
              .utc(eventStartDate)
              .add(22.5, "hours")
              .toISOString(),
            checkInStartDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            checkInEndDate: moment
              .utc(eventStartDate)
              .add(22.5, "hours")
              .toISOString(),
            doorsOpenStartDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            visibleTo: [],
            visibility: "All",
          });
        } else {
          newSessions.push({
            type: "",
            name: "SLM",
            description: "",
            speakers: [],
            location: "",
            capacity: "",
            startDate: moment
              .utc(eventStartDate)
              .add(20, "hours")
              .toISOString(),
            endDate: moment.utc(eventStartDate).add(22, "hours").toISOString(),
            checkInStartDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            checkInEndDate: moment
              .utc(eventStartDate)
              .add(22, "hours")
              .toISOString(),
            doorsOpenStartDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            visibleTo: [],
            visibility: "All",
          });
        }

        if (createdEvent.event?.location?.value == "onsite") {
          await eventUpdatePut.mutateAsync({
            eventObject: { sessions: newSessions },
            eventId: createdEvent.id,
          });
        }
        await handleDateSaveClick(true, createdEvent);
      }
      history.push(`/events/edit/event/localv2/${createdEvent.id}`);
    } else if (newEvent.ruleset === "LOS v2.0") {
      history.push(`/events/edit/event/losv2/${createdEvent.id}`);
    } else {
      history.push(`/events/edit/${createdEvent.id}`);
    }
    onCollapse();
  };

  const onClose = () => {
    setIsChooseDateModalOpen(false);
    setEventType(initialEventType);
    if (isFromRuleset) {
      history.push(`/events/view/rulesets`);
    } else if (isFromEventDetails) {
      history.push(`/events/events-details`);
    } else {
      history.goBack();
    }
    onCollapse();
  };

  const handleDateSaveClick = async (isV2, createdEvent) => {
    const eventStartDate = moment
      .utc(isV2 ? date2 : date)
      .startOf("day")
      .toISOString();
    let dates;
    if (isV2) {
      if (createdEvent?.ruleset?.name.includes("Second Look Meeting")) {
        if (createdEvent?.event?.location?.value == "onsite") {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            // changeRequestEndDate: moment
            //   .utc(eventStartDate)
            //   .add(5, "days")
            //   .toISOString(),
          };
        } else {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            // changeRequestEndDate: moment
            //   .utc(eventStartDate)
            //   .add(5, "days")
            //   .toISOString(),
          };
        }
      } else {
        if (createdEvent?.event?.location?.value == "onsite") {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            // changeRequestEndDate: moment
            //   .utc(eventStartDate)
            //   .add(5, "days")
            //   .toISOString(),
          };
        } else {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            // changeRequestEndDate: moment
            //   .utc(eventStartDate)
            //   .add(5, "days")
            //   .toISOString(),
          };
        }
      }
    } else {
      if (createdEventData?.ruleset?.name.includes("Second Look Meeting")) {
        if (createdEventData?.event?.location?.value == "onsite") {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            slmDoorsOpenDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            slmSessionStartDate: moment
              .utc(eventStartDate)
              .add(20, "hours")
              .toISOString(),
            slmSessionEndDate: moment
              .utc(eventStartDate)
              .add(22, "hours")
              .toISOString(),
            changeRequestEndDate: moment
              .utc(eventStartDate)
              .add(5, "days")
              .toISOString(),
          };
        } else {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            changeRequestEndDate: moment
              .utc(eventStartDate)
              .add(5, "days")
              .toISOString(),
          };
        }
      } else {
        if (createdEventData?.event?.location?.value == "onsite") {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            seminarDoorsOpenDate: moment
              .utc(eventStartDate)
              .add(14, "hours")
              .toISOString(),
            slmSessionStartDate: moment
              .utc(eventStartDate)
              .add(20, "hours")
              .toISOString(),
            slmSessionEndDate: moment
              .utc(eventStartDate)
              .add(22.5, "hours")
              .toISOString(),
            seminarSessionStartDate: moment
              .utc(eventStartDate)
              .add(15, "hours")
              .toISOString(),
            seminarSessionEndDate: moment
              .utc(eventStartDate)
              .add(1, "days")
              .toISOString(),
            changeRequestEndDate: moment
              .utc(eventStartDate)
              .add(5, "days")
              .toISOString(),
            slmDoorsOpenDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
          };
        } else {
          dates = {
            eventStartDate: eventStartDate,
            purchaseStartDate: moment
              .utc(eventStartDate)
              .subtract(2, "days")
              .toISOString(),
            purchaseEndDate: moment
              .utc(eventStartDate)
              .add(23.5, "hours")
              .toISOString(),
            seminarDoorsOpenDate: moment
              .utc(eventStartDate)
              .add(14, "hours")
              .toISOString(),
            seminarSessionStartDate: moment
              .utc(eventStartDate)
              .add(15, "hours")
              .toISOString(),
            seminarSessionEndDate: moment
              .utc(eventStartDate)
              .add(1, "days")
              .toISOString(),
            slmDoorsOpenDate: moment
              .utc(eventStartDate)
              .add(19, "hours")
              .toISOString(),
            slmSessionStartDate: moment
              .utc(eventStartDate)
              .add(20, "hours")
              .toISOString(),
            slmSessionEndDate: moment
              .utc(eventStartDate)
              .add(22.5, "hours")
              .toISOString(),
            changeRequestEndDate: moment
              .utc(eventStartDate)
              .add(5, "days")
              .toISOString(),
          };
        }
      }
    }
    const eventObject = {
      event: {
        dates: dates,
      },
    };
    const eventId = isV2 ? createdEvent.id : createdEventData?.id;
    await eventUpdatePut.mutateAsync({ eventObject, eventId });
    if (!isV2) {
      history.push(
        `/events/edit/event/localv${
          createdEventData?.event?.ruleset?.value.includes("2") ? "2" : "1"
        }/${isV2 ? createdEvent.id : createdEventData?.id}`
      );
    }
    onCollapse();
  };

  return (
    <div className={styles.container}>
      <Modal
        title="CREATE NEW EVENT"
        isOpen={isOpen && !isChooseDateModalOpen}
        onClose={onClose}
        closeBtn="no"
        className={styles.modal}
        children={
          <div>
            <div className={styles.grid2}>
              <div className={styles.gridItem}>Ruleset:</div>
              <div className={styles.ruleset}>
                <Select
                  placeholder={eventType.ruleset || "Select"}
                  options={rulesetOptions}
                  onChange={(selectedOption) =>
                    handleRulesetChange(selectedOption)
                  }
                  disabled={isRulesetDataLoading}
                />
              </div>
              <div className={styles.gridItem}>Event Name:</div>
              <div
                className={classNames(styles.eventName, {
                  [styles.disable]: !eventType.ruleset,
                })}
              >
                <Select
                  placeholder={eventType.eventName || "Select"}
                  options={eventNameOptions || []}
                  onChange={(newEventName) =>
                    handleEventNameChange(eventType.ruleset, newEventName)
                  }
                  disabled={!eventType.ruleset || isRulesetDataLoading}
                />
              </div>
            </div>

            <div className={styles.hr} />

            <div className={styles.grid}>
              <div className={styles.location}>Location:</div>
              <div>
                <button
                  className={classNames(
                    styles.onSiteOrVirtualIndicator,
                    eventType.location !== "Virtual"
                      ? styles.onSiteOrVirtualIndicatorOff
                      : ""
                  )}
                  onClick={handleLocation}
                >
                  VIRTUAL
                </button>
                <button
                  className={classNames(
                    styles.onSiteOrVirtualIndicator,
                    eventType.location === "Onsite"
                      ? ""
                      : styles.onSiteOrVirtualIndicatorOff
                  )}
                  onClick={handleLocation}
                >
                  ONSITE
                </button>
              </div>
            </div>

            <div className={styles.hr} />

            {eventType.location === "Onsite" &&
              eventType.eventName !== "" &&
              eventType.eventName !== "Select" && (
                <div className={styles.grid3}>
                  <div className={styles.location}>Link To Virtual Event:</div>
                  {isVirtualEventsLoading || virtualEvents === null ? (
                    <span className={styles.loader}>
                      <Loader />
                    </span>
                  ) : (
                    <>
                      {virtualEvents.length <= 0 ? (
                        <div className={styles.noEvents}>No events found</div>
                      ) : (
                        <div className={styles.virtualEventList}>
                          {virtualEvents
                            .filter(
                              (e) =>
                                e.marketing.title.saved ||
                                e.marketing.title.value
                            )
                            .map((event, index) => (
                              <div
                                className={
                                  index == virtualEvents.length - 1
                                    ? styles.virtualEventLast
                                    : styles.virtualEvent
                                }
                                key={index}
                              >
                                <div className={styles.checkboxContainer}>
                                  <Checkbox
                                    className={styles.checkbox}
                                    onChange={() => {
                                      if (selectedVirtualEventId === event.id) {
                                        setSelectedVirtualEventId(null);
                                      } else {
                                        setSelectedVirtualEventId(event.id);
                                      }
                                    }}
                                    checked={
                                      event.id === selectedVirtualEventId
                                    }
                                  />
                                </div>
                                <div className={styles.name}>
                                  {event.marketing.title.saved ||
                                    event.marketing.title.value}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </>
                  )}
                  <div className={styles.virtualNote}>
                    Note: Virtual event must be published to be shown in list
                  </div>
                  <div className={styles.divider} />
                </div>
              )}

            {eventType.ruleset == "Local v2.0" && (
              <>
                <div className={styles.grid3}>
                  <div className={styles.datePickerContainer}>
                    <div>Select Date:</div>
                    <DatePicker
                      dateString={moment(date2).format("MM/DD/YYYY")}
                      setDate={setDate2}
                    />
                  </div>
                </div>
              </>
            )}

            <div>
              <button
                className={classNames(styles.button, {
                  [styles.disable]: !eventType.ruleset || !eventType.eventName,
                })}
                onClick={() => handleCreate()}
                disabled={!eventType.ruleset || !eventType.eventName}
              >
                CREATE
              </button>
            </div>
          </div>
        }
      />

      <Modal
        title="CHOOSE DATE"
        isOpen={isChooseDateModalOpen && !isOpen}
        closeBtn="none"
        className={styles.modal}
        children={
          <div className={styles.chooseDateModalContainer}>
            <div className={styles.labelAndPickerContainer}>
              <DatePicker
                dateString={moment(date).format("MM/DD/YYYY")}
                setDate={setDate}
              />
            </div>
            <div>
              <button
                className={styles.saveBtn}
                onClick={handleDateSaveClick}
                disabled={!eventType.ruleset || !eventType.eventName}
              >
                SAVE
              </button>
            </div>
          </div>
        }
      />

      <NewEventNameModal
        id="newEventNameModal"
        ruleset={eventType.ruleset}
        isOpen={openNewEventModal}
        onCollapse={() => {
          setOpenNewEventModal(false);
        }}
        onSave={onSaveNewEvent}
      />
    </div>
  );
}

NewEventModal.propTypes = {
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  eventNames: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  setEventNames: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.bool,
};
