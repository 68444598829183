import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./UndoChangesModal.module.css";
import { Modal } from "shared/Modal";
import DeleteModal from "Events/Components/DeleteModal";
import moment from "moment";

/**
 * Modal that opens when clicking undo changes button in edit events page
 */
export function UndoChangesModal({
  editedEvent,
  isOpen,
  onCollapse,
  onClick,
  className,
}) {
  function onClose() {
    onCollapse();
  }
  const areArraysIdentical = (array1 = [], array2 = []) => {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortByStringifiedObject = (array) =>
      array
        .slice()
        .map((item) => {
          // Remove id and _id and keep only string properties
          const filteredItem = Object.keys(item)
            .filter(
              (key) =>
                key !== "id" && key !== "_id" && typeof item[key] === "string"
            )
            .sort()
            .reduce((obj, key) => {
              obj[key] = item[key];
              return obj;
            }, {});
          return JSON.stringify(filteredItem);
        })
        .sort();

    const sortedArray1 = sortByStringifiedObject(array1);
    const sortedArray2 = sortByStringifiedObject(array2);

    // console.log("Sorted Processed Array 1:", sortedArray1);
    // console.log("Sorted Processed Array 2:", sortedArray2);

    return sortedArray1.every((item, index) => {
      const isEqual = item === sortedArray2[index];
      return isEqual;
    });
  };
  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function separateCamelCaseWords(str) {
    return (
      str
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .charAt(0)
        .toUpperCase() + str.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)
    );
  }

  function formatDate(dateString) {
    if (!dateString) return "";

    const date = moment.utc(dateString);

    if (!date.isValid()) {
      return "Invalid Date";
    }

    return date.format("MM/DD/YYYY hh:mm A"); // Adjusted format
  }

  function formatFileType(base64String) {
    if (
      typeof base64String === "string" &&
      /^data:image\/[a-zA-Z0-9-+.]+;/.test(base64String)
    ) {
      // Extract MIME type from Base64 string
      return base64String.split(";")[0].split(":")[1] || "Unknown File Type";
    }
    return base64String; // Return the original value if it's not a Base64 string
  }
  // console.log(editedEvent);
  return (
    <>
      <Modal
        title="CHANGES"
        isOpen={isOpen}
        onClose={onClose}
        closeBtn="none"
        className={`${styles.modal} ${className}`}
        children={
          <>
            <div className={styles.container}>
              <div className={styles.header}>
                <div>Field</div>
                <div>Original Value</div>
                <div>New Saved Value</div>
              </div>
              <div className={styles.hr} />

              <div className={styles.itemContainer}>
                {editedEvent &&
                  editedEvent.map((change, index) =>
                    change.original_value != change.saved_value ? (
                      <React.Fragment
                        key={change.path || change.field_name || index}
                      >
                        {change.field_name == "sessions" ? (
                          <>
                            {change.saved_value.map((session, index) => {
                              // Get the corresponding session from original_value (if it exists)
                              const originalSession =
                                change.original_value.find(
                                  (ses) => ses._id == session._id
                                ) || {};

                              return (
                                <React.Fragment key={index}>
                                  {Object.keys(session)
                                    .filter((prop) => {
                                      if (
                                        prop === "speakers" ||
                                        prop == "visibleTo"
                                      ) {
                                        if (!session[prop]) {
                                          return false;
                                        } else {
                                          return !areArraysIdentical(
                                            session[prop],
                                            originalSession[prop]
                                          );
                                        }
                                      }
                                      if (prop === "id" || prop === "_id")
                                        return false;
                                      // Include fields that differ or have no original value but are non-empty
                                      if (
                                        prop !== "speakers" &&
                                        prop !== "visibleTo"
                                      ) {
                                        const hasOriginal = originalSession[
                                          prop
                                        ]
                                          ? true
                                          : false;
                                        const hasSaved = session[prop]
                                          ? true
                                          : false;
                                        if (!hasSaved) {
                                          return false;
                                        } else {
                                          if (hasOriginal) {
                                            return (
                                              originalSession[prop] !==
                                              session[prop]
                                            );
                                          } else {
                                            return true;
                                          }
                                        }
                                      }
                                    })
                                    .map((prop) => (
                                      <div
                                        key={`${session.id || index}-${prop}`}
                                        className={styles.item}
                                      >
                                        <div className={styles.field}>
                                          {`Session #${
                                            index + 1
                                          } - ${separateCamelCaseWords(prop)}
                                          `}
                                        </div>
                                        <div>
                                          {!Array.isArray(
                                            prop.toLowerCase().includes("date")
                                              ? formatDate(
                                                  originalSession[prop]
                                                )
                                              : originalSession[prop] || ""
                                          ) ? (
                                            originalSession[prop] || ""
                                          ) : (
                                            <div
                                              className={styles.savedValueList}
                                            >
                                              {originalSession[prop].map(
                                                (item) => (
                                                  <div
                                                    className={
                                                      styles.savedValueListItem
                                                    }
                                                  >
                                                    {item.iboNumber
                                                      ? `${item.iboNumber}: ${item.name}`
                                                      : `${item.ticketSet} Ticket Set` ||
                                                        "No Name For Ticket Set"}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          {!Array.isArray(session[prop]) ? (
                                            prop
                                              .toLowerCase()
                                              .includes("date") ? (
                                              formatDate(session[prop])
                                            ) : (
                                              session[prop] || ""
                                            )
                                          ) : (
                                            <div
                                              className={styles.savedValueList}
                                            >
                                              {session[prop].map((item, i) => (
                                                <div
                                                  className={
                                                    styles.savedValueListItem
                                                  }
                                                  key={`session-${
                                                    session.id
                                                  }-${prop}-${item.id || i}`}
                                                >
                                                  {item.iboNumber
                                                    ? `${item.iboNumber}: ${item.name}`
                                                    : `${item.ticketSet} Ticket Set` ||
                                                      "No Name For Ticket Set"}
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                        <button
                                          className={styles.undoBtn}
                                          onClick={() => {
                                            const returnPath = `${change.path}.${index}.${prop}`;
                                            onClick(
                                              index,
                                              returnPath,
                                              change.field_name, // Used to update the data
                                              change.original_value,
                                              change.field // Displays in Undo Confirmation modal
                                            );
                                          }}
                                        >
                                          UNDO
                                        </button>
                                      </div>
                                    ))}
                                </React.Fragment>
                              );
                            })}
                          </>
                        ) : change.field_name == "ticketing" ? (
                          <>
                            {change.saved_value.map((ticketSet, index) => {
                              // Get the corresponding session from original_value (if it exists)
                              const originalTicketSet =
                                change.original_value.find(
                                  (ses) => ses._id == ticketSet._id
                                ) || {};

                              return (
                                <div key={`ticketSet-${ticketSet.id || index}`}>
                                  {/* Render TicketSet Section */}

                                  {originalTicketSet.title !==
                                    ticketSet.title && (
                                    <div className={styles.item}>
                                      <div className={styles.field}>
                                        {ticketSet.ticketSet} Ticket Set -
                                        Display Name
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {originalTicketSet.title}
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {ticketSet.title}
                                      </div>
                                      <button
                                        className={styles.undoBtn}
                                        onClick={() => {
                                          onClick(
                                            index,
                                            `tickets.${ticketSet.id}.title`,
                                            change.field_name,
                                            change.original_value,
                                            change.field
                                          );
                                        }}
                                      >
                                        UNDO
                                      </button>
                                    </div>
                                  )}
                                  {originalTicketSet.subTitle !==
                                    ticketSet.subTitle && (
                                    <div className={styles.item}>
                                      <div className={styles.field}>
                                        {ticketSet.ticketSet} Ticket Set -
                                        Display Date(s)
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {originalTicketSet.subTitle}
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {ticketSet.subTitle}
                                      </div>
                                      <button
                                        className={styles.undoBtn}
                                        onClick={() => {
                                          onClick(
                                            index,
                                            `tickets.${ticketSet.id}.subTitle`,
                                            change.field_name,
                                            change.original_value,
                                            change.field
                                          );
                                        }}
                                      >
                                        UNDO
                                      </button>
                                    </div>
                                  )}

                                  {!originalTicketSet ? (
                                    <div className={styles.item}>
                                      <div className={styles.field}>
                                        {ticketSet.ticketSet} Ticket Set
                                      </div>
                                      <div />
                                      <div>
                                        {ticketSet.ticketSet} Ticket Set
                                      </div>
                                      <button
                                        className={styles.undoBtn}
                                        onClick={() => {
                                          onClick(
                                            index,
                                            `tickets.${ticketSet.id}.remove`,
                                            change.field_name,
                                            change.original_value,
                                            change.field
                                          );
                                        }}
                                      >
                                        UNDO
                                      </button>
                                    </div>
                                  ) : ticketSet.badges &&
                                    !areArraysIdentical(
                                      ticketSet.badges,
                                      originalTicketSet.badges
                                    ) ? (
                                    <div className={styles.item}>
                                      {/* Render Badges Section */}
                                      <div className={styles.field}>
                                        {ticketSet.ticketSet} Ticket Set -
                                        Badges
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {/* Original badges */}
                                        {originalTicketSet.badges.map(
                                          (group, i) => (
                                            <div
                                              className={
                                                styles.savedValueListItem
                                              }
                                              key={`original-badge-${index}-${i}`}
                                            >
                                              {group?.name || "No Badge Name"}
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className={styles.savedValueList}>
                                        {/* Updated badges */}
                                        {ticketSet.badges.map((group, i) => (
                                          <div
                                            className={
                                              styles.savedValueListItem
                                            }
                                            key={`updated-badge-${index}-${i}`}
                                          >
                                            {group?.name || "No Badge Name"}
                                          </div>
                                        ))}
                                      </div>
                                      <button
                                        className={styles.undoBtn}
                                        onClick={() => {
                                          onClick(
                                            index,
                                            `tickets.${ticketSet.id}.badges`,
                                            change.field_name,
                                            change.original_value,
                                            change.field
                                          );
                                        }}
                                      >
                                        UNDO
                                      </button>
                                    </div>
                                  ) : null}

                                  {/* Render Tickets Section */}
                                  {ticketSet.tickets.map(
                                    (ticket, ticketIndex) => {
                                      const originalTicket =
                                        originalTicketSet?.tickets.find(
                                          (tkt) => tkt.id === ticket.id
                                        ) || null;

                                      return (
                                        <React.Fragment
                                          key={`ticket-${
                                            ticket.id || ticketIndex
                                          }`}
                                        >
                                          {!originalTicket &&
                                            originalTicketSet && (
                                              <div
                                                key={`ticket-${index}-${ticketIndex}`}
                                                className={styles.item}
                                              >
                                                <div className={styles.field}>
                                                  {ticketSet.ticketSet} Ticket
                                                  Set:
                                                  {/* Ticket #
                                                            {ticketIndex + 1} */}{" "}
                                                  {ticket.name && ticket.name}
                                                </div>
                                                <div />
                                                <div>
                                                  {`Name: ${
                                                    ticket.name || "No Name"
                                                  }
                                                Price: ${
                                                  ticket.price || "No Price"
                                                }
                                                ID: ${
                                                  ticket.commonItemId || "No ID"
                                                }`}
                                                </div>
                                                <button
                                                  className={styles.undoBtn}
                                                  onClick={() => {
                                                    onClick(
                                                      index,
                                                      `tickets.${ticketSet.id}.tickets.${ticket.id}.ticket`,
                                                      change.field_name,
                                                      change.original_value,
                                                      change.field
                                                    );
                                                  }}
                                                >
                                                  UNDO
                                                </button>
                                              </div>
                                            )}
                                          {originalTicket &&
                                            ticket.limitedToGroups &&
                                            !areArraysIdentical(
                                              ticket.limitedToGroups,
                                              originalTicket.limitedToGroups
                                            ) && (
                                              <div className={styles.item}>
                                                {/* Render Groups Section */}
                                                <div className={styles.field}>
                                                  {ticketSet.ticketSet} Ticket
                                                  Set:
                                                  {/* Ticket #
                                                            {ticketIndex + 1} */}{" "}
                                                  {ticket.name && ticket.name} -
                                                  Groups
                                                </div>
                                                <div
                                                  className={
                                                    styles.savedValueList
                                                  }
                                                >
                                                  {/* Original groups */}
                                                  {originalTicket.limitedToGroups.map(
                                                    (group, i) => (
                                                      <div
                                                        className={
                                                          styles.savedValueListItem
                                                        }
                                                        key={`original-badge-${index}-${i}`}
                                                      >
                                                        {group?.name ||
                                                          "No Group Name"}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <div
                                                  className={
                                                    styles.savedValueList
                                                  }
                                                >
                                                  {/* Updated groups */}
                                                  {ticket.limitedToGroups.map(
                                                    (group, i) => (
                                                      <div
                                                        className={
                                                          styles.savedValueListItem
                                                        }
                                                        key={`updated-badge-${index}-${i}`}
                                                      >
                                                        {group?.name ||
                                                          "No Group Name"}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <button
                                                  className={styles.undoBtn}
                                                  onClick={() => {
                                                    onClick(
                                                      index,
                                                      `tickets.${ticketSet.id}.tickets.${ticket.id}.limitedToGroups`,
                                                      change.field_name,
                                                      change.original_value,
                                                      change.field
                                                    );
                                                  }}
                                                >
                                                  UNDO
                                                </button>
                                              </div>
                                            )}
                                          {originalTicket &&
                                            ticket.acceptedCreditTypes &&
                                            !areArraysIdentical(
                                              ticket.acceptedCreditTypes,
                                              originalTicket.acceptedCreditTypes
                                            ) && (
                                              <div className={styles.item}>
                                                <div className={styles.field}>
                                                  {ticketSet.ticketSet} Ticket
                                                  Set:
                                                  {/* Ticket #
                                                            {ticketIndex + 1} */}{" "}
                                                  {ticket.name && ticket.name} -
                                                  Accepted Credit Types
                                                </div>
                                                <div
                                                  className={
                                                    styles.savedValueList
                                                  }
                                                >
                                                  {originalTicket.acceptedCreditTypes.map(
                                                    (item, i) => (
                                                      <div
                                                        className={
                                                          styles.savedValueListItem
                                                        }
                                                        key={`original-acceptedCreditType-${index}-${i}`}
                                                      >
                                                        {`${item.creditTypeId}${
                                                          item.creditTypeId &&
                                                          item.name &&
                                                          " - "
                                                        }${item.name}`}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <div
                                                  className={
                                                    styles.savedValueList
                                                  }
                                                >
                                                  {/* Updated groups */}
                                                  {ticket.acceptedCreditTypes.map(
                                                    (item, i) => (
                                                      <div
                                                        className={
                                                          styles.savedValueListItem
                                                        }
                                                        key={`updated-acceptedCreditType-${index}-${i}`}
                                                      >
                                                        {`${item.creditTypeId}${
                                                          item.creditTypeId &&
                                                          item.name &&
                                                          " - "
                                                        }${item.name}`}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <button
                                                  className={styles.undoBtn}
                                                  onClick={() => {
                                                    onClick(
                                                      index,
                                                      `tickets.${ticketSet.id}.tickets.${ticket.id}.acceptedCreditTypes`,
                                                      change.field_name,
                                                      change.original_value,
                                                      change.field
                                                    );
                                                  }}
                                                >
                                                  UNDO
                                                </button>
                                              </div>
                                            )}

                                          {originalTicket &&
                                            "creditsRequired" in
                                              originalTicket &&
                                            "creditsRequired" in ticket &&
                                            (originalTicket.creditsRequired
                                              ? "Yes"
                                              : "No") !==
                                              (ticket.creditsRequired
                                                ? "Yes"
                                                : "No") && (
                                              <div className={styles.item}>
                                                <div className={styles.field}>
                                                  {ticketSet.ticketSet} Ticket
                                                  Set:
                                                  {/* Ticket #
                                                            {ticketIndex + 1} */}{" "}
                                                  {ticket.name && ticket.name} -
                                                  Credits Required
                                                </div>
                                                <div>
                                                  {/* Original value */}
                                                  {originalTicket.creditsRequired
                                                    ? "Yes"
                                                    : "No"}
                                                </div>
                                                <div>
                                                  {/* Updated value */}
                                                  {ticket.creditsRequired
                                                    ? "Yes"
                                                    : "No"}
                                                </div>
                                                <button
                                                  className={styles.undoBtn}
                                                  onClick={() => {
                                                    onClick(
                                                      index,
                                                      `tickets.${ticketSet.id}.tickets.${ticket.id}.creditsRequired`,
                                                      change.field_name,
                                                      change.original_value,
                                                      change.field
                                                    );
                                                  }}
                                                >
                                                  UNDO
                                                </button>
                                              </div>
                                            )}
                                          {/* Render Tickets Properties Section */}
                                          {originalTicket &&
                                            Object.keys(ticket)
                                              .filter((prop) => {
                                                // Exclude specific properties
                                                if (
                                                  prop === "limitedToGroups" ||
                                                  prop === "creditsRequired" ||
                                                  prop === "id" ||
                                                  prop === "_id" ||
                                                  prop ===
                                                    "acceptedCreditTypes" ||
                                                  prop === "virtualCommonItemId"
                                                ) {
                                                  return false;
                                                }
                                                // Skip properties that are missing in either originalTicket or ticket
                                                if (
                                                  !(prop in originalTicket) ||
                                                  !(prop in ticket)
                                                ) {
                                                  return false;
                                                }
                                                // Handle null and undefined values while comparing
                                                const originalValue =
                                                  originalTicket[prop] ?? ""; // Default to empty string
                                                const ticketValue =
                                                  ticket[prop] ?? ""; // Default to empty string
                                                return (
                                                  originalValue !==
                                                    ticketValue && ticketValue
                                                ); // Return true if values are different
                                              })
                                              .map((prop) => (
                                                <div
                                                  key={`ticket-prop-${index}-${ticketIndex}-${prop}`}
                                                  className={styles.item}
                                                >
                                                  <div className={styles.field}>
                                                    {ticketSet.ticketSet} Ticket
                                                    Set:
                                                    {/* Ticket #
                                                            {ticketIndex + 1} */}{" "}
                                                    {ticket.name && ticket.name}{" "}
                                                    -{" "}
                                                    {separateCamelCaseWords(
                                                      capitalizeFirstLetter(
                                                        prop
                                                      )
                                                    )}
                                                  </div>
                                                  <div>
                                                    {typeof originalTicket[
                                                      prop
                                                    ] === "string" ? (
                                                      moment(
                                                        originalTicket[prop],
                                                        moment.ISO_8601,
                                                        true
                                                      ).isValid() ? (
                                                        formatDate(
                                                          originalTicket[prop]
                                                        )
                                                      ) : (
                                                        originalTicket[prop] ||
                                                        ""
                                                      )
                                                    ) : Array.isArray(
                                                        originalTicket[prop]
                                                      ) ? (
                                                      <div
                                                        className={
                                                          styles.savedValueList
                                                        }
                                                      >
                                                        {originalTicket[
                                                          prop
                                                        ].map((item, i) => (
                                                          <div
                                                            className={
                                                              styles.savedValueListItem
                                                            }
                                                            key={`original-${i}`}
                                                          >
                                                            {item}
                                                          </div>
                                                        ))}
                                                      </div>
                                                    ) : (
                                                      originalTicket[prop] || ""
                                                    )}
                                                  </div>
                                                  <div>
                                                    {typeof ticket[prop] ===
                                                    "string" ? (
                                                      moment(
                                                        ticket[prop],
                                                        moment.ISO_8601,
                                                        true
                                                      ).isValid() ? (
                                                        formatDate(ticket[prop])
                                                      ) : (
                                                        ticket[prop] || ""
                                                      )
                                                    ) : Array.isArray(
                                                        ticket[prop]
                                                      ) ? (
                                                      <div
                                                        className={
                                                          styles.savedValueList
                                                        }
                                                      >
                                                        {ticket[prop].map(
                                                          (item, i) => (
                                                            <div
                                                              className={
                                                                styles.savedValueListItem
                                                              }
                                                              key={`updated-${i}`}
                                                            >
                                                              {item}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ticket[prop] || ""
                                                    )}
                                                  </div>
                                                  <button
                                                    className={styles.undoBtn}
                                                    onClick={() => {
                                                      onClick(
                                                        index,
                                                        `tickets.${ticketSet.id}.tickets.${ticket.id}.${prop}`,
                                                        change.field_name,
                                                        change.original_value,
                                                        change.field
                                                      );
                                                    }}
                                                  >
                                                    UNDO
                                                  </button>
                                                </div>
                                              ))}
                                        </React.Fragment>
                                      );

                                      return null; // No ticket changes
                                    }
                                  )}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div key={index} className={styles.item}>
                              <div className={styles.field}>
                                {" "}
                                {separateCamelCaseWords(change.field)}
                              </div>
                              <div>
                                {!Array.isArray(change.original_value) ? (
                                  change.original_value
                                ) : (
                                  <div className={styles.savedValueList}>
                                    {change.original_value.map((item, i) => (
                                      <div
                                        className={styles.savedValueListItem}
                                        key={`original-value-${i}-${
                                          item.id || item.name || item
                                        }`}
                                      >
                                        {change.field_name == "ada"
                                          ? `${i + 1}: ${capitalizeFirstLetter(
                                              item.component
                                            )}`
                                          : item.iboNumber
                                          ? `${item.iboNumber}: ${item.name}`
                                          : `${item.groupId}: ${item.name}`}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div>
                                {!Array.isArray(change.saved_value) ? (
                                  // Use formatFileType or formatDate depending on the value
                                  typeof change.saved_value === "string" &&
                                  /^data:image\/[a-zA-Z0-9-+.]+;/.test(
                                    change.saved_value
                                  ) ? (
                                    formatFileType(change.saved_value)
                                  ) : typeof change.saved_value === "string" &&
                                    moment(
                                      change.saved_value,
                                      moment.ISO_8601,
                                      true
                                    ).isValid() ? (
                                    formatDate(change.saved_value)
                                  ) : (
                                    change.saved_value
                                  )
                                ) : (
                                  <div className={styles.savedValueList}>
                                    {change.saved_value.map((item, ind) => (
                                      <div
                                        className={styles.savedValueListItem}
                                        key={`saved-value-${ind}-${
                                          item.id || item.name || item
                                        }`}
                                      >
                                        {change.field_name == "ada"
                                          ? `${
                                              ind + 1
                                            }: ${capitalizeFirstLetter(
                                              item.component
                                            )}`
                                          : item.iboNumber
                                          ? `${item.iboNumber}: ${item.name}`
                                          : `${item.groupId}: ${item.name}`}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>

                              <button
                                className={styles.undoBtn}
                                onClick={() => {
                                  onClick(
                                    index,
                                    change.path,
                                    change.field_name, // Used to update the data
                                    change.original_value,
                                    change.field // Displays in Undo Confirmation modal
                                  );
                                }}
                              >
                                UNDO
                              </button>
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )
                  )}
              </div>

              <div className={styles.hr} />
              <div className={styles.button}>
                <button className={styles.closeBtn} onClick={onClose}>
                  CLOSE
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

UndoChangesModal.propTypes = {
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  eventChanges: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      original_value: PropTypes.string,
      saved_value: PropTypes.string,
    })
  ),
};

UndoChangesModal.defaultProps = {
  eventChanges: [],
};
