import { useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";

export function useEventPrintTicket() {
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useEventPrintTicket" });

  const fetchPrintTicket = useMutation(
    async (attendeeTicketId) => {
      return makeAuthedRequest(
        `v1/events/ticket/print/${attendeeTicketId}`,
        {
          method: "GET",
        }
      );
    },
    {
      onError: (error) => {
        console.error("Error fetching print ticket:", error);
        logError(error);
      },
    }
  );

  return { fetchPrintTicket };
}
