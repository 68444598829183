import React from "react";
import PropTypes from "prop-types";
import styles from "./HostsV2Form.module.css";
import { useState } from "react";
import Card from "shared/Card";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import HostCard from "Events/Components/HostCard";
import AddButton from "Events/Components/AddButton";
import Button from "shared/Button";
import { useSessionStorage } from "shared/@hooks/useSessionStorage";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import DeleteModal from "Events/Components/DeleteModal";

/**
 * Form to add/delete hosts.
 */

export function HostsV2Form({ hosts, setHosts, isReunion }) {
  const hostsAdded = hosts || [];

  const [showSpeakerModal, changeShowSpeakerModal] = useState(false);
  const [btnOpened, setBtnOpened] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);

  const addBtnOnClick = () => {
    setBtnOpened(true);
  };

  const onRemove = () => {
    const newArray = [...hostsAdded];

    newArray.splice(removeIndex, 1);

    setHosts(newArray);
    changeShowRemoveModal(false);
  };

  const onAddSpeaker = (speakers) => {
    changeShowSpeakerModal(false);
    handleHostsForm(speakers);
  };

  const handleHostsForm = (newHosts) => {
    const updatedHosts = [...hostsAdded, ...newHosts];
    updateEventInfoHosts(updatedHosts);
  };

  const updateEventInfoHosts = (updatedHosts) => {
    setHosts(updatedHosts);
  };

  const [showRemoveModal, changeShowRemoveModal] = useState(false);

  return (
    <Card>
      <AddSpeakerModal
        isOpen={showSpeakerModal}
        onCollapse={() => changeShowSpeakerModal(false)}
        onClick={(selection) => onAddSpeaker(selection)}
        isHosts={true}
        isReunion={isReunion}
      />
      <div className={styles.title}>EVENT HOSTS</div>

      <hr className={styles.hr} />

      {hostsAdded.map((host, index) => {
        return (
          <React.Fragment key={index}>
            <div className={styles.grid}>
              <span className={styles.label}> Host </span>
              <div className={styles.hostCard}>
                <HostCard host={host} />
              </div>
              <div>
                <Button
                  className={styles.removeButton}
                  children="Remove"
                  size="small"
                  onClick={() => {
                    setRemoveIndex(index);
                    changeShowRemoveModal(true);
                  }}
                />
              </div>
            </div>
            {!isReunion && hostsAdded.length < 1 && (
              <hr className={styles.hr} />
            )}
            <DeleteModal
              header={"REMOVE HOST"}
              description={
                "Confirm that you would like to remove " +
                hostsAdded[removeIndex]?.name +
                " as a host"
              }
              isOpen={showRemoveModal}
              onCollapse={() => changeShowRemoveModal(false)}
              onClick={() => onRemove()}
              btnName={"REMOVE"}
            >
              <div className={styles.marginTop}></div>
              <HostCard host={host} />
            </DeleteModal>
          </React.Fragment>
        );
      })}
      {btnOpened ? (
        <div className={styles.container}>
          <div className={styles.add}>
            <div className={styles.btn}>
              <span className={styles.label}> Host </span>
            </div>
            <div className={styles.addBtn}>
              {/*
              <AddButton
                color="#2A3847"
                description="Add Host"
                onClick={() => changeShowSpeakerModal(true)}
              />
              */}
            </div>
            <div className={styles.remove}>
              <Button
                className={styles.removeButton}
                children="Remove"
                size="small"
                onClick={() => setBtnOpened(false)}
              />
            </div>
          </div>
          <hr className={styles.hr} />
        </div>
      ) : (
        ""
      )}

      {!isReunion ||
        (hostsAdded.length < 1 && (
          <AddButton
            color="#9AB5BB"
            description="Add Host"
            onClick={() => changeShowSpeakerModal(true)}
            /* saved for future reference
        onClick={() => addBtnOnClick()}
      */
          />
        ))}
    </Card>
  );
}

HostsV2Form.propTypes = {
  hosts: PropTypes.arrayOf(
    PropTypes.shape({
      iboNum: PropTypes.string,
      name: PropTypes.string,
      username: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      location: PropTypes.string,
    })
  ),
  onRemove: PropTypes.func,
};
