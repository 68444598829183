import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";

import TextArea from "shared/TextArea";
import styles from "./EventV2InfoForm.module.css";
import classNames from "classnames";
import { Select } from "shared/Select/Select";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import DateV2Section from "../DateV2Section";
import { Link } from "react-router-dom";
import Button from "shared/Button";
import { Modal } from "shared/Modal";
import { useEventsVirtualGet } from "Events/Components/NewEventModal/useEventsVirtualGet";
import Checkbox from "shared/@forms/Checkbox";
import { useHistory } from "react-router-dom";

/**
 * Interactive component. Form to fill out information about an event.
 */

export function EventV2InfoForm({
  marketingInfo,
  onBlur,
  pageMode,
  onValidationChange,
  triggerValidation,
  onDateTimeChange,
  onSpeakerChange,
  onVirtualEventChange,
  onCartTypeChange,
}) {
  const history = useHistory();

  const { data: states, isLoading } = useCurrentRoute();

  const [virtualEvents, setVirtualEvents] = useState([]);

  const isVirtualEventsPage =
    (marketingInfo.event?.location?.saved ||
      marketingInfo.event?.location?.value) == "virtual";

  const {
    data: virtualEventsData,
    isLoading: isVirtualEventsLoading,
    refetch: refetchVirtualEvents,
  } = useEventsVirtualGet({
    ruleset:
      marketingInfo.event?.ruleset?.saved ||
      marketingInfo.event?.ruleset?.value,
    eventName:
      marketingInfo.event?.name?.saved || marketingInfo.event?.name?.value,
    enabled: !isVirtualEventsPage,
  });

  useEffect(() => {
    if (virtualEventsData) {
      setVirtualEvents(virtualEventsData);
    }
  }, [virtualEventsData]);

  const initialEvent = {
    country: { value: "", saved: "" },
    city: { value: "", saved: "" },
    state: { value: "", saved: "" },
    dates: {},
  };

  const initialMarketing = {
    title: { value: "", saved: "" },
    description: { value: "", saved: "" },
  };

  const initialFinancial = {
    cartType: { value: "", saved: "" },
    cartTypeId: { value: "", saved: "" },
  };

  const [eventData, setEventData] = useState({
    ...initialEvent,
    ...(marketingInfo?.event && marketingInfo.event),
  });

  const [marketingData, setMarketingData] = useState({
    ...initialMarketing,
    ...(marketingInfo?.marketing && marketingInfo.marketing),
  });

  const [financialData, setFinancialData] = useState({
    ...initialFinancial,
    ...(marketingInfo?.event &&
      marketingInfo?.event?.financial &&
      marketingInfo.event.financial),
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (marketingInfo?.event !== eventData) {
      setEventData(marketingInfo?.event);
    }
    if (marketingInfo?.marketing !== marketingData) {
      setMarketingData(marketingInfo?.marketing);
    }
    if (marketingInfo?.event?.financial !== financialData) {
      setFinancialData(marketingInfo?.event?.financial);
    }
  }, [marketingInfo?.event, marketingInfo?.marketing]);

  const handleInputChange = (dataType, key, newValue) => {
    if (dataType === "event") {
      setEventData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    } else if (dataType === "marketing") {
      setMarketingData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    }
  };

  useEffect(() => {
    if (triggerValidation) {
      // const isValid = validateFields();
      // onValidationChange(isValid);
      validateFields();
    }
  }, [triggerValidation]);

  const handleBlur = async (dataType, key, value, event) => {
    event?.preventDefault();

    if (!isInitialLoad) {
      const success = await onBlur(dataType, key, value);
      if (success) {
        const fieldValidationResult = validateFields(dataType, key, value);

        if (fieldValidationResult.isValid) {
          setValidationErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[key];
            return newErrors;
          });
        }
        validateFields();
      }
    }
  };

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    13: "ANZ",
  };

  const defaultMarketingInfo = {
    virtual: {
      eventId: {
        saved: null,
        value: null,
      },
    },
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("Select");
  const [stateOptions, setStateOptions] = useState([]);
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [showAddSpeakerModal, setShowAddSpeakerModal] = useState(false);

  const [isVirtualModalOpen, setIsVirtualModalOpen] = useState(false);
  const [selectedVirtualEventId, setSelectedVirtualEventId] = useState(
    marketingInfo?.virtual?.eventId?.saved ??
      marketingInfo?.virtual?.eventId?.value ??
      null
  );

  const [selectedVirtualEventName, setSelectedVirtualEventName] = useState(
    marketingInfo?.virtual?.eventName?.saved ??
      marketingInfo?.virtual?.eventName?.value ??
      null
  );

  useEffect(() => {
    if (marketingInfo?.virtual?.eventId) {
      setSelectedVirtualEventId(
        marketingInfo.virtual.eventId?.saved ??
          marketingInfo.virtual.eventId?.value ??
          null
      );
    }

    if (marketingInfo?.virtual?.eventName) {
      setSelectedVirtualEventName(
        marketingInfo.virtual.eventName?.saved ??
          marketingInfo.virtual.eventName?.value ??
          null
      );
    }
  }, [marketingInfo]);

  //Find existing country.saved or default to "US"
  useEffect(() => {
    // const defaultCountryId = marketingInfo?.event?.country?.saved;
    // const defaultCountryName = countryIdName[defaultCountryId];
    const defaultCountry =
      marketingInfo?.event?.country?.saved ||
      marketingInfo?.event?.country?.value ||
      "";
    setSelectedCountry(defaultCountry);
  }, [marketingInfo]);

  //Filter states based on country selected
  useEffect(() => {
    if (states?.length) {
      const countryIdName = {
        220: "US",
        38: "CA",
        40: "JM",
        221: "JM",
        300: "AU",
        151: "AU",
        13: "AU",
      };

      const filteredStates = states
        .filter(
          (state) =>
            countryIdName[state.countryId] === selectedCountry &&
            state.state !== "N/A"
        )
        .map((state) => `${state.abbreviation} - ${state.state}`);

      setStateOptions(filteredStates);
    }
  }, [states, selectedCountry]);

  //Find existing state.saved else show "Select"
  useEffect(() => {
    const loadInitialState = () => {
      const stateSavedId =
        Number(marketingInfo?.event?.state.saved) ||
        Number(marketingInfo?.event?.state.value);
      if (stateSavedId > 0) {
        const countryNameId = {
          US: 220,
          CA: 38,
          JM: 40,
          JM: 221,
          AU: 300,
          AU: 151,
          AU: 13,
        };

        const existingState = states?.find(
          (state) => state.stateId === stateSavedId
        );

        // if (
        //   existingState &&
        //   existingState.countryId.toString() === countryNameId[selectedCountry]
        // ) {
        setSelectedState(
          `${existingState.abbreviation} - ${existingState.state}`
        );
        // } else {
        //   setSelectedState("");
        // }
      } else {
        setSelectedState("");
      }
    };

    if (!isInitialLoad) {
      loadInitialState();
    }
  }, [marketingInfo, states, selectedCountry, isInitialLoad]);

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        if (pageMode === "edit") {
          return "Province *";
        } else {
          return "Province";
        }
      case "JAMAICA":
        if (pageMode === "edit") {
          return "Parish *";
        } else {
          return "Parish";
        }
      default:
        if (pageMode === "edit") {
          return "State *";
        } else {
          return "State";
        }
    }
  };

  const handleViewVirtual = () => {
    const selectedEvent = virtualEvents.find(
      (e) => e.id == selectedVirtualEventId
    );
    const ruleset =
      selectedEvent.event?.ruleset.saved || selectedEvent.event?.ruleset.value;

    if (ruleset === "Major v1.0") {
      history.push(`/events/view/event/majorv1/${selectedVirtualEventId}`);
    } else if (ruleset === "Local v1.0") {
      history.push(`/events/view/event/localv1/${selectedVirtualEventId}`);
    } else if (ruleset === "LOS v1.0") {
      history.push(`/events/view/event/losv1/${selectedVirtualEventId}`);
    } else if (ruleset === "Major v2.0") {
      history.push(`/events/view/event/majorv2/${selectedVirtualEventId}`);
    } else if (ruleset === "Local v2.0") {
      history.push(`/events/view/event/localv2/${selectedVirtualEventId}`);
    } else if (ruleset === "LOS v2.0") {
      history.push(`/events/view/event/losv2/${selectedVirtualEventId}`);
    } else {
      history.push("/events/events-details");
    }
  };

  const handleCountrySelection = (selectedValue) => {
    if (selectedValue !== selectedCountry) {
      setSelectedCountry(selectedValue);
      setSelectedState("");
      setIsCountryChange(true);

      setEventData((prevData) => ({
        ...prevData,
        // city: { ...prevData.city, saved: "", value: "" },
        state: { ...prevData.state, saved: "", value: "" },
        title: { ...prevData.title }, // Explicitly copy the title field so it doesnt delete when switching countries
      }));

      const countryReference = {
        "U.S.": "US",
        CDN: "CA",
        ANZ: "AU",
        JAMAICA: "JM",
      };
      const countryId = Object.keys(countryIdName).find(
        (key) => countryIdName[key] === selectedValue
      );
      const countryValue = countryReference[selectedValue];
      if (countryValue) {
        handleBlur("event", "country", countryValue);
      }
    }
  };

  //Rendering based on permission
  const renderField = (field, label, elementType, dataType, key) => {
    const fieldValue = typeof field === "object" ? field?.value : field;
    const permission = pageMode === "view" ? "read" : field?.permission;

    const hasError = validationErrors[key];

    // Handle read-only and hidden logic
    if (permission === "hidden") {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <div className={styles.label}>
          <span className={styles.label}>{label}</span>
          <span className={styles.text}> {fieldValue || "N/A"}</span>
        </div>
      );
    } else if (
      (permission === "write" || pageMode === "edit") &&
      label === "City"
    ) {
      return (
        <InputItem
          label={label + (pageMode === "edit" ? " *" : "")}
          value={field?.saved || field?.value || ""}
          placeholder="Click to type"
          onChange={(e) => handleInputChange(dataType, key, e.target.value)}
          onBlur={() => handleBlur(dataType, key, field?.saved)}
          className={hasError ? styles.warningBorder : ""}
        />
      );
    }

    // Handle editable fields (write permission)
    switch (elementType) {
      case "input":
        return (
          <InputItem
            label={`${label}${field?.required == true ? " *" : ""}`}
            value={field?.saved || field?.value || ""}
            placeholder={
              field === marketingData.title
                ? "Click to type (IE: Anaheim A, CA)"
                : "Click to type"
            }
            onChange={(e) => handleInputChange(dataType, key, e.target.value)}
            onBlur={() => handleBlur(dataType, key, field?.saved)}
            className={hasError ? styles.warningBorder : ""}
          />
        );

      case "textarea":
        return (
          <TextArea
            label={`${label}${field?.required == true ? " *" : ""}`}
            value={field?.saved || field?.value || ""}
            placeholder="Click to type"
            onChange={(e) =>
              handleInputChange("marketing", "description", e.target.value)
            }
            onBlur={() => handleBlur("marketing", key, field?.saved)}
          />
        );
    }
  };

  const cartTypeNameToIdMap = {
    "Major Functions": 1,
    "Local Functions - US": 4,
    "Local Functions - Canada": 5,
    "Family Reunion - Baker": 6,
    "Family Reunion - Duncan": 7,
    "Family Reunion - Erickson": 8,
    "Family Reunion - Puryear": 9,
    "Family Reunion - Rice": 10,
    "Family Reunion - Whalen": 11,
  };
  const cartTypeOptions = [
    "Major Functions",
    "Local Functions - US",
    "Local Functions - Canada",
    "Family Reunion - Baker",
    "Family Reunion - Duncan",
    "Family Reunion - Erickson",
    "Family Reunion - Puryear",
    "Family Reunion - Rice",
    "Family Reunion - Whalen",
  ];

  const renderCartTypeSelection = () => {
    const permission =
      pageMode === "view"
        ? "read"
        : // "write";
          financialData?.cartType?.permission;
    const required = financialData?.cartType?.required;
    const cartTypeValue =
      financialData?.cartType?.saved || financialData?.cartType?.value || "";

    if (permission === "hidden") {
      return null;
    }

    if (
      permission === "read" ||
      pageMode === "view" ||
      (marketingInfo?.event?.status?.saved ||
        marketingInfo?.event?.status?.value) == "Published" ||
      (marketingInfo?.event?.status?.saved ||
        marketingInfo?.event?.status?.value) == "Publishing"
    ) {
      return (
        <div className={styles.row}>
          <div className={styles.stateCol}>
            <span className={styles.label}>Cart Type{required && " *"}</span>
            <span className={styles.select}>
              <span className={styles.stateValue}> {cartTypeValue}</span>
            </span>
          </div>
        </div>
      );
    }

    if (permission === "write") {
      return (
        <div className={styles.row}>
          <div className={styles.stateCol}>
            <span className={styles.label}>Cart Type{required && " *"}</span>
            <span className={styles.select}>
              <Select
                placeholder={cartTypeValue || "Select"}
                value={cartTypeValue}
                options={cartTypeOptions}
                onChange={(value) => {
                  setFinancialData({
                    ...financialData,
                    cartType: { ...financialData, saved: value },
                  });

                  const foundCartTypeId = cartTypeNameToIdMap[value];

                  if (foundCartTypeId) {
                    onCartTypeChange(value, foundCartTypeId);
                  }
                }}
                disabled={isLoading}
              />
            </span>
          </div>
        </div>
      );
    }
  };

  const renderStateSelection = () => {
    const permission =
      pageMode === "view" ? "read" : eventData?.state?.permission;
    const stateValue =
      eventData?.state?.value === 0 || eventData?.state === 0
        ? "N/A"
        : eventData?.state?.value || eventData?.state?.saved;

    const hasStateError = validationErrors["state"];

    //Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = states?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    if (permission === "hidden") {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <span className={styles.stateValue}>
          {getStateNameById(stateValue)}
        </span>
      );
    }

    if (permission === "write") {
      return (
        <Select
          label={
            getLabelForCountry(selectedCountry) + pageMode === "edit" && "*"
          }
          placeholder={selectedState ? selectedState : "Select"}
          value={selectedState}
          options={stateOptions}
          onChange={(value) => {
            const extractState = value.split(" - ")[1];
            setSelectedState(value);

            const foundState = states?.find(
              (state) => state.state === extractState
            );

            if (foundState) {
              const stateId = String(foundState.stateId);

              handleInputChange("event", "state", stateId);
              handleBlur("event", "state", stateId);
            }
          }}
          disabled={isLoading && !selectedCountry}
          className={hasStateError ? styles.warningBorder : ""}
        />
      );
    }
  };

  const renderCountryField = () => {
    const countryPermission =
      pageMode === "view" ? "read" : marketingInfo?.event?.country?.permission;

    const countryId =
      marketingInfo?.event?.country?.saved ||
      marketingInfo?.event?.country?.value;

    const countryName = countryIdName[countryId] || "N/A";

    const countryReference = {
      US: "U.S.",
      CA: "CDN",
      AU: "ANZ",
      JM: "JAMAICA",
    };
    const displayCountry = countryReference[selectedCountry];

    if (countryPermission === "write" || countryPermission === undefined) {
      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelection}
            options={["U.S.", "CDN", "ANZ", "JAMAICA"]}
            defaultValue={displayCountry}
            groupName="eventCountry"
          />
        </span>
      );
    } else if (countryPermission === "read") {
      return <span className={styles.textValue}>{countryName}</span>;
    } else if (countryPermission === "hidden") {
      return null;
    }
  };

  const renderAddSpeaker = () => {
    const speakerPermission =
      pageMode === "view" ? "read" : marketingInfo?.speakers?.permission;

    const speakerValue = marketingInfo?.speakers?.value;
    const speakerSaved = marketingInfo?.speakers?.saved;

    const speakersToDisplay = speakerSaved ? speakerSaved : speakerValue || [];

    if (speakerPermission === "write" || speakerPermission === undefined) {
      if (speakersToDisplay) {
        return (
          <>
            <div className={styles.speakerList}>
              {speakersToDisplay.map((speaker, index) => (
                <div className={styles.speakerItem} key={index}>
                  <Link to={"/events/events-details"}>
                    {speaker?.name ||
                      speaker?.iboNumber ||
                      "No Name For Speaker"}
                  </Link>
                  <div
                    className={styles.removeSpeaker}
                    onClick={() => handleRemoveSpeaker(speaker)}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
            <div
              className={classNames(styles["button-container"])}
              onClick={() => setShowAddSpeakerModal(true)}
            >
              <AddButton description={"Add Speaker"} color="#2A3847" />
            </div>
          </>
        );
      }
      return (
        <div
          className={classNames(styles["button-container"])}
          onClick={() => setShowAddSpeakerModal(true)}
        >
          <AddButton description={"Add Speaker"} color="#2A3847" />
        </div>
      );
    } else if (speakerPermission === "read") {
      return (
        <span className={styles.textValue}>
          {speakersToDisplay.length > 0
            ? speakersToDisplay.map((speaker, index) => (
                <span key={index}>
                  {speaker?.name || speaker?.iboNumber || "No Name For Speaker"}
                  {index < speakersToDisplay.length - 1 ? "," : ""}
                </span>
              ))
            : "N/A"}
        </span>
      );
    } else if (speakerPermission === "hidden") {
      return null;
    }
  };

  // Function to validate all fields
  const validateFields = () => {
    let errors = {};
    let isValid = true;

    const validateField = (fieldData, fieldName) => {
      // Check if the field is required
      if (fieldData.required && fieldData.saved === null) {
        errors[fieldName] = `This field cannot be empty`;
        isValid = false;
      }
    };

    // Validate marketing data fields
    for (const key in marketingData) {
      if (marketingData.hasOwnProperty(key)) {
        validateField(marketingData[key], key);
      }
    }

    // Validate event data fields
    // for (const key in eventData) {
    //   if (eventData.hasOwnProperty(key)) {
    //     validateField(eventData[key], key);
    //   }
    // }

    // Temporary validate event data fields,  skip 'city' and 'state'
    for (const key in eventData) {
      if (eventData.hasOwnProperty(key) && key !== "city" && key !== "state") {
        validateField(eventData[key], key);
      }
    }

    setValidationErrors(errors);

    return isValid;
  };

  const handleAddSpeaker = (speakersToAdd) => {
    // Start with saved speakers, fallback to value if saved is empty or null
    const currentSpeakers = marketingInfo.speakers?.saved
      ? marketingInfo.speakers.saved
      : marketingInfo.speakers?.value || [];

    // Add only unique speakers to the array
    const updatedSpeakers = [
      ...currentSpeakers,
      ...speakersToAdd.filter(
        (newSpeaker) =>
          !currentSpeakers.some(
            (existingSpeaker) => existingSpeaker.wwgId === newSpeaker.wwgId
          )
      ),
    ];

    // Send the updated array back
    onSpeakerChange(updatedSpeakers);
  };

  const handleRemoveSpeaker = (speakerToRemove) => {
    const currentSpeakers = marketingInfo.speakers?.saved
      ? marketingInfo.speakers.saved
      : marketingInfo.speakers?.value || [];

    const updatedSpeakers = currentSpeakers.filter(
      (speaker) => speaker.wwgId !== speakerToRemove.wwgId
    );

    onSpeakerChange(updatedSpeakers);
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.headerContainer}>
            <div className={classNames(styles.title)}>EVENT INFORMATION</div>
            {/* <div className={styles.errorMessage}>
              {Object.keys(validationErrors).length > 0 ? (
                <p>(Please fill out required fields before navigating away.)</p>
              ) : null}
            </div> */}
          </div>
          <hr className={styles.hr} />
          <div className={classNames(styles["form-container"])}>
            <div
              className={classNames(
                styles["input-container"],
                styles.inputItem
              )}
            >
              {renderField(
                marketingData?.title,
                "Display Name",
                "input",
                "marketing",
                "title"
              )}
            </div>

            <div className={classNames(styles["input-container"])}>
              <div className={classNames(styles.flex)}>
                <span className={styles.label}>Speakers</span>
                {renderAddSpeaker()}
              </div>
            </div>

            {marketingInfo?.event?.location?.value === "onsite" && (
              <>
                <div className={styles.radioContainer}>
                  <div className={styles.radioCol}>
                    <span className={styles.label}>
                      Country
                      {marketingInfo?.event?.country?.required &&
                      pageMode == "edit"
                        ? " *"
                        : ""}
                    </span>
                    {renderCountryField()}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles["input-container"],
                    styles.inputItem
                  )}
                >
                  {renderField(
                    eventData?.city,
                    "City",
                    "input",
                    "event",
                    "city"
                  )}
                </div>

                <div className={styles.row}>
                  <div className={styles.stateCol}>
                    <span className={styles.label}>
                      {getLabelForCountry(selectedCountry)}
                    </span>
                    <span
                      className={classNames(
                        !selectedCountry ? styles.disable : styles.select
                      )}
                    >
                      {renderStateSelection()}
                    </span>
                  </div>
                </div>

                {renderCartTypeSelection()}

                {!marketingInfo?.virtual?.eventName ||
                marketingInfo?.virtual?.eventName?.permission ==
                  "hidden" ? null : (
                  <div className={styles.row}>
                    <div className={styles.linkedEvent}>
                      <span className={styles.label}>
                        Linked Virtual Event
                        {marketingInfo?.virtual?.eventName?.required &&
                        pageMode == "edit"
                          ? " *"
                          : ""}
                      </span>
                      <span className={styles.linkedEventItem}>
                        {marketingInfo?.virtual?.eventName.permission !==
                          "write" || pageMode == "view" ? (
                          <>
                            <div className={styles.virtualEventIdText}>
                              {marketingInfo?.virtual?.eventName.saved
                                ? marketingInfo?.virtual?.eventName.saved
                                : marketingInfo?.virtual?.eventName.value}
                            </div>
                          </>
                        ) : marketingInfo?.virtual?.eventName.saved ||
                          marketingInfo?.virtual?.eventName.saved ? (
                          <>
                            <div className={styles.virtualEventIdText}>
                              {marketingInfo?.virtual?.eventName.saved
                                ? marketingInfo?.virtual?.eventName.saved
                                : marketingInfo?.virtual?.eventName.value}
                            </div>
                            <Button
                              className={styles.viewVirtualEventBtn}
                              children={"View"}
                              onClick={() => handleViewVirtual()}
                            />
                            <Button
                              className={styles.addVirtualEventBtn}
                              children={"Edit"}
                              onClick={() => {
                                setSelectedVirtualEventId(
                                  marketingInfo?.virtual?.eventId.saved ||
                                    marketingInfo?.virtual?.eventId.value
                                );
                                setSelectedVirtualEventName(
                                  marketingInfo?.virtual?.eventName.saved ||
                                    marketingInfo?.virtual?.eventName.value
                                );
                                setIsVirtualModalOpen(true);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              className={styles.addVirtualEventBtn}
                              children={"Add"}
                              onClick={() => {
                                setSelectedVirtualEventId(
                                  marketingInfo?.virtual?.eventId.saved ||
                                    marketingInfo?.virtual?.eventId.value
                                );
                                setSelectedVirtualEventName(
                                  marketingInfo?.virtual?.eventName.saved ||
                                    marketingInfo?.virtual?.eventName.value
                                );
                                setIsVirtualModalOpen(true);
                              }}
                            />
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className={styles.datesSection}>
              {marketingInfo && (
                <DateV2Section
                  ruleset={marketingInfo?.event?.ruleset?.value}
                  eventName={marketingInfo?.event?.name?.value}
                  dateInfo={marketingInfo?.event}
                  onDateTimeChange={onDateTimeChange}
                  pageMode={pageMode}
                  section="Event Information"
                  timezonePermission={pageMode === "view" ? "read" : "write"}
                />
              )}
            </div>
          </div>

          <AddSpeakerModal
            isOpen={showAddSpeakerModal}
            onCollapse={() => setShowAddSpeakerModal(false)}
            onClick={(speakers) => handleAddSpeaker(speakers)}
          />

          <Modal
            title="Link Virtual Event"
            className={styles.virtualModal}
            onClose={() => {
              setIsVirtualModalOpen(false);
            }}
            isOpen={isVirtualModalOpen}
            children={
              <>
                <div className={styles.grid3}>
                  <div className={styles.virtualEventList}>
                    {virtualEvents
                      .filter(
                        (e) =>
                          e.marketing.title.saved || e.marketing.title.value
                      )
                      .map((event, index) => (
                        <div className={styles.virtualEvent} key={index}>
                          <div className={styles.checkboxContainer}>
                            <Checkbox
                              className={styles.checkbox}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedVirtualEventId(event.id);
                                  setSelectedVirtualEventName(
                                    event.marketing.title.saved ||
                                      event.marketing.title.value
                                  );
                                } else {
                                  setSelectedVirtualEventId(null);
                                  setSelectedVirtualEventName(null);
                                }
                              }}
                              checked={event.id === selectedVirtualEventId}
                            />
                          </div>
                          <div className={styles.name}>
                            {event.marketing.title.saved ||
                              event.marketing.title.value}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className={styles.virtualNote}>
                    Note: Virtual event must be published to be shown in list
                  </div>
                  <Button
                    className={styles.confirmVirtualEventBtn}
                    children={"Confirm"}
                    onClick={() => {
                      setIsVirtualModalOpen(false);
                      onVirtualEventChange(
                        selectedVirtualEventId,
                        selectedVirtualEventName
                      );
                    }}
                  />
                </div>
              </>
            }
          />
        </section>
      }
    />
  );
}

EventV2InfoForm.propTypes = {
  /**
   * Value of display name input box.
   */
  title: PropTypes.string,
  /**
   * Function to set the display name when changed.
   */
  settitle: PropTypes.func,
  /**
   * Value of job code input box.
   */
  jobCode: PropTypes.string,
  /**
   * Function to set the job code when changed.
   */
  setJobCode: PropTypes.func,
  /**
   * Value of description input box.
   */
  description: PropTypes.string,
  /**
   * Function to set the description when changed.
   */
  setDescription: PropTypes.func,
  /**
   * Value of start date input box.
   */
  startDate: PropTypes.string,
  /**
   * Function to set the start date when changed.
   */
  setStartDate: PropTypes.func,
  /**
   * Value of end date input box.
   */
  endDate: PropTypes.string,
  /**
   * Function to set the end date when changed.
   */
  setEndDate: PropTypes.func,
  /**
   * Value of start time input box.
   */
  startTime: PropTypes.string,
  /**
   * Function to set the start time when changed.
   */
  setStartTime: PropTypes.func,
  /**
   * Value of end time input box.
   */
  endTime: PropTypes.string,
  /**
   * Function to set the end time when changed.
   */
  setEndTime: PropTypes.func,
  /**
   * Value of doors open time input box.
   */
  doorsOpen: PropTypes.string,
  /**
   * Function to set the doors open time when changed.
   */
  setDoorsOpen: PropTypes.func,
};
