import { useState, useEffect } from "react";
import TextInput from "shared/@forms/TextInput";
import DateTimePicker from "shared/@forms/DateTimePicker";
import Button, { ButtonPurpose } from "shared/Button";
import { Row, Column, LabelGroup, Section } from "shared/layout";
import styles from "../index.module.css";
import { Auth } from "shared/@auth/Auth";
import { Event } from "Events/Components/useEvent";
 
const timezoneMapping = {
  PT: "America/Los_Angeles",
  MT: "America/Boise",
  CT: "America/Chicago",
  ET: "America/New_York",
  AKT: "America/Anchorage",
  HAT: "America/Adak",
  HST: "Pacific/Honolulu",
};
 
const formatDate = (dateString, timeZoneAbbreviation) => {
  if (!dateString) return "Invalid Date";
 
  const timeZone =
    timezoneMapping[timeZoneAbbreviation] || timeZoneAbbreviation;
  const date = new Date(dateString);
 
  if (isNaN(date.getTime())) return "Invalid Date";
 
  return new Intl.DateTimeFormat("en-US", {
    timeZone,
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  }).format(date);
};
 
export default function Setup({ eventId, timezone }) {
  const { eventData, updateGeneralInfo, refreshEvent } = Event.useContainer();
  const [isEditing, setIsEditing] = useState(false);
 
  const [checkInStartsOn, setCheckInStartsOn] = useState({
    dateId: null,
    startsOn: "",
  });
  const [checkInEndsOn, setCheckInEndsOn] = useState({
    dateId: null,
    startsOn: "",
  });
  const [syncId, setSyncId] = useState("");
  const [guidId, setGuidId] = useState(null);
 
  const safeTimeZone = timezoneMapping[timezone] || "America/Los_Angeles";
 
  useEffect(() => {
    if (eventData) {
      // console.log("Refetched event data:", eventData);
 
      const checkInStart = eventData.checkInDates?.find(
        (date) => date.dateType === "CHECK IN - STARTS ON"
      );
      const checkInEnd = eventData.checkInDates?.find(
        (date) => date.dateType === "CHECK IN - ENDS ON"
      );
 
      setCheckInStartsOn({
        dateId: checkInStart?.dateID ?? null,
        startsOn: checkInStart?.startsOn || "",
      });
 
      setCheckInEndsOn({
        dateId: checkInEnd?.dateID ?? null,
        startsOn: checkInEnd?.startsOn || "",
      });
 
      setSyncId(eventData.scanningGuid?.guid || "");
      setGuidId(eventData.scanningGuid?.guidId || null);
    }
  }, [eventData]);
 
  const handleSave = () => {
    if (!eventId || !updateGeneralInfo) return;
 
    const updatedInfo = {
      eventId: eventId,
      scanningGuid: {
        lngId: guidId, 
        lngPurchaseTypeId: eventId,
        lngEventId: eventId,
        strGuid: syncId,
      },
      checkInStartsOn: {
        dateId: checkInStartsOn.dateId,
        startsOn: checkInStartsOn.startsOn, 
      },
      checkInEndsOn: {
        dateId: checkInEndsOn.dateId, 
        startsOn: checkInEndsOn.startsOn, 
      },
    };
 
    updateGeneralInfo.mutate(updatedInfo, {
      onSuccess: () => {
        setIsEditing(false);
        refreshEvent(); 
      },
    });
  };
 
  return (
    <Section>
      <header>
        <h3>General Info</h3>
        <div className={styles.buttonsColumn}>
          {!isEditing ? (
            <Button size="small" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                size="small"
                purpose={ButtonPurpose.save}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button size="small" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </>
          )}
        </div>
      </header>
 
      <Row>
        <Column>
          <LabelGroup title="Sync ID">
            {!isEditing ? (
              syncId || "Default ID"
            ) : (
              <TextInput
                value={syncId || ""}
                onChange={(e) => setSyncId(e.target.value)}
              />
            )}
          </LabelGroup>
        </Column>
 
        <Column>
          <LabelGroup title="Check In - Starts on">
            {!isEditing ? (
              checkInStartsOn.startsOn &&
              formatDate(checkInStartsOn.startsOn, safeTimeZone)
            ) : (
              <DateTimePicker
                timeZone={safeTimeZone}
                value={checkInStartsOn.startsOn || ""}
                onChange={(date) =>
                  setCheckInStartsOn((prev) => ({ ...prev, startsOn: date }))
                }
              />
            )}
          </LabelGroup>
        </Column>
 
        <Column>
          <LabelGroup title="Check In - Ends on">
            {!isEditing ? (
              checkInEndsOn.startsOn &&
              formatDate(checkInEndsOn.startsOn, safeTimeZone)
            ) : (
              <DateTimePicker
                timeZone={safeTimeZone}
                value={checkInEndsOn.startsOn || ""}
                onChange={(date) =>
                  setCheckInEndsOn((prev) => ({ ...prev, startsOn: date }))
                }
              />
            )}
          </LabelGroup>
        </Column>
      </Row>
    </Section>
  );
}