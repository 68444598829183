import { Auth } from "shared/@auth/Auth";
import { QUERY_KEYS } from "shared/@utils/queryKeys";
import { useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";

export function useCreateEvent(fetchRulesets) {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    ["rulesets"],
    () =>
      makeAuthedRequest(`v1/events/rulesets/all`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: fetchRulesets,
    }
  );

  const createEvent = useCallback(
    async (event) => {
      return makeAuthedRequest(`v1/events/event/add`, {
        method: "POST",
        body: JSON.stringify(event),
      }).then(async (req) => {
        if (req.ok) {
          const json = await req.json();

          // Invalidate and refetch event-related queries
          // queryClient.invalidateQueries(["events"]);

          // Immediately refetch to ensure fresh data is available
          queryClient.refetchQueries(["events"]);

          return json;
        } else {
          const json = await req.json();
          throw new Error(json.message);
        }
      });
    },
    [queryClient]
  );

  const createNewEvent = useMutation(
    async (newEvent) => {
      const response = await makeAuthedRequest("v1/events/rulesets/new-event", {
        method: "POST",
        body: JSON.stringify(newEvent),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error.message);
      }

      return response.json();
    },
    {
      onSuccess: () => {
        // Invalidate and refetch queries here
        queryClient.invalidateQueries(["rulesets"]);
      },
      onError: (error) => {
        console.error("Error creating new event:", error);
      },
    }
  );

  const eventUpdatePut = useMutation(
    ({ eventObject, eventId }) =>
      makeAuthedRequest(`v1/events/event/update/${eventId}`, {
        method: "PUT",
        body: JSON.stringify(eventObject),
      }),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["event", eventId]);
        createNotification({
          type: "success",
          title: "Saved",
          message: "Successfully Updated",
          autoDismiss: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("Something went wrong!");
      },
    }
  );

  return {
    data,
    refetch,
    isError,
    isLoading,
    createEvent,
    createNewEvent,
    eventUpdatePut,
  };
}
