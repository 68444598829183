import { useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import env from "shared/regionConfig";

const API_BASE_URL = env.apiBase || null;

export function useSession(eventId, scanningSessions = []) {
  const { makeAuthedRequest } = Auth.useContainer();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scanningSessions) {
      setSessions(
        scanningSessions.map((s) => ({
          id: s.Id,
          description: s.Description,
          total: s.Total || 0,
          uniqueTotal: s.UniqueTotal || 0,
        }))
      );
    }
  }, [scanningSessions]);

  async function fetchUpdatedSessions() {
    try {
      const getUrl = `/v1/events/scanner/event/id/${eventId}`;
      const getResponse = await makeAuthedRequest(`${API_BASE_URL}${getUrl}`, {
        method: "GET",
        headers: { Accept: "application/json" },
      });

      if (getResponse.ok) {
        const updatedEvent = await getResponse.json();
        setSessions(
          updatedEvent.scanningSessions.map((s) => ({
            id: s.Id,
            description: s.Description,
            total: s.Total || 0,
            uniqueTotal: s.UniqueTotal || 0,
          }))
        );
      }
    } catch (error) {}
  }

  async function createSession(description) {
    if (!description?.trim()) return;

    const eventData = {
      eventId,
      description,
      startsOn: null,
    };

    try {
      const response = await makeAuthedRequest(`${API_BASE_URL}/v1/events/session`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });

      if (response.ok) {
        await fetchUpdatedSessions();
      }
    } catch (error) {}
  }

  async function updateSession(sessionId, description) {
    if (!sessionId || !description?.trim()) return;

    const eventData = {
      id: sessionId,
      eventId,
      description,
      startsOn: null,
    };

    try {
      const response = await makeAuthedRequest(`${API_BASE_URL}/v1/events/session`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });

      if (response.ok) {
        await fetchUpdatedSessions();
      }
    } catch (error) {}
  }

  async function removeSession(id) {
    try {
      const url = `/v1/events/session/${id}`;
      const response = await makeAuthedRequest(`${API_BASE_URL}${url}`, {
        method: "DELETE",
        headers: { Accept: "application/json" },
      });

      if (response.ok) {
        setSessions((prev) => prev.filter((s) => s.id !== id));
      }
    } catch (error) {}
  }

  return {
    sessions,
    loading,
    setLoading,
    fetchUpdatedSessions,
    createSession,
    updateSession,
    removeSession,
  };
}
