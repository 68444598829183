import { useState } from "react";
import { Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import Table from "shared/Table";
import styles from "../index.module.css";
import PhoneInput from "shared/@forms/PhoneInput";
import { useSecurity } from "./useSecurity";
import Loader from "shared/Loader";

export default function Security({
  eventId,
  scanningSecurities = [],
  refreshEventData,
}) {
  const {
    securities,
    fetchUpdatedSecurities,
    onRemove,
    createSecurity,
    updateSecurity,
  } = useSecurity(eventId, scanningSecurities);

  const [editingId, setEditingId] = useState(null);
  const [tempAdd, setTempAdd] = useState(null);
  const [loading, setLoading] = useState(false);

  function onCancel() {
    setEditingId(null);
    setTempAdd(null);
  }

  function onAddClick() {
    if (!tempAdd) {
      setTempAdd({ id: null, firstName: "", lastName: "", phone: "" });
    }
  }

  async function onSave(security) {
    setLoading(true);

    if (security.id === null) {
      await createSecurity(
        security.firstName,
        security.lastName,
        security.phone
      );
    } else {
      await updateSecurity(
        security.id,
        security.firstName,
        security.lastName,
        security.phone
      );
    }

    await fetchUpdatedSecurities(); 

    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }

    setEditingId(null);
    setTempAdd(null);
    setLoading(false); 
  }

  async function handleRemove(id) {
    await onRemove(id);
    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }
  }

  return (
    <Section className={styles.sectionTopMargin}>
      <header>
        <h3>Staffing</h3>
        <div>
          <Button size="small" onClick={onAddClick}>
            Add
          </Button>
        </div>
      </header>
      {loading ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Loader />
        </div>
      ) : (
        <Table>
          {securities.length > 0 && (
            <thead>
              <tr>
                <th>Edit</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
          )}
          <tbody>
            {securities.length > 0 || tempAdd ? (
              <>
                {securities.map((s) =>
                  editingId !== s.id ? (
                    <tr key={s.id}>
                      <td width="25px">
                        <Button size="small" onClick={() => setEditingId(s.id)}>
                          Edit
                        </Button>
                      </td>
                      <td>{s.firstName}</td>
                      <td>{s.lastName}</td>
                      <td>{s.phone}</td>
                      <td className={styles.buttonsColumn}>
                        <Button
                          size={ButtonSize.small}
                          purpose={ButtonPurpose.delete}
                          onClick={() => handleRemove(s.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    <SecurityEdit
                      key={s.id}
                      security={s}
                      onCancel={onCancel}
                      onSave={onSave}
                    />
                  )
                )}
                {tempAdd && (
                  <SecurityEdit
                    key="new-security"
                    security={tempAdd}
                    onCancel={onCancel}
                    onSave={onSave}
                  />
                )}
              </>
            ) : (
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  There are currently no Staff in this event.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Section>
  );
}

function SecurityEdit({ security, onCancel, onSave }) {
  const [localSecurity, setLocalSecurity] = useState({
    ...security,
    firstName: security.firstName || "",
    lastName: security.lastName || "",
    phone: security.phone || "",
  });

  function handleChange(e) {
    setLocalSecurity({ ...localSecurity, [e.target.name]: e.target.value });
  }

  function handleSave() {
    onSave(localSecurity);
  }

  return (
    <tr>
      <td width="25px"></td>
      <td>
        <TextInput
          name="firstName"
          placeholder="First Name"
          value={localSecurity.firstName}
          onChange={handleChange}
        />
      </td>
      <td>
        <TextInput
          name="lastName"
          placeholder="Last Name"
          value={localSecurity.lastName}
          onChange={handleChange}
        />
      </td>
      <td>
        <PhoneInput
          name="phone"
          placeholder="555.555.5555"
          value={localSecurity.phone}
          onChange={handleChange}
        />
      </td>
      <td className={styles.buttonsColumn}>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.save}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.generic}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );
}
