import React, { useEffect, useState } from "react";
import { Auth } from "shared/@auth/Auth";
import classNames from "classnames";
import Table from "shared/Table";
import Card from "shared/Card";
import Checkbox from "shared/@forms/Checkbox";
import { Column, Row, Section } from "shared/layout";
import formatDate from "shared/@utils/formatDate";
import styles from "./index.module.css";
import Order from "../Order";
import Button from "shared/Button";
import NewOrderModal from "Events/Components/NewOrderModal";

import { descendingBy } from "shared/@utils/sort";
import { RECENT_EVENTS_IBOS_KEY } from "../OrdersByIBOLegacy/index";
import { useMount, useUnmount } from "react-use";

function appendToRecents(iboNumber, name) {
  const recentEventIbos = JSON.parse(
    sessionStorage.getItem(RECENT_EVENTS_IBOS_KEY) || "[]"
  );

  let newList = recentEventIbos.filter((item) => item.iboNumber !== iboNumber);
  newList.sort(descendingBy("timestamp"));
  newList = newList.slice(0, 9);

  newList.unshift({
    iboNumber,
    name,
    timestamp: new Date(),
  });

  sessionStorage.setItem(RECENT_EVENTS_IBOS_KEY, JSON.stringify(newList));
}

export default function OrdersList({ ordersList, refreshIBOInfo }) {
  const { iboInfo, filters, orders = [] } = ordersList;

  const { hasPermission } = Auth.useContainer();

  useMount(() => {
    appendToRecents(iboInfo.iboNumber, iboInfo.displayName, iboInfo.iboA);
  });

  useUnmount(() => {
    appendToRecents(iboInfo.iboNumber, iboInfo.displayName);
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filteredOrders, setFilteredOrders] = useState(orders);

  const [eventYears, setEventYears] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [eventStatus, setEventStatus] = useState([]);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (filters?.eventYears?.length >= 1) {
      setEventYears((prevYears) => [...prevYears, filters.eventYears[0]]);
    }

    setEventTypes(filters?.eventTypes ?? []);

    let completedIndex = filters?.eventStatus?.indexOf("COMPLETED");
    if (completedIndex !== -1) {
      setEventStatus((prevStatus) => [...prevStatus, "COMPLETED"]);
    } else if (filters?.eventStatus?.length >= 1) {
      setEventStatus((prevStatus) => [...prevStatus, filters.eventStatus[0]]);
    }
  }, [filters]);

  useEffect(() => {
    filterOrders();
  }, [eventTypes, eventYears, eventStatus]);

  useEffect(() => {
    setOrder(filteredOrders[selectedIndex]);
  }, [selectedIndex, filteredOrders]);

  useEffect(() => {
    if (orders) {
      // Your existing filtering logic
      // setFilteredOrders(orders);
      filterOrders();
    }
  }, [orders]);

  const onEventTypeFilterClicked = (event) => {
    const { checked, value } = event.currentTarget;

    setEventTypes((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const onEventYearFilterClicked = (event) => {
    const { checked, value } = event.currentTarget;

    setEventYears((prev) =>
      checked
        ? [...prev, parseInt(value)]
        : prev.filter((val) => val !== parseInt(value))
    );
  };

  const onEventStatusFilterClicked = (event) => {
    const { checked, value } = event.currentTarget;

    setEventStatus((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const filterOrders = () => {
    let updatedFilteredOrders = [];

    orders.forEach((order) => {
      if (
        eventYears.indexOf(order.orderInfo.year) !== -1 &&
        eventStatus.indexOf(order.orderInfo.status) !== -1 &&
        eventTypes.indexOf(order.orderInfo.eventName) !== -1
      ) {
        updatedFilteredOrders.push(order);
      }
    });

    if (updatedFilteredOrders.length === 0) {
      setFilteredOrders([]);
      setOrder(null);
    } else {
      setFilteredOrders(updatedFilteredOrders);
      // setSelectedIndex(0);
      setOrder(updatedFilteredOrders[selectedIndex]);
    }
  };

  const [showNewOrderModal, setShowNewOrderModal] = useState(false);

  const onCancelNewOrderModal = () => {
    setShowNewOrderModal(false);
  };

  const openNewOrderModal = () => {
    setShowNewOrderModal(true);
  };

  return (
    <div>
      {showNewOrderModal && (
        <NewOrderModal
          onClose={onCancelNewOrderModal}
          iboNumber={iboInfo.iboNumber}
          refreshIBOInfo={refreshIBOInfo}
        />
      )}

      <header className={styles.eventHeader}>
        <div className={styles["ibo-container"]}>
          <span className={styles.headerEventType}>
            IBO {iboInfo.iboNumber}
          </span>

          <Button
            className={styles["dropdown-toggle"]}
            onClick={openNewOrderModal}
          >
            NEW ORDER
          </Button>
        </div>
        <h1>{iboInfo.displayName}</h1>
      </header>
      <Card className={styles.container}>
        <Row>
          <Column>
            <Section>
              <header>
                <h3>Event Name</h3>
              </header>
              <Row>
                <Column>
                  {filters.eventTypes &&
                    filters.eventTypes?.map((eventType, index) => {
                      return (
                        <div className={styles.checkboxGroup} key={index}>
                          <Checkbox
                            id={eventType}
                            value={eventType}
                            checked={eventTypes.indexOf(eventType) !== -1}
                            onChange={onEventTypeFilterClicked}
                          />
                          <label htmlFor={eventType}>{eventType}</label>
                        </div>
                      );
                    })}
                </Column>
              </Row>
            </Section>
          </Column>
          <div className={styles.divider} />
          <Column>
            <Section>
              <header>
                <h3>Event Year</h3>
              </header>
              <Row>
                <Column>
                  {filters.eventYears &&
                    filters.eventYears.map((year, index) => {
                      return (
                        <div className={styles.checkboxGroup} key={index}>
                          <Checkbox
                            id={year}
                            value={year}
                            checked={eventYears.some((val) => val === year)}
                            onChange={onEventYearFilterClicked}
                          />
                          <label htmlFor={year}>{year}</label>
                        </div>
                      );
                    })}
                </Column>
              </Row>
            </Section>
          </Column>
          <div className={styles.divider} />
          <Column>
            <Section>
              <header>
                <h3>Status</h3>
              </header>

              <Row>
                <Column>
                  {filters.eventStatus &&
                    filters.eventStatus.map((status, index) => {
                      return (
                        <div className={styles.checkboxGroup} key={index}>
                          <Checkbox
                            id={status}
                            value={status}
                            checked={eventStatus.some((val) => val === status)}
                            onChange={onEventStatusFilterClicked}
                          />
                          <label htmlFor={status}>{status}</label>
                        </div>
                      );
                    })}
                </Column>
              </Row>
            </Section>
          </Column>
        </Row>
      </Card>
      <div className={styles.tableHeight}>
        <Table>
          <thead>
            <tr>
              <th width="250px">Date</th>
              <th>Order ID</th>
              <th>Event Name</th>
              <th>Event Year</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length === 0 && (
              <tr>
                <td colSpan="6">
                  No Orders Found (please review your filters)
                </td>
              </tr>
            )}
            {filteredOrders.length !== 0 &&
              filteredOrders.map((order, i) => {
                return (
                  <tr
                    key={i}
                    onMouseDown={() => {
                      setSelectedIndex(i);
                    }}
                    className={classNames(styles.resultRow, {
                      [styles.selected]: selectedIndex === i,
                    })}
                  >
                    <td>{formatDate(order.orderInfo.createdOn)}</td>
                    <td>{order.orderInfo.orderId}</td>
                    <td>{order.orderInfo.eventName}</td>
                    <td>{order.orderInfo.year}</td>
                    <td>{order.orderInfo.status}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {order && <Order iboInfo={iboInfo} order={order} />}
    </div>
  );
}
