import { Auth } from "shared/@auth/Auth";
import { useMutation } from "react-query";

export function useRefundOrder() {
  const { makeAuthedRequest } = Auth.useContainer();

  const refundOrder = useMutation(async ({ orderId, ibo, addFee, tickets }) => {
    const res = await makeAuthedRequest(`v1/events/order/new/refund`, {
      method: "PUT",
      body: JSON.stringify({ orderId, ibo, addFee, tickets }),
    });

    if (res.ok) {
      return res;
    } else {
      const body = await res.json();
      return body.error?.message.message;
    }
  });

  const cancelOrder = useMutation(async (tickets) => {
    const res = await makeAuthedRequest(`v1/events/order/new/cancel`, {
      method: "PUT",
      body: JSON.stringify(tickets),
    });

    if (res.ok) {
      return res;
    } else {
      const body = await res.json();
      return body.error?.message.message;
    }
  });

  return {
    refundOrder,
    cancelOrder,
  };
}
