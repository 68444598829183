import { Auth } from "shared/@auth/Auth";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";

export function useEventsData(iboNum) {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, isLoading, refetch } = useQuery(
    // Query Key for invalidating data
    ["eventsOnSale", iboNum],
    () =>
      makeAuthedRequest(`v1/events/sale/ibo/${iboNum}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  const clearCartPost = useMutation(
    ({ iboNumber, eventId}) =>
      makeAuthedRequest(`v1/events/order/clear-cart/ibo/${iboNumber}/eventId/${eventId}`, {
        method: "POST",
      }).then(async (res) => {
        if (res.ok) {
          return res;
        } else {
        const body = await res.json();
        throw new Error(body.error);
        }
      }),
  )

  const addToCartPost = useMutation(
    ({ iboNumber, eventId, ticketData }) =>
    makeAuthedRequest(
      `v1/events/order/new/cart/ibo/${iboNumber}/eventId/${eventId}`, {
        method: "POST",
        body: JSON.stringify(ticketData)
      }).then(async (res) => {
      if (res.ok) {
        return res;
      } else {
        const body = await res.json();
        throw new Error(body.error);
      }
    })
  );

  const createOrderPost = useMutation(
    ({ iboNumber, orderData }) =>
    makeAuthedRequest(
      `v1/events/order/new/ibo/${iboNumber}`,{
        method: "POST",
        body: JSON.stringify(orderData),
      }).then(async (res) => {
        if (res.ok) {
          const body = await res.json();
        return body;
      } else {
        const body = await res.json();
        throw new Error(body.error.message?.message || body.error.message);
      }
    })
  );

  return {
    data,
    isLoading,
    refetch,
    clearCartPost,
    addToCartPost,
    createOrderPost,
  };
}
