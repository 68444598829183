import { useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import { useMutation, useQueryClient } from "react-query";

export function useTicketSpecialNeeds() {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useTicketSpecialNeeds" });

  const updateSpecialNeedsMutation = useMutation(
    async ({ iboNumber, body }) => {
      return makeAuthedRequest(`v1/events/order/ada-value/ibo/${iboNumber}`, {
        method: "PUT",
        body: JSON.stringify(body),
      });
    },
    {
      onError: (error) => {
        console.error("Error updating special needs:", error);
      },
    }
  );

  useEffect(() => {
    if (updateSpecialNeedsMutation.isError) {
      logError(updateSpecialNeedsMutation.error);
    }
  }, [updateSpecialNeedsMutation.isError, updateSpecialNeedsMutation.error]);

  return {
    updateSpecialNeedsMutation,
  };
}
