import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./CalendarPickerModal.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";
import chevron from "./chevron.png";
import moment from "moment";

/**
 * Modal that opens when the user clicks the calendar in create new event.
 */
export function CalendarPickerModal({
  allowPastDate,
  onDateSelect,
  onMonthChange,
  isOpen,
  onCollapse,
  selectedDate,
  endDate,
}) {
  const [currentMonth, setMonth] = useState(null);
  const [currentYear, setYear] = useState(null);
  const [days, setDays] = useState([]);
  const [today, setToday] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const selectedMoment = moment(selectedDate, "MM/DD/YYYY", true);

      if (selectedMoment.isValid()) {
        setMonth(selectedMoment.month());
        setYear(selectedMoment.year());
      } else {
        const today = moment();
        setMonth(today.month());
        setYear(today.year());
      }
    }
  }, [isOpen, selectedDate]);

  useEffect(() => {
    if (currentMonth != null && currentYear != null) {
      setDays(getDays(currentMonth + 1, currentYear));
    }
  }, [currentMonth, currentYear, endDate]);

  useEffect(() => {
    setToday(moment());
  }, []);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function incrementMonth() {
    const nextMonthDate = moment([currentYear, currentMonth]).add(1, "month");
    setMonth(nextMonthDate.month());
    setYear(nextMonthDate.year());

    if (onMonthChange) {
      onMonthChange(nextMonthDate.toDate());
    }
  }

  function decrementMonth() {
    // var lastMonthDate = new Date(currentMonth + 1 + "/01/" + currentYear);
    const lastMonthDate = moment([currentYear, currentMonth]).subtract(
      1,
      "month"
    );
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    setMonth(lastMonthDate.getMonth());
    setYear(lastMonthDate.getFullYear());

    if (onMonthChange) {
      onMonthChange(lastMonthDate);
    }
  }

  const isDateAfterEndDate = (date) => {
    if (endDate) {
      const endMoment = moment(endDate, "MM/DD/YYYY", true);
      const isAfter = date.isAfter(endMoment, "day");
      return isAfter;
    }
    return false;
  };

  function isDateBeforeToday(date) {
    if (allowPastDate !== true) {
      const todaysDate = moment();
      return date.isBefore(todaysDate, "day");
    }
  }

  function getDays(month, year) {
    let currentDate = moment([year, month - 1, 1]).startOf("day");

    // Adjust to ensure the calendar starts on Monday
    if (currentDate.day() !== 1) {
      currentDate = currentDate.subtract(
        currentDate.day() === 0 ? 6 : currentDate.day() - 1,
        "days"
      );
    }

    const d = [];
    for (let i = 0; i < 35; i++) {
      const isToday = today && currentDate.isSame(today, "day");
      const isSelected =
        selectedDate &&
        currentDate.isSame(moment(selectedDate, "MM/DD/YYYY"), "day");

      const disabled =
        isDateBeforeToday(currentDate) || isDateAfterEndDate(currentDate);

      d.push({
        day: currentDate.date(),
        month: currentDate.month(),
        year: currentDate.year(),
        dateString: currentDate.format("MM/DD/YYYY"),
        grayedOut: currentDate.month() !== month - 1,
        disabled,
        isToday,
        isSelected, // New property to highlight the selected date
      });

      currentDate = currentDate.add(1, "days");
    }
    return d;
  }

  return (
    <div className={styles.container}>
      <Modal
        title="CHOOSE DATE"
        isOpen={isOpen}
        closeBtn=""
        onClose={onCollapse}
        className={styles.modal}
        children={
          <>
            <div className={classNames(styles.calendar)}>
              <img
                alt=""
                src={chevron}
                className={styles.rightArrow}
                onClick={() => incrementMonth()}
              />
              <img
                alt=""
                src={chevron}
                className={styles.leftArrow}
                onClick={() => decrementMonth()}
              />

              <div className={classNames(styles.monthYear)}>
                {monthNames[currentMonth] + " " + currentYear}
              </div>

              <div className={styles.grid}>
                <div className={styles.gridItem}>Mo</div>
                <div className={styles.gridItem}>Tu</div>
                <div className={styles.gridItem}>We</div>
                <div className={styles.gridItem}>Th</div>
                <div className={styles.gridItem}>Fr</div>
                <div className={styles.gridItem}>Sa</div>
                <div className={styles.gridItem}>Su</div>

                {days.map((day, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.gridItem,
                      styles.days,
                      day.grayedOut ? styles.grayedOut : "",
                      day.disabled ? styles.disabled : "",
                      day.isSelected ? styles.selected : "",
                      day.isToday && !day.isSelected ? styles.today : ""
                    )}
                    onClick={() => {
                      if (!day.disabled && !day.grayedOut) {
                        onDateSelect && onDateSelect(day.dateString);
                      }
                    }}
                  >
                    {day.day}
                  </div>
                ))}
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}

CalendarPickerModal.propTypes = {
  allowPastDate: PropTypes.bool,
  onMonthChange: PropTypes.func,
  onDateSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
  selectedDate: PropTypes.string,
  endDate: PropTypes.string, // Add endDate prop
};
