import { useState } from "react";
import { useLogger } from "shared/@hooks/useLogger";
import { useQueryClient } from "react-query";
import createNotification from "shared/@utils/createNotification";
import { Auth } from "shared/@auth/Auth";
import env from "shared/regionConfig";

export function UseScannerRefreshAll() {
  const { logError } = useLogger({ prefix: "useScannerRefresh" });
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();

  const [loadingScanId, setLoadingScanId] = useState(null);
  const [loadingEventId, setLoadingEventId] = useState(null);
  const [loadingScannerSearch, setLoadingScannerSearch] = useState(false);

  const API_BASE_URL = env.apiBase || null;

  async function getCorrectEventId(eventId) {
    try {
      const response = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/scanner/event/id/${eventId}`
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch correct eventId. Status: ${response.status}`
        );
      }

      const eventData = await response.json();

      return eventData?.eventId ?? eventId;
    } catch (error) {
      logError(error);
      return eventId;
    }
  }

  async function refreshScannerById(scanId, updateHistory, eventRoute) {
    if (!scanId || !eventRoute) {
      return;
    }

    setLoadingScanId(scanId);
    const fetchUrl = `${API_BASE_URL}/v1/events/scanner/history/scan/id/${scanId}`;

    try {
      const response = await makeAuthedRequest(fetchUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          `HTTP error! Status: ${response.status} - ${response.statusText}`
        );
      }

      const updatedData = await response.json();

      if (typeof updateHistory === "function") {
        updateHistory(scanId, updatedData);
      }

      queryClient.invalidateQueries(["scanner", scanId]);

      createNotification({
        type: "success",
        title: "Refreshed",
        message: "Scanner data refreshed successfully.",
        autoDismiss: true,
      });
    } catch (error) {
      logError(error);
    } finally {
      setLoadingScanId(null);
    }
  }

  async function refreshScannerByEvent(eventId, updateHistory, eventRoute) {
    if (!eventId || !eventRoute) {
      return;
    }

    setLoadingEventId(eventId);

    const correctEventId = await getCorrectEventId(eventId);

    const fetchUrl = `${API_BASE_URL}/v1/events/scanner/event/id/${correctEventId}`;

    try {
      const response = await makeAuthedRequest(fetchUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          `HTTP error! Status: ${response.status} - ${response.statusText}`
        );
      }

      const updatedData = await response.json();

      if (typeof updateHistory === "function") {
        updateHistory(correctEventId, updatedData);
      }

      queryClient.invalidateQueries(["scanner", correctEventId]);

      createNotification({
        type: "success",
        title: "Refreshed",
        message: "Scanner data for the event refreshed successfully.",
        autoDismiss: true,
      });
    } catch (error) {
      logError(error);
    } finally {
      setLoadingEventId(null);
    }
  }

  async function ScannerEventSearch(updateResults) {
    setLoadingScannerSearch(true);
    const fetchUrl = `${API_BASE_URL}/v1/events/scanner/events/search?term=year:2025&limit=50`;

    try {
      const response = await makeAuthedRequest(fetchUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          `HTTP error! Status: ${response.status} - ${response.statusText}`
        );
      }

      const eventSearchResults = await response.json();

      if (typeof updateResults === "function") {
        updateResults(eventSearchResults);
      }

      createNotification({
        type: "success",
        title: "Search Complete",
        message: "Scanner events retrieved successfully.",
        autoDismiss: true,
      });
    } catch (error) {
      logError(error);
    } finally {
      setLoadingScannerSearch(false);
    }
  }
async function syncScanner(scanId) {
  if (!scanId) return;

  try {
    const response = await makeAuthedRequest(`${API_BASE_URL}/v1/events/scanner/sync`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ scanId }),
    });

    if (!response.ok) {
      throw new Error(`Sync failed. Status: ${response.status}`);
    }

    let result = null;
    const text = await response.text();
    if (text) {
      result = JSON.parse(text);
    }

    createNotification({
      type: "success",
      title: "Synced",
      message: "Scanner data synced successfully.",
      autoDismiss: true,
    });

    return result;
  } catch (error) {
    logError(error);
    createNotification({
      type: "error",
      title: "Sync Failed",
      message: "Unable to sync scanner data.",
      autoDismiss: true,
    });
  }
}


  return {
    refreshScannerById,
    refreshScannerByEvent,
    ScannerEventSearch,
    syncScanner,
    loadingScanId,
    loadingEventId,
    loadingScannerSearch,
  };
}
