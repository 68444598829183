import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import styles from "./PublishingV2InfoForm.module.css";
import Checkbox from "shared/@forms/Checkbox";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";
import { Select } from "shared/Select/Select";
import AddGroupModal from "Events/Components/AddGroupModal";
import { GroupsGet } from "Events/Components/TicketGroup/GroupsGet";
import Loader from "shared/Loader";
import VisibleToModal from "Events/Components/VisibleToModal";
import DateV2Section from "../DateV2Section";
import { ImagePicker } from "Events/Components/ImagePicker/ImagePicker";

//Area to fill out the publishing information
export function PublishingV2InfoForm({
  publishingInfo,
  onPublishChange,
  pageMode,
  onDateTimeChange,
  onGroupChange,
  homesitesGroups,
  onCaptionChange,
  onDisplayImageChange,
  isLocal,
}) {
  const {
    data: groupData,
    isLoading: isGroupsLoading,
    refetch: refetchGroupsGet,
  } = GroupsGet();
  const [groups, setGroups] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({
    marketing: {
      tentative: {
        value: publishingInfo?.marketing?.tentative?.saved ?? false,
        permission: publishingInfo?.marketing?.tentative?.permission ?? "write",
      },
    },
  });
  const [inputValues, setInputValues] = useState({});
  const initialInputValuesRef = useRef({});
  const [loadingValue, setLoadingValue] = useState({});
  const [openVisibleToModal, setOpenVisibleToModal] = useState(false);
  const [visibleTo, setVisibleTo] = useState(false);
  const [bookingStatusSelected, setBookingStatusSelected] = useState("");
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [primaryCaptionText, setprimaryCaptionText] = useState(
    publishingInfo?.marketing?.primaryCaption?.saved ||
      publishingInfo?.marketing?.primaryCaption?.value ||
      ""
  );
  const [secondaryCaptionText, setSecondaryCaptionText] = useState(
    publishingInfo?.marketing?.secondaryCaption?.saved ||
      publishingInfo?.marketing?.secondaryCaption?.value ||
      ""
  );

  const [displayImage, setDisplayImage] = useState(
    publishingInfo?.marketing?.displayImage?.saved ||
      publishingInfo?.marketing?.displayImage?.value ||
      ""
  );

  const [errors, setErrors] = useState({
    image: "",
  });

  const isRulesetLocal = publishingInfo.event.ruleset.value;

  useEffect(() => {
    if (groupData) {
      setGroups(groupData);
    }
  }, [groupData]);

  useEffect(() => {
    const newPrimaryCaption =
      publishingInfo?.marketing?.primaryCaption?.saved ||
      publishingInfo?.marketing?.primaryCaption?.value ||
      "";

    const newSecondaryCaption =
      publishingInfo?.marketing?.secondaryCaption?.saved ||
      publishingInfo?.marketing?.secondaryCaption?.value ||
      "";

    const newDisplayImage =
      publishingInfo?.marketing?.displayImage?.saved ||
      publishingInfo?.marketing?.displayImage?.value ||
      "";

    setprimaryCaptionText(decodeURI(newPrimaryCaption));
    setSecondaryCaptionText(decodeURI(newSecondaryCaption));
    setDisplayImage(newDisplayImage);

    setVisibleTo(
      publishingInfo.marketing?.visibleTo?.saved ||
        publishingInfo.marketing?.visibleTo?.value
    );
  }, [publishingInfo]);

  useEffect(() => {
    setCheckboxStates({
      marketing: {
        visibleCalendar:
          publishingInfo?.marketing?.visibleCalendar?.saved ??
          publishingInfo?.marketing?.visibleCalendar?.value ??
          "",
        tentative: {
          value:
            publishingInfo?.marketing?.tentative?.saved ??
            publishingInfo?.marketing?.tentative?.value ??
            false, // Default to false if not set
          permission: "write", // Default to "write" if not set
        },
      },
      analytics: {
        countingTickets:
          publishingInfo?.analytics?.countingTickets?.saved ??
          publishingInfo?.analytics?.countingTickets?.value ??
          "",
        sales: {
          soldOut:
            publishingInfo?.analytics?.sales?.soldOut?.saved ??
            publishingInfo?.analytics?.sales?.soldOut?.value ??
            "",
        },
      },
    });
  }, [publishingInfo]);
  useEffect(() => {
    setInputValues({
      purchase:
        publishingInfo?.analytics?.sales.capacity?.purchase?.saved ??
        publishingInfo?.analytics?.sales.capacity?.purchase?.value ??
        "",

      platinum:
        publishingInfo?.analytics?.sales.capacity?.platinum?.saved ??
        publishingInfo?.analytics?.sales.capacity?.platinum?.value ??
        "",

      estimatedAttendance:
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.saved ??
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.value ??
        "",
    });
  }, [publishingInfo]);

  const [isRequired, setIsRequired] = useState({
    visibleTo: false,
    displayImage: false,
  });

  useEffect(() => {
    const updatedIsRequired = { ...isRequired };

    if (publishingInfo?.marketing?.visibleTo?.required !== undefined) {
      updatedIsRequired.visibleTo =
        publishingInfo?.marketing?.visibleTo?.required;
    }
    if (publishingInfo?.marketing?.displayImage?.required !== undefined) {
      updatedIsRequired.displayImage =
        publishingInfo?.marketing?.displayImage?.required;
    }

    setIsRequired(updatedIsRequired);
  }, [publishingInfo]);

  const handleCheckboxChange = (section, field, isChecked) => {
    const newStates = { ...checkboxStates };

    const fullPath =
      section === "analytics" && field === "soldOut"
        ? "analytics.sales.soldOut"
        : `${section}.${field}`;

    const newSavedValue = isChecked ? "true" : "false";

    // Immediately update UI
    newStates[section][field] = {
      ...newStates[section][field],
      value: !isChecked,
      saved: newSavedValue,
    };

    setCheckboxStates(newStates);

    setLoadingValue((prev) => ({ ...prev, [fullPath]: true }));
    onPublishChange({ path: fullPath, value: newSavedValue })
      .then(() => {
        // Confirm the change was successful and update the saved state
        setCheckboxStates((prev) => ({
          ...prev,
        }));
      })
      .catch((error) => {
        // Revert the checkbox state in case of an error
        setCheckboxStates((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: {
              ...prev[section][field],
              value: !isChecked,
              saved: !isChecked ? "true" : "false",
            },
          },
        }));
      })
      .finally(() => {
        setLoadingValue((prev) => ({ ...prev, [fullPath]: false }));
      });
  };

  const handleInputChange = (key, newValue) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  const handleInputFocus = (key) => {
    initialInputValuesRef.current[key] = inputValues[key] ?? "";
  };

  const handleInputBlur = (key) => {
    const currentValue = inputValues[key];
    const initialValue = initialInputValuesRef.current[key];
    if (currentValue !== initialValue) {
      setLoadingValue((prev) => ({ ...prev, [key]: true }));

      const updatePath = `analytics.sales.capacity.${key}`;
      // Ensure that onPublishChange returns a promise
      onPublishChange({ path: updatePath, value: currentValue })
        .catch((error) => {
          console.error("API call failed for", key, error);
        })
        .finally(() => {
          setLoadingValue((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
    }
  };

  const handleCaptionsInputChange = (dataType, event) => {
    let newValue = event.target.value;

    if (dataType === "marketing.primary") {
      setprimaryCaptionText(newValue);
    } else if (dataType === "marketing.secondary")
      setSecondaryCaptionText(newValue);
  };

  const handleCaptionPrimaryBlur = () => {
    onCaptionChange("marketing.primary", primaryCaptionText);
  };

  const handleCaptionSecondaryBlur = () => {
    onCaptionChange("marketing.secondary", secondaryCaptionText);
  };

  const handleVisibleToChange = (newValue) => {
    if (newValue !== visibleTo) {
      onPublishChange({
        path: "marketing.visibleTo",
        value: newValue,
      })
        .catch((error) => {
          console.error("API call failed for Visible To", error);
        })
        .finally(() => {
          setVisibleTo(newValue);
          if (newValue == "All") {
            onGroupChange([]);
          }
        });
    }
  };
  const handleAddGroups = (newGroups) => {
    const currentGroups =
      publishingInfo.visibleTo?.saved
        ? publishingInfo.visibleTo?.saved
        : publishingInfo.visibleTo?.value || [];

    const updatedGroups = [
      ...currentGroups,
      ...newGroups.filter(
        (newGroup) =>
          !currentGroups.some(
            (existingGroup) => existingGroup.id === newGroup.id
          )
      ),
    ];

    onGroupChange(updatedGroups);
  };
  const handleRemoveGroup = (groupToDelete) => {
    const currentGroups =
      publishingInfo.visibleTo?.saved
        ? publishingInfo.visibleTo?.saved
        : publishingInfo.visibleTo?.value || [];

    const updatedGroups = currentGroups.filter(
      (group) => group.id !== groupToDelete.id
    );

    onGroupChange(updatedGroups);
  };

  const handleImageChange = (selectedImage) => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageData = event.target.result;
        setDisplayImage(base64ImageData);

        if (onDisplayImageChange) {
          onDisplayImageChange({
            path: "marketing.displayImage",
            value: base64ImageData,
          });
        }

        //Clear error if user uploaded an image
        if (errors.image) {
          setErrors({ ...errors, image: "" });
        }
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setDisplayImage(null);
    }
  };

  const renderCheckbox = (section, fieldIdentifier, onChange, key) => {
    const field =
      section === "marketing"
        ? checkboxStates[section]?.[fieldIdentifier] ?? {}
        : section === "analytics" && fieldIdentifier === "soldOut"
        ? publishingInfo?.analytics?.sales[fieldIdentifier]
        : publishingInfo?.analytics[fieldIdentifier];
    const isLoading = loadingValue[`${section}.${fieldIdentifier}`];
    const checkedStatus = field?.value ?? false; // Fallback to false if undefined

    const permission = pageMode === "view" ? "read" : field?.permission;

    if (permission === "write" || permission === undefined) {
      return (
        <>
          <span className={styles.checkbox}>
            <Checkbox
              checked={checkedStatus}
              disabled={isLoading}
              onChange={(e) => {
                handleCheckboxChange(
                  section,
                  fieldIdentifier,
                  e.target.checked
                );
              }}
            />
          </span>
        </>
      );
    } else if (permission === "read") {
      return (
        <>
          <span className={styles.textValue}>
            {field === true ||
            publishingInfo?.marketing?.[fieldIdentifier] === true
              ? "Yes"
              : "No"}
          </span>
        </>
      );
    }
    if (permission === "hidden") {
      return null;
    }
  };

  const renderInput = (field, label, key) => {
    const permission = pageMode === "view" ? "read" : field?.permission;

    const isPlatinumDisabled =
      (key === "platinum" || key === "estimatedAttendance") &&
      loadingValue["purchase"];
    const inputValue = inputValues[key] ?? field?.saved ?? field?.value ?? 0;
    const isLoading = loadingValue[key];

    //Adjust alignment of capacity or attendance
    const labelStyle = (() => {
      if (label) {
        return {
          minWidth: "184px",
          display: "inline-block",
        };
      }
    })();

    // If permission is hidden, don't render anything
    if (
      permission === "hidden" ||
      (pageMode === "view" && field?.permission === "hidden")
    ) {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <div className={styles.colValue}>
          <span className={styles.label} style={labelStyle}>
            {label}
          </span>
          <span className={styles.textInput}>{inputValue || 0}</span>
        </div>
      );
    }

    return (
      <>
        {/* {isLoading && !inputValue ? (
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
          </div>
        ) : ( */}
        <InputItem
          label={`${label}${field?.required == true ? " *" : ""}`}
          value={inputValue}
          // placeholder="0"
          onChange={(e) => handleInputChange(key, e.target.value)}
          onBlur={() => handleInputBlur(key)}
          onFocus={() => handleInputFocus(key)}
          className={isPlatinumDisabled ? styles.disabled : styles.inputItem}
          // disabled={isPlatinumDisabled}
          tabIndex={isPlatinumDisabled ? -1 : 0}
          style={{ cursor: isPlatinumDisabled ? "not-allowed" : "auto" }}
        />
        {/* )} */}
      </>
    );
  };
  useEffect(() => {
    const newVisibleTo =
      publishingInfo?.marketing?.visibleTo?.saved ||
      publishingInfo?.marketing?.visibleTo?.value ||
      "Select";

    // Update only if the value has actually changed
    if (newVisibleTo !== visibleTo) {
      setVisibleTo(newVisibleTo);
    }
  }, [publishingInfo]); // Runs only when `publishingInfo` changes

  const renderVisibleTo = () => {
    const visiblePermission =
      pageMode === "view"
        ? "read"
        : publishingInfo?.marketing?.visibleTo?.permission;

    const visibleToValue = publishingInfo?.marketing?.visibleTo?.value;
    const visibleToSaved = publishingInfo?.marketing?.visibleTo?.saved;

    const visibleToValueToRead =
      publishingInfo?.visibleTo?.saved
        ? publishingInfo?.visibleTo?.saved
        : publishingInfo?.visibleTo?.value || [];

    if (visiblePermission === "write" || visiblePermission === undefined) {
      return (
        <>
          <span className={styles.visibleToSelect}>
            <Select
              placeholder={visibleTo || "Select"}
              value={visibleTo}
              options={["All", "Limit To Groups"]}
              onChange={(value) => handleVisibleToChange(value)}
            />
          </span>
          {visibleTo === "Limit To Groups" && (
            <div className={styles.row}>
              <div className={styles.badgesList}>
                {visibleToValueToRead.map((group, index) => (
                  <div className={styles.speakerItem} key={group.name || index}>
                    {group?.name || "No Name For Group"}
                    <div
                      className={styles.removeSpeaker}
                      onClick={() => handleRemoveGroup(group)}
                    >
                      &times;
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={styles.addBadgesButton}
                onClick={() => {
                  setIsGroupModalOpen(true);
                }}
              >
                <AddButton description={"Add Groups"} color="#2A3847" />
              </div>
            </div>
          )}
        </>
      );
    } else if (visiblePermission === "read") {
      return (
        <span className={styles.textValue}>
          {visibleToSaved || visibleToValue || "N/A"}
        </span>
      );
    } else if (visiblePermission === "hidden") {
      return null;
    }
  };

  const renderCaptionInput = (dataType, label, onChange, onBlur) => {
    let displayValue = "";
    if (dataType === "marketing.primary") {
      displayValue = primaryCaptionText;
    } else if (dataType === "marketing.secondary") {
      displayValue = secondaryCaptionText;
    }

    const permission = (() => {
      if (dataType === "marketing.primary") {
        return publishingInfo?.marketing?.primaryCaption?.permission;
      }
      if (dataType === "marketing.secondary") {
        return publishingInfo?.marketing?.secondaryCaption?.permission;
      }
      return "hidden";
    })();

    if (permission === "hidden") {
      return null;
    }

    if (permission !== "write" || pageMode === "view") {
      return (
        <div className={styles.captionInputWrapper}>
          <span className={styles.viewLabel}>{label}</span>
          <span className={styles.textInput}>{displayValue || "N/A"}</span>
        </div>
      );
    }

    return (
      <div className={styles.captionInputWrapper}>
        <span className={styles.captionLabel}>{label}</span>
        <InputItem
          value={displayValue}
          onChange={(event) => onChange(event)}
          onBlur={onBlur}
          className={styles.captionInput}
          removeLabel={true}
          placeholder="Click to type"
        />
      </div>
    );
  };

  const renderDisplayImage = (displayValue, label) => {
    // Should only register on Local v2.0
    if (publishingInfo?.event?.ruleset?.value !== "Local v2.0") {
      return null;
    }

    const permission =
      pageMode === "view"
        ? "read"
        : publishingInfo?.marketing?.displayImage?.permission;

    const imageSrc = permission === "write" ? displayImage : displayValue;

    // Render the ImagePicker if permission is "write"
    if (permission === "write" || permission === undefined) {
      return (
        <div className={styles.displayImage}>
          <div className={styles.col}>
            <span className={styles.imageLabel}>
              Display Image{" "}
              {isRequired.displayImage && pageMode == "edit" ? " *" : ""}
            </span>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={handleImageChange}
                isPublish={true}
                imgSrc={displayImage}
                className={styles.icon}
              />
            </div>
          </div>
        </div>
      );
    }

    // Render the label and non-editable display image if permission is "read"
    if (permission === "read") {
      return (
        <div className={styles.displayImage}>
          <div className={styles.col}>
            <span className={styles.imageLabel}>{label}</span>
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="Display"
                className={styles.displayImagePreview}
              />
            ) : (
              <span className={styles.textValue}>N/A</span>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const countingTicketsValue =
    publishingInfo?.analytics?.countingTickets?.saved ||
    publishingInfo?.analytics?.countingTickets?.value;
  const isCountingTicketsChecked =
    countingTicketsValue === true || countingTicketsValue === "Yes";

  const handleAddVisibleTo = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  const handleCloseVisibleModal = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  // const handleTentative = () => {
  //   setTentative(!tentative);
  // };

  return (
    <Card
      children={
        <section className={styles.container}>
          <div className={styles.title}>PUBLISHING INFORMATION</div>

          <hr className={styles.hr} />

          {isRulesetLocal === "Local v2.0" && (
            <>
              {renderCaptionInput(
                "marketing.primary",
                `Display First Name(s)${
                  publishingInfo?.marketing?.primaryCaption?.required &&
                  pageMode === "edit"
                    ? " *"
                    : ""
                }`,
                (event) =>
                  handleCaptionsInputChange("marketing.primary", event),
                handleCaptionPrimaryBlur
              )}

              {renderCaptionInput(
                "marketing.secondary",
                `Display Last Name${
                  publishingInfo?.marketing?.secondaryCaption?.required &&
                  pageMode === "edit"
                    ? " *"
                    : ""
                }`,
                (event) =>
                  handleCaptionsInputChange("marketing.secondary", event),
                handleCaptionSecondaryBlur
              )}
            </>
          )}

          {renderDisplayImage(
            publishingInfo?.marketing?.displayImage?.saved ||
              publishingInfo?.marketing?.displayImage?.value,
            "Display Image"
          )}

          <div className={styles.row}>
            <div className={styles.tentative}>
              <span className={styles.label}>Tentative</span>
              {renderCheckbox("marketing", "tentative", onPublishChange)}
            </div>
          </div>

          {/*
          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>Sold Out</span>
              {renderCheckbox("analytics", "soldOut", onPublishChange)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>Counting Tickets</span>

              {renderCheckbox("analytics", "countingTickets", onPublishChange)}
            </div>
          </div>
          */}

          {isRulesetLocal === "Local v2.0" ? (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity
                        ?.estimatedAttendance,
                      "Estimated Attendance",
                      "estimatedAttendance"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          ) : (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.platinum,
                      "Platinum Capacity",
                      "platinum"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          )}

          <div className={styles.row}>
            <div className={styles.visibleToCol}>
              <span className={styles.label}>
                Visible to{" "}
                {isRequired.visibleTo && pageMode == "edit" ? " *" : ""}
              </span>

              {renderVisibleTo()}
            </div>
          </div>

          <div className={styles.datesSection}>
            {publishingInfo && (
              <DateV2Section
                location={publishingInfo?.event?.location?.value}
                ruleset={publishingInfo?.event?.ruleset?.value}
                eventName={publishingInfo?.event?.name?.value}
                dateInfo={publishingInfo?.event}
                onDateTimeChange={onDateTimeChange}
                pageMode={pageMode}
                section="Publishing Information"
                timezonePermission={pageMode === "view" ? "read" : "write"}
                isLocal={isRulesetLocal === "Local v2.0"}
              />
            )}
          </div>

          <VisibleToModal
            isOpen={openVisibleToModal}
            onCollapse={handleCloseVisibleModal}
            onClick={handleAddVisibleTo}
          />

          <AddGroupModal
            isOpen={isGroupModalOpen}
            onCollapse={() => setIsGroupModalOpen(false)}
            onClick={(newGroups) => handleAddGroups(newGroups)}
            groups={
              homesitesGroups ? [...groups, ...homesitesGroups] : [...groups]
            }
            groupsLoading={isGroupsLoading}
          />
        </section>
      }
    />
  );
}

PublishingV2InfoForm.propTypes = {
  //Value of visible on date
  visibleOnDate: PropTypes.string,
  //Used to change the visible on date
  setVisibleOnDate: PropTypes.func,
  //Value of visible on time
  visibleOnTime: PropTypes.string,
  //used to set the visible on time
  setVisibleOnTime: PropTypes.func,
  //Value of start fee date
  startFeeDate: PropTypes.string,
  //Used to change the start fee date
  setStartFeeDate: PropTypes.func,
  //Value of start fee time
  startFeeTime: PropTypes.string,
  //Used to set start fee time
  setStartFeeTime: PropTypes.func,
  //Value of changeable until date
  changeableUntilDate: PropTypes.string,
  //Used to change the changeable until date
  setChangeableUntilDate: PropTypes.func,
  //Value of changeable until time
  changeableUntilTime: PropTypes.string,
  //Used to set changeable until time
  setChangeableUntilTime: PropTypes.func,
  //value of purchase begin date
  purchaseBeginDate: PropTypes.string,
  //used to set the purchase begin date
  setPurchaseBeginDate: PropTypes.func,
  //value of the purchase begin time
  purchaseBeginTime: PropTypes.string,
  //used to set the purchase begin time
  setPurchaseBeginTime: PropTypes.func,
  //value of purchase end time
  purchaseEndTime: PropTypes.string,
  //used to set the purchase end time
  setPurchaseEndTime: PropTypes.func,
  //value of the purchase end date
  purchaseEndDate: PropTypes.string,
  //used to set the purchase end date
  setPurchaseEndDate: PropTypes.func,
  //value of visible to select
  visibleTo: PropTypes.string,
  //used to set the value of the visible to select
  setVisibleTo: PropTypes.func,
};
