import React, { useState, useMemo, useEffect } from "react";
import { Select } from "../../../shared/Select/Select";
import { SearchBar } from "../../../shared/SearchBar/SearchBar";
import { Tag } from "../Tag/Tag";
import { Button } from "../../../shared/Button/Button";
import { DateRangePickerButton } from "../../../shared/DateRangePickerButton/DateRangePickerButton";
import styles from "./FilterOrderEventNew.module.css";
import { nanoid } from "nanoid";
import classNames from "classnames";

/**
 * A component that can be used to filter events by rulesets
 * and event name. With filter items selected, clicking the filter button
 * will display selected tags underneath. Clicking the "X" will remove the
 * associated tag.
 */

export function FilterOrderEventNew({
  handleFilter,
  venueOptions,
  eventNameOptions,
  rulesetOptions,
  onRulesetChange,
  clearFilters,
  setClearFilters,
}) {
  const [rulesetStatus, setRulesetStatus] = useState("Select");
  const [venueStatus, setVenueStatus] = useState("Select");
  const [eventNameStatus, setEventNameStatus] = useState("Select");
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [search, setSearch] = useState("");
  const [showTags, setShowTags] = useState(false);
  const [tagArray, setTagArray] = useState({});
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    if (clearFilters) {
      setRulesetStatus("Select");
      setVenueStatus("Select");
      setEventNameStatus("Select");
      setStartDate(false);
      setEndDate(false);
      setSearch("");
      setSearchKey(id);
      setTagArray({});
      setShowTags(false);
      setButtonStatus(false);
      handleFilter({});
      setClearFilters(false); // Reset the clearFilters flag
    }
  }, [clearFilters, handleFilter, setClearFilters]);

  // Ensure "Select" is always an option
  const extendedRulesetOptions = ["Select", ...rulesetOptions];
  const extendedVenueOptions = ["Select", ...venueOptions];

  // Define the mapping between rulesets and event names
  const eventOptionsMapping = {
    "Major v1.0": ["Select", "Free Enterprise", "Spring Leadership"],
    "LOS v1.0": [
      "Select",
      "Erickson Family Reunion",
      "Baker Family Reunion",
      "Puryear Family Reunion",
      "Duncan Family Reunion",
    ],
  };

  const getFilteredEventNameOptions = () => {
    // if (rulesetStatus === "Select") {
      return ["Select", ...eventNameOptions];
    // }
    // return eventOptionsMapping[rulesetStatus] || ["Select"];
  };

  const [filteredEventNameOptions, setFilteredEventNameOptions] = useState(
    getFilteredEventNameOptions
  );

  useEffect(() => {
    setFilteredEventNameOptions(getFilteredEventNameOptions());
  }, [rulesetStatus, eventNameOptions]);

  // tag array data
  useMemo(() => {}, [tagArray]);

  // re-renders daterangepicker & search bar to reset without modifying components
  const id = nanoid();
  const [dateKey, setDateKey] = useState(id);
  const [searchKey, setSearchKey] = useState(id);

  // add selection to array of tags if valid
  const onFilter = () => {
    setTagArray([]);
    const newObj = {};
    rulesetStatus !== "Select" && (newObj.ruleset = rulesetStatus);
    eventNameStatus !== "Select" && (newObj.eventName = eventNameStatus);
    venueStatus !== "Select" && (newObj.venue = venueStatus);
    startDate && (newObj.startDate = startDate);
    startDate !== endDate && endDate && (newObj.endDate = endDate);
    search && (newObj.search = search);
    setTagArray(newObj);
    setShowTags(true);
    handleFilter(newObj);
  };

  // delete tag that corresponds to x clicked
  const onDelete = (option) => {
    rulesetOptions.includes(option) && setRulesetStatus("Select");
    eventNameOptions.includes(option) && setEventNameStatus("Select");
    venueOptions.includes(option) && setVenueStatus("Select");
    if (option === `${startDate} - ${endDate}` || option === startDate) {
      setStartDate("");
      setEndDate("");
      setDateKey(id);
    }
    if (search === option) {
      setSearch("");
      setSearchKey(id);
    }

    let newObj = { ...tagArray };

    for (var value in newObj) {
      if (newObj[value] === option) {
        delete newObj[value];
      }
    }
    setTagArray(newObj);
    handleFilter(newObj);
  };

  useEffect(() => {
    handleFilter(tagArray);
  }, [tagArray]);

  useEffect(() => {
    if (rulesetStatus === "Select") {
      setEventNameStatus("Select");
      setVenueStatus("Select");
      setStartDate("");
      setEndDate("");
      setSearch("");
      setTagArray({});
    }
  }, [rulesetStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.menuRow}>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Ruleset</label>
          <div className={styles.selectSpan}>
            <Select
              options={extendedRulesetOptions}
              placeholder={rulesetStatus}
              onChange={(option) => {
                setRulesetStatus(option);
                setButtonStatus(true);
                if (onRulesetChange) onRulesetChange(option);
              }}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Event Name</label>
          <div className={styles.selectSpan}>
            <Select
              className={styles.customSelect}
              options={filteredEventNameOptions}
              placeholder={eventNameStatus}
              onChange={(option) => {
                setEventNameStatus(option);
                setButtonStatus(true);
              }}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Location</label>
          <div className={styles.selectSpan}>
            <Select
              options={extendedVenueOptions}
              placeholder={venueStatus}
              onChange={(option) => {
                setVenueStatus(option);
                setButtonStatus(true);
              }}
            />
          </div>
        </div>

        <div className={styles.selectDiv}>
          <label className={classNames(styles.label)}>Date Range</label>
          <div className={styles.selectSpan}>
            <DateRangePickerButton
              key={dateKey}
              setStartDate={(e) => {
                setStartDate(e);
                if (startDate || endDate || e) {
                  setButtonStatus(true);
                }
              }}
              setEndDate={(e) => {
                setEndDate(e);
                if (startDate || endDate || e) {
                  setButtonStatus(true);
                }
              }}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={classNames(styles.label)}>Search</label>
          <div className={classNames(styles.searchBar, styles.selectSpan)}>
            <SearchBar
              key={searchKey}
              placeholderText="(Search by…)"
              onInput={(value) => {
                setSearch(value);
                search !== "" ||
                rulesetStatus !== "Select" ||
                venueStatus !== "Select" ||
                eventNameStatus !== "Select" ||
                startDate !== ""
                  ? setButtonStatus(true)
                  : setButtonStatus(false);
              }}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            className={styles[buttonStatus ? "btnEnabled" : "btnDisabled"]}
            children="FILTER"
            onClick={onFilter}
          />
        </div>
      </div>
      <div className={styles.tagRow}>
        {showTags &&
          Object.values(tagArray).map((tag, index) => (
            <div key={index} className={styles.tag}>
              <Tag
                title={tag.toString()}
                isBtnLight={true}
                onClick={(option) => onDelete(option)}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
