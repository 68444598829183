import { useState } from "react";
import { Button, ButtonPurpose, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import Table from "shared/Table";
import { Section } from "shared/layout";
import Loader from "shared/Loader";
import styles from "../index.module.css";
import { useSession } from "./useSession";

export default function Session({
  eventId,
  scanningSessions = [],
  refreshEventData,
}) {
  const {
    sessions,
    loading,
    setLoading,
    fetchUpdatedSessions,
    createSession,
    updateSession,
    removeSession,
  } = useSession(eventId, scanningSessions);

  const [editingId, setEditingId] = useState(null);
  const [tempAdd, setTempAdd] = useState(null);

  function onCancel() {
    setEditingId(null);
    setTempAdd(null);
  }

  function onAddClick() {
    setTempAdd({ id: null, description: "" });
  }

  async function onSave(session) {
    setLoading(true);

    if (session.id === null || session.id === undefined) {
      await createSession(session.description);
    } else {
      await updateSession(session.id, session.description);
    }

    await fetchUpdatedSessions();

    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }

    setEditingId(null);
    setTempAdd(null);
    setLoading(false);
  }

  async function handleRemove(id) {
    setLoading(true);

    await removeSession(id);
    await fetchUpdatedSessions();

    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }

    setLoading(false);
  }

  return (
    <Section className={styles.sectionTopMargin}>
      <header>
        <h3>Scanning Sessions</h3>
        <div>
          <Button size="small" onClick={onAddClick}>
            Add
          </Button>
        </div>
      </header>

      {loading ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Loader />
        </div>
      ) : (
        <Table>
          {(sessions.length > 0 || tempAdd) && (
            <thead>
              <tr>
                <th>Edit</th>
                <th>Description</th>
                <th>Total Scans</th>
                <th>Unique Scans</th>
                <th>&nbsp;</th>
                <th style={{ width: "120px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      size={ButtonSize.small}
                      onClick={fetchUpdatedSessions}
                      style={{ width: "100px" }}
                    >
                      Refresh
                    </Button>
                  </div>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {sessions.length > 0 || tempAdd ? (
              <>
                {sessions.map((s) =>
                  editingId !== s.id ? (
                    <tr key={s.id}>
                      <td width="25px">
                        <Button size="small" onClick={() => setEditingId(s.id)}>
                          Edit
                        </Button>
                      </td>
                      <td>{s.description}</td>
                      <td>{s.total ?? "0"}</td>
                      <td>{s.uniqueTotal ?? "0"}</td>
                      <td></td>
                      <td style={{ textAlign: "right", paddingLeft: "45px" }}>
                        {s.total === 0 && (
                          <Button
                            size={ButtonSize.small}
                            purpose={ButtonPurpose.delete}
                            onClick={() => handleRemove(s.id)}
                          >
                            Remove
                          </Button>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <SessionEdit
                      key={s.id}
                      session={s}
                      onCancel={onCancel}
                      onSave={onSave}
                    />
                  )
                )}
                {tempAdd && (
                  <SessionEdit
                    key="new-session"
                    session={tempAdd}
                    onCancel={onCancel}
                    onSave={onSave}
                  />
                )}
              </>
            ) : (
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  There are currently no scanning sessions.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Section>
  );
}

function SessionEdit({ session, onCancel, onSave }) {
  const [localDescription, setLocalDescription] = useState(
    session.description || ""
  );

  function handleChange(e) {
    setLocalDescription(e.target.value);
  }

  function handleSave() {
    onSave({ ...session, description: localDescription });
  }

  return (
    <tr key={session.id || `temp-${Math.random()}`}>
      <td width="25px">&nbsp;</td>
      <td className={styles.buttonsColumn} colSpan={5}>
        <TextInput
          placeholder="Description"
          value={localDescription}
          onChange={handleChange}
          autoFocus
        />
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.save}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.generic}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );
}
