import Card from "shared/Card";
import { TabContainer, Tab } from "shared/TabContainer";
import styles from "./index.module.css";
import Security from "./Security/Security";
import Session from "./Session/Session";
import Location from "./Location/Location";
import EventInfo from "./EventInfo/EventInfo";
import History from "./History/History";
import Setup from "./Setup/Setup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Loader from "shared/Loader";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default function Details({ eventInfo, refreshEventData, isLoading }) {
  if (isLoading || !eventInfo) {
    return (
      <div style={{ textAlign: "center", padding: "40px" }}>
        <Loader />
      </div>
    );
  }

  const {
    eventId,
    displayName,
    city,
    strAbbr,
    eventStartDate,
    timeZone,
    timeZones = [],
    company = {},
    scanningGuid,
    scanningSessions,
    scanningLocations,
    scanningSecurities,
    scanningHistory,
  } = eventInfo;

  const eventDates = eventInfo?.checkInDates || [];
  const checkInStartsOn = eventDates.find((a) => a.dateTypeId === 22) || null;
  const checkInEndsOn = eventDates.find((a) => a.dateTypeId === 23) || null;

  // Convert friendly name (e.g., "Pacific Time (PT)") to valid IANA (e.g., "America/Los_Angeles")
  const validTimeZone =
    timeZones.find((tz) => tz.name === timeZone)?.convertAs ||
    "America/Los_Angeles";

  const timezoneMapping = {
    "America/Los_Angeles": "PT",
    "America/Boise": "MT",
    "America/Chicago": "CT",
    "America/New_York": "ET",
    "America/Anchorage": "AKT",
    "America/Adak": "HAT",
    "Pacific/Honolulu": "HT",
  };

  const timezoneAbbr = timezoneMapping[validTimeZone] || "PT";

  function formatDateTime(utcDate) {
    if (!utcDate) return "Invalid Date";
    const date = dayjs(utcDate);
    if (!date.isValid()) return "Invalid Date";
    return date.tz(validTimeZone).format(`MM/DD/YY hh:mm A [${timezoneAbbr}]`);
  }

  return (
    <div>
      <header className={styles.eventHeader}>
        <span className={styles.headerEventType}>{displayName}</span>
        <h1 style={{ marginBottom: "1rem" }}>
          {eventInfo.venue?.address?.city || "Unknown City"}
          {eventInfo.venue?.address?.state
            ? ` ${eventInfo.venue.address.state}`
            : ""}
          {" – "}
          {dayjs(checkInStartsOn?.startsOn)
            .tz(validTimeZone)
            .format("MMMM Do, YYYY")}
        </h1>
      </header>

      <Card>
        <EventInfo eventInfo={eventInfo} refreshEventData={refreshEventData} />
      </Card>

      <TabContainer>
        <Tab title="Setup" value="Setup">
          <Setup
            eventId={eventId}
            scanningGuid={scanningGuid}
            checkInStartsOn={checkInStartsOn}
            checkInEndsOn={checkInEndsOn}
            formatDateTime={formatDateTime}
            timezone={validTimeZone}
          />
          <Session
            eventId={eventId}
            scanningSessions={scanningSessions}
            formatDateTime={formatDateTime}
            refreshEventData={refreshEventData}
          />
          <Location
            eventId={eventId}
            scanningLocations={scanningLocations}
            refreshEventData={refreshEventData}
          />
        </Tab>
        <Tab title="Security" value="security">
          <Security
            eventId={eventId}
            scanningSecurities={scanningSecurities}
            refreshEventData={refreshEventData}
          />
        </Tab>
        <Tab title="History" value="history">
          <History
            eventId={eventId}
            scanningHistory={scanningHistory}
            timeZone={validTimeZone}
            refreshEventData={refreshEventData}
          />
        </Tab>
      </TabContainer>
    </div>
  );
}
