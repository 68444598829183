import { useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import env from "shared/regionConfig";

const API_BASE_URL = env.apiBase || null;

export function useSecurity(eventId, scanningSecurities = []) {
  const { makeAuthedRequest } = Auth.useContainer();
  const [securities, setSecurities] = useState([]);

  useEffect(() => {
    setSecurities(
      scanningSecurities.map((s) => ({
        id: s.Id,
        firstName: s.FirstName,
        lastName: s.LastName,
        phone:
          s.AreaCode && s.Prefix && s.Suffix
            ? `${s.AreaCode}.${s.Prefix}.${s.Suffix}`
            : "",
      }))
    );
  }, [scanningSecurities]);

  async function fetchUpdatedSecurities() {
    try {
      const getResponse = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/scanner/event/id/${eventId}`,
        {
          method: "GET",
          headers: { Accept: "application/json" },
        }
      );

      if (getResponse.ok) {
        const updatedEvent = await getResponse.json();
        setSecurities(
          updatedEvent.scanningSecurities.map((s) => ({
            id: s.Id,
            firstName: s.FirstName,
            lastName: s.LastName,
            phone:
              s.AreaCode && s.Prefix && s.Suffix
                ? `${s.AreaCode}.${s.Prefix}.${s.Suffix}`
                : "",
          }))
        );
      }
    } catch (error) {}
  }

  async function onRemove(securityId) {
    if (!securityId) return;
    try {
      const response = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/security/${securityId}`,
        {
          method: "DELETE",
          headers: { Accept: "application/json" },
        }
      );

      if (response.ok) {
        fetchUpdatedSecurities();
      }
    } catch (error) {}
  }

  async function createSecurity(firstName, lastName, phone) {
    if (!firstName || !lastName) return;

    const cleanPhone = phone.replace(/\D/g, "");
    const eventData = {
      eventId,
      firstName,
      lastName,
      areaCode: cleanPhone.substring(0, 3),
      prefix: cleanPhone.substring(3, 6),
      suffix: cleanPhone.substring(6),
    };

    try {
      const response = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/security`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventData),
        }
      );

      if (response.ok) {
        fetchUpdatedSecurities();
      }
    } catch (error) {}
  }

  async function updateSecurity(securityId, firstName, lastName, phone) {
    if (!securityId || !firstName || !lastName) return;

    const cleanPhone = phone.replace(/\D/g, "");
    const eventData = {
      id: securityId,
      eventId,
      firstName,
      lastName,
      areaCode: cleanPhone.substring(0, 3),
      prefix: cleanPhone.substring(3, 6),
      suffix: cleanPhone.substring(6),
    };

    try {
      const response = await makeAuthedRequest(
        `${API_BASE_URL}/v1/events/security`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventData),
        }
      );

      if (response.ok) {
        fetchUpdatedSecurities();
      }
    } catch (error) {}
  }

  return {
    securities,
    fetchUpdatedSecurities,
    onRemove,
    createSecurity,
    updateSecurity,
  };
}
