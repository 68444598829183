import PropTypes from "prop-types";
import styles from "./SpecialNeedsModalNew.module.css";
import { Button } from "@wwg/ui";
import { useState, useEffect } from "react";
import options from "./options";
import Loader from "shared/Loader";
import { useQueryClient } from "react-query";
import React from "react";

/**
 * Interactive component that allows the user to configure ticket options notifying WWG of any accommodations they may need when attending an event.
 */
export const SpecialNeedsModalNew = ({
  ticket,
  onSave,
  onCancel,
  message,
  messageType,
  adaDescription,
  adaOptions,
  clearLoader = true,
}) => {
  const [selectedTickets, setSelectedTickets] = useState(
    ticket.adaValues || []
  );

  useEffect(() => {
    if (ticket.adaValues) {
      setSelectedTickets(ticket.adaValues);
    }
  }, [ticket]);

  const [showLoader, setShowLoader] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [changesMade, setChangesMade] = useState(false); // Track changes made to checkbox states

  useEffect(() => {
    if (message != null && message !== "" && clearLoader) {
      setShowLoader(true);
      setShowButtons(false);
      setTimeout(() => {
        setShowLoader(false);
        setShowMessage(true);
        setShowCloseButton(true);
      }, 2000);
    }
  }, [message, clearLoader]);


  const handleCheckboxClick = (option, selectedParam) => {
    setSelectedTickets((prevSelected) => {
      let updatedSelected = [...prevSelected];

      const existingIndex = updatedSelected.findIndex(
        (opt) =>
          opt.eventAdaID === option.eventAdaID &&
          opt.parameter1 === selectedParam
      );

      if (existingIndex !== -1) {
        updatedSelected.splice(existingIndex, 1);
      } else {
        updatedSelected = updatedSelected.filter(
          (opt) => opt.eventAdaID !== option.eventAdaID
        );

        updatedSelected.push({
          eventAdaID: option.eventAdaID,
          component: option.component,
          parameter1: selectedParam, // Always store selectedParam in parameter1
        });
      }

      return updatedSelected;
    });
  };

  const handleSave = () => {
    setChangesMade(false);
    setShowLoader(true);
    setShowButtons(false);
    onSave({ ...ticket, adaValues: selectedTickets });
  };

  const handleCancel = () => {
    setShowButtons(false);
    onCancel();
  };
  let messageClass = `${styles.message}`;
  if (messageType === "success") {
    messageClass = `${styles.successMessage}`;
  } else if (messageType === "error") {
    messageClass = `${styles.errorMessage}`;
  }
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.specialNeedsModal}>
        <span className={styles.specialNeedsTitle}>Special Needs</span>
        <p className={styles.specialNeedsDesc}>{adaDescription} </p>
        {showLoader ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <form className={styles.specialNeedsOptions}>
            {adaOptions?.map((option, index) => {
              const isSelected = (param) => {
                return selectedTickets?.some((opt) => opt.parameter1 == param);
              };

              return (
                <React.Fragment key={`${option.eventAdaID}-${index}`}>
                  <input
                    type="checkbox"
                    id={option.parameter1}
                    checked={isSelected(option.parameter1)}
                    onChange={() =>
                      handleCheckboxClick(option, option.parameter1)
                    }
                  />
                  <label htmlFor={option.parameter1}>{option.parameter1}</label>

                  {option.parameter2 && (
                    <>
                      <span className={styles.specialNeedsOr}>or</span>
                      <input
                        type="checkbox"
                        id={option.parameter2}
                        checked={isSelected(option.parameter2, 2)}
                        onChange={() =>
                          handleCheckboxClick(option, option.parameter2)
                        }
                      />
                      <label htmlFor={option.parameter2}>
                        {option.parameter2}
                      </label>
                    </>
                  )}

                  {index !== adaOptions.length - 1 && <hr />}
                </React.Fragment>
              );
            })}
          </form>
        )}

        {message && showMessage && !showLoader && !changesMade && (
          <div className={messageClass}>{message}</div>
        )}
        {/* {showCloseButton && (
            <div className={styles.containerCloseButton}>
              <Button
                label="Close"
                className={styles.closeButton}
                onClick={handleClose}
              />
            </div>
          )} */}

        {/* Buttons */}
        <div className={styles.containerSpecialNeedsButtons}>
          {!showLoader && (
            <Button
              label="Save"
              className={styles.onSaveButton}
              onClick={handleSave}
            />
          )}
          <Button
            label={
              (showCloseButton || showLoader) && !changesMade
                ? "Close"
                : "Cancel"
            }
            className={styles.onCancelButton}
            buttonStyle="hollow"
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

SpecialNeedsModalNew.propTypes = {
  ticket: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
