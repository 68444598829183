import { useState, useEffect, useRef } from "react";
import { Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import Table from "shared/Table";
import styles from "../index.module.css";
import { Event } from "Events/Components/useEvent";
import { UseScannerRefreshAll } from "./UseScannerRefreshAll";

export default function HistoryEdit({
  eventId,
  scanningHistory = [],
  setScanningHistory: externalSetScanningHistory,
  timeZone,
}) {
  const { saveHistory, deleteHistory } = Event.useContainer();
  const [syncingRow, setSyncingRow] = useState(null);
  const [refreshingRows, setRefreshingRows] = useState([]);
  const [refreshingAll, setRefreshingAll] = useState(false);
  const [scanningHistoryState, setScanningHistoryState] = useState(
    Array.isArray(scanningHistory) ? scanningHistory : []
  );
  const [manuallySyncedRows, setManuallySyncedRows] = useState({});
  const syncTimersRef = useRef({});

  const {
    refreshScannerById,
    refreshScannerByEvent,
    syncScanner,
    loadingScanId,
    loadingEventId,
  } = UseScannerRefreshAll();

  const updateScanningHistory =
    typeof externalSetScanningHistory === "function"
      ? externalSetScanningHistory
      : setScanningHistoryState;

  useEffect(() => {
    setScanningHistoryState(
      Array.isArray(scanningHistory) ? scanningHistory : []
    );
    resetSyncState(); // Reset on new props
  }, [scanningHistory]);

  useEffect(() => {
    return () => {
      // Clean up timers on unmount
      Object.values(syncTimersRef.current).forEach(clearTimeout);
    };
  }, []);

  function resetSyncState() {
    setManuallySyncedRows({});
    Object.values(syncTimersRef.current).forEach(clearTimeout);
    syncTimersRef.current = {};
  }

  function updateHistory(scanId, updatedData) {
    updateScanningHistory((prevHistory) => {
      if (!Array.isArray(prevHistory)) return [];
      return prevHistory.map((item) =>
        item.id === scanId ? { ...item, ...updatedData } : item
      );
    });
  }

  function onRefresh(scanId) {
    if (!scanId || !eventId) return;

    setRefreshingRows((prev) => [...prev, scanId]);
    refreshScannerById(scanId, updateHistory, eventId)
      .then(() => {
        setRefreshingRows((prev) => prev.filter((rowId) => rowId !== scanId));
        setManuallySyncedRows((prev) => {
          const { [scanId]: _, ...rest } = prev;
          return rest;
        });
      })
      .catch(() => {
        setRefreshingRows((prev) => prev.filter((rowId) => rowId !== scanId));
      });
  }

  function onRefreshAll() {
    if (!eventId) return;

    setRefreshingAll(true);

    refreshScannerByEvent(
      eventId,
      (correctEventId, updatedData) => {
        if (updatedData && Array.isArray(updatedData.scanningHistory)) {
          setScanningHistoryState(updatedData.scanningHistory);
        } else {
          console.warn("No valid scanning history found in API response.");
          setScanningHistoryState([]);
        }

        resetSyncState();
        setRefreshingAll(false);
      },
      eventId
    ).catch(() => setRefreshingAll(false));
  }

  function onSync(id) {
    if (!id) return;
    setSyncingRow(id);

    // Clear existing timeout for this scan
    if (syncTimersRef.current[id]) {
      clearTimeout(syncTimersRef.current[id]);
    }

    // Mark it as synced
    setManuallySyncedRows((prev) => ({
      ...prev,
      [id]: Date.now(),
    }));

    // syncTimersRef.current[id] = setTimeout(() => {
    //   onRefresh(id);
    // }, 30 * 1000); // 15 seconds

    syncTimersRef.current[id] = setTimeout(() => {
      onRefresh(id);
    }, 10 * 60 * 1000); // 10 minutes

    syncScanner(id).finally(() => {
      setSyncingRow(null);
    });
  }

  return (
    <Section className={styles.sectionTopMargin}>
      <header>
        <h3>Scanning History</h3>
      </header>

      <Table>
        <thead>
          <tr>
            <th>Device</th>
            <th>Status</th>
            <th>Scans</th>
            <th>Synced</th>
            <th>
              <Button
                size={ButtonSize.small}
                onClick={onRefreshAll}
                disabled={refreshingAll || loadingEventId === eventId}
                style={{ width: "100px" }}
              >
                {refreshingAll ? "Refreshing..." : "Refresh All"}
              </Button>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {scanningHistoryState.length > 0 ? (
            scanningHistoryState
              .slice()
              .sort((a, b) => {
                if (a.completedOn && !b.completedOn) return 1;
                if (!a.completedOn && b.completedOn) return -1;
                return new Date(b.startedOn) - new Date(a.startedOn);
              })
              .map((a) => {
                const now = Date.now();
                const startedTime = new Date(a.startedOn).getTime();

                const syncClickedTimestamp = manuallySyncedRows[a.id];
                const hasSyncedRecently =
                  syncClickedTimestamp &&
                  syncClickedTimestamp > now - 10 * 60 * 1000;
                const syncWindowIsReady = startedTime + 10 * 60 * 1000 < now;

                const shouldShowSync =
                  !a.completedOn && syncWindowIsReady && !hasSyncedRecently;

                return (
                  <tr
                    key={a.id || a.startedOn}
                    className={a.completedOn ? styles.completedRow : ""}
                  >
                    <td className={styles.deviceColumn}>
                      <strong>{a.device?.split("\n")[0]}</strong>
                      <br />
                      <span className={styles.deviceId}>
                        {a.device?.includes("\n")
                          ? a.device.split("\n")[1]
                          : "Unknown"}
                      </span>
                    </td>
                    <td className={styles.statusColumn}>
                      <div>
                        <strong>
                          {a.completedOn ? "Completed On: " : "Started On: "}
                        </strong>
                        {formatDate(a.completedOn || a.startedOn, timeZone)}
                      </div>
                    </td>
                    <td className={styles.scansColumn}>{a.scans || 0}</td>
                    <td className={styles.syncedColumn}>{a.synced || 0}</td>
                    <td className={styles.refreshColumn}>
                      {!a.completedOn ? (
                        <Button
                          size={ButtonSize.small}
                          onClick={() => onRefresh(a.id)}
                          disabled={
                            loadingScanId === a.id ||
                            refreshingRows.includes(a.id)
                          }
                          style={{ width: "100px" }}
                        >
                          {loadingScanId === a.id ||
                          refreshingRows.includes(a.id)
                            ? "Refreshing..."
                            : "Refresh"}
                        </Button>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                    </td>
                    <td className={styles.syncColumn}>
                      {shouldShowSync && a.scans !== a.synced ? (
                        <Button
                          size={ButtonSize.small}
                          onClick={() => onSync(a.id)}
                          disabled={syncingRow === a.id}
                          purpose={ButtonPurpose.save}
                          style={{ width: "100px" }}
                        >
                          {syncingRow === a.id ? "Syncing..." : "Sync"}
                        </Button>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                No scanning history available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Section>
  );
}

const formatDate = (dateString, timeZone) => {
  if (!dateString) return "Invalid Date";
  const validTimeZones = {
    "Pacific Time (PT)": "America/Los_Angeles",
    "Mountain Time (MT)": "America/Denver",
    "Central Time (CT)": "America/Chicago",
    "Eastern Time (ET)": "America/New_York",
    "Alaska Time (AKT)": "America/Anchorage",
    "Hawaii-Aleutian Time (HAT)": "America/Adak",
    "Hawaii-Honolulu (HST)": "Pacific/Honolulu",
  };
  const validTimeZone = validTimeZones[timeZone] || "UTC";
  try {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: validTimeZone,
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    }).format(new Date(dateString));
  } catch (error) {
    return "Invalid Date";
  }
};
