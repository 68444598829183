import { useEffect, useState } from "react";
import { useAuthedRequest } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import env from "../../shared/regionConfig";


export function useEventSearch(term = "", isScannerSearch = false, limit = 50) {
  const { logError } = useLogger({ prefix: "useEventSearch" });
  const [searchResults, setSearchResults] = useState([]);
  const [loadingScannerSearch, setLoadingScannerSearch] = useState(false);
  const [cleanedTerm, setCleanedTerm] = useState("");

  const API_BASE_URL = env.apiBase;

  const baseUrl = isScannerSearch
    ? `${API_BASE_URL}/v1/events/scanner/events/search`
    : `${API_BASE_URL}/v1/events/search`;

  useEffect(() => {
    setCleanedTerm(
      term
        .trim()
        .replace(/\s*:\s*/g, ":") // clean up spacing around colons
        .replace(/\s+/g, "+") // replace spaces with +
    );
  }, [term]);

  const shouldFetchData = cleanedTerm.length >= 3;
  const url = shouldFetchData
    ? `${baseUrl}?term=${cleanedTerm}&limit=${limit}`
    : null;

  const { data, loading, error } = useAuthedRequest(url);

  useEffect(() => {
    if (shouldFetchData) {
      setLoadingScannerSearch(true);
    }
  }, [shouldFetchData]);

  useEffect(() => {
    if (data) {
      let filtered = data;
      const lowerTerm = term.toLowerCase();

      const typeMap = {
        fr: "family reunion",
        sl: "spring leadership",
        fed: "free enterprise",
      };

      if (lowerTerm.startsWith("location:")) {
        const city = lowerTerm.replace("location:", "").trim();
        filtered = data.filter((item) =>
          item.display.toLowerCase().includes(city)
        );
      } else if (lowerTerm.startsWith("state:")) {
        const state = lowerTerm.replace("state:", "").trim();
        filtered = data.filter((item) =>
          item.display.toLowerCase().includes(state)
        );
      } else if (lowerTerm.startsWith("year:")) {
        const year = lowerTerm.replace("year:", "").trim();
        filtered = data.filter((item) =>
          item.display.toLowerCase().includes(year)
        );
      } else if (lowerTerm.startsWith("type:")) {
        let type = lowerTerm.replace("type:", "").trim();
        type = typeMap[type] || type; // use mapped value if exists
        filtered = data.filter((item) =>
          item.display.toLowerCase().includes(type)
        );
      }

      setSearchResults(filtered);
      setLoadingScannerSearch(false);
    }
  }, [data, term]);

  useEffect(() => {
    if (error) {
      logError(error);
      setLoadingScannerSearch(false);
    }
  }, [error]);

  return {
    searchResults,
    isLoading: loading,
    loadingScannerSearch,
  };
}
