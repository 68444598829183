import Card from "shared/Card";
import { Column, LabelGroup, Row, Section } from "shared/layout";
import Button, { ButtonPurpose } from "shared/Button";
import { ZonePicker } from "shared/@forms/DateTimePicker/ZonePicker/ZonePicker";
import { useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import styles from "../index.module.css";
import moment from "moment-timezone";
import { useEventInfo } from "./useEventInfo";

export default function EventInfo({ eventInfo, fetchEvent }) {
  const { makeAuthedRequest } = Auth.useContainer();
  const {
    eventId,
    title,
    timeZone: initialTimeZone,
    venue,
    timeZones = [],
  } = eventInfo;

  const { name: venueName, address } = venue;

  const [isEditing, setIsEditing] = useState(false);
  const [timezone, setTimezone] = useState(initialTimeZone);
  const [saving, setSaving] = useState(false);
  const { updateEventTimezone } = useEventInfo();

  useEffect(() => {
    setTimezone(initialTimeZone);
  }, [initialTimeZone]);

  const selectedZone =
    timeZones.find(
      (zone) => zone.name === timezone || zone.convertAs === timezone
    ) || {};

  const displayName = selectedZone.name || timezone;
  const ianaTimezone = selectedZone.convertAs || timezone;
  const abbreviation = moment().tz(ianaTimezone).zoneAbbr();

  const selectedLabel = selectedZone.name || timezone;

  async function onSave() {
    setSaving(true);

    const success = await updateEventTimezone({
      eventId,
      timeZone: timezone,
    });

    if (success) {
      setIsEditing(false);
      if (typeof fetchEvent === "function") {
        await fetchEvent();
      }
    }

    setSaving(false);
  }

  function onCancel() {
    setTimezone(initialTimeZone);
    setIsEditing(false);
  }

  return (
    <Section>
      <header>
        <h3>Event Info</h3>
        <div className={styles.buttonsColumn}>
          {!isEditing && (
            <Button size="small" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button
                size="small"
                purpose={ButtonPurpose.save}
                onClick={onSave}
                disabled={saving}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
              <Button size="small" onClick={onCancel} disabled={saving}>
                Cancel
              </Button>
            </>
          )}
        </div>
      </header>
      <Row>
        <Column>
          <LabelGroup title="Event Type">{title}</LabelGroup>
        </Column>
        <Column>
          <Row>
            <LabelGroup title="Location">
              {venueName ? `${venueName}, ${address.city}` : "Unknown Location"}
            </LabelGroup>
          </Row>
          <Row>
            <LabelGroup title="Timezone">
              {!isEditing ? (
                selectedLabel
              ) : (
                <ZonePicker
                  value={timezone}
                  onChange={(value) => setTimezone(value)}
                />
              )}
            </LabelGroup>
          </Row>
        </Column>
        <Column>
          <LabelGroup title="Venue">
            {venueName} <br />
            {address && (
              <>
                {address.address1 && (
                  <>
                    <span>{address.address1}</span>
                    <br />
                  </>
                )}
                {address.address2 && (
                  <>
                    <span>{address.address2}</span>
                    <br />
                  </>
                )}
                {address.city}, {address.state} {address.postalCode}
              </>
            )}
          </LabelGroup>
        </Column>
      </Row>
    </Section>
  );
}
