import { useState } from "react";
import { Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import Table from "shared/Table";
import Loader from "shared/Loader";
import styles from "../index.module.css";
import { useLocation } from "./useLocation";

export default function Location({
  eventId,
  scanningLocations = [],
  refreshEventData,
}) {
  const {
    locations,
    loading,
    setLoading,
    fetchUpdatedLocations,
    createLocation,
    updateLocation,
    removeLocation,
  } = useLocation(eventId, scanningLocations);

  const [editingId, setEditingId] = useState(null);
  const [tempAdd, setTempAdd] = useState(null);

  function onCancel() {
    setEditingId(null);
    setTempAdd(null);
  }

  function onAddClick() {
    setTempAdd({ id: null, description: "" });
  }

  async function onSave(location) {
    setLoading(true);

    if (location.id === null || location.id === undefined) {
      await createLocation(location.description);
    } else {
      await updateLocation(location.id, location.description);
    }

    await fetchUpdatedLocations();

    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }

    setEditingId(null);
    setTempAdd(null);
    setLoading(false);
  }

  async function handleRemove(id) {
    setLoading(true);

    await removeLocation(id);
    await fetchUpdatedLocations();

    if (typeof refreshEventData === "function") {
      await refreshEventData();
    }

    setLoading(false);
  }

  return (
    <Section className={styles.sectionTopMargin}>
      <header>
        <h3>Scanning Locations</h3>
        <div>
          <Button size="small" onClick={onAddClick}>
            Add
          </Button>
        </div>
      </header>
      {loading ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Loader />
        </div>
      ) : (
        <Table>
          {locations.length > 0 && (
            <thead>
              <tr>
                <th>Edit</th>
                <th>Description</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
          )}
          <tbody>
            {locations.length > 0 || tempAdd ? (
              <>
                {locations.map((l) =>
                  editingId !== l.id ? (
                    <tr key={l.id}>
                      <td width="25px">
                        <Button size="small" onClick={() => setEditingId(l.id)}>
                          Edit
                        </Button>
                      </td>
                      <td>{l.description}</td>
                      <td className={styles.buttonsColumn}>
                        <Button
                          size={ButtonSize.small}
                          purpose={ButtonPurpose.delete}
                          onClick={() => handleRemove(l.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    <LocationEdit
                      key={l.id}
                      location={l}
                      onCancel={onCancel}
                      onSave={onSave}
                    />
                  )
                )}
                {tempAdd && (
                  <LocationEdit
                    key="new-location"
                    location={tempAdd}
                    onCancel={onCancel}
                    onSave={onSave}
                  />
                )}
              </>
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: "center", padding: "20px" }}>
                  There are currently no scanning locations.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Section>
  );
}

function LocationEdit({ location, onCancel, onSave }) {
  const [localDescription, setLocalDescription] = useState(
    location.description || ""
  );

  function handleChange(e) {
    setLocalDescription(e.target.value);
  }

  function handleSave() {
    onSave({ ...location, description: localDescription });
  }

  return (
    <tr key={location.id || `temp-${Math.random()}`}>
      <td width="25px"></td>
      <td className={styles.buttonsColumn} colSpan={2}>
        <TextInput
          placeholder="Description"
          value={localDescription}
          onChange={handleChange}
          autoFocus
        />
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.save}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.generic}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );
}
