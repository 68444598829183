import React, { useState, useEffect } from "react";
import SuggestionInput from "shared/@forms/SuggestionInput";
import Link from "shared/Link";
import NoSelection from "./NoSelection";
import { useEventSearch } from "../useEventSearch";
import Details from "../ScannerDetails";
import styles from "./index.module.css";
import { useRouteMatch } from "react-router-dom";
import { Event } from "Events/Components/useEvent";
import LoadingPage from "shared/LoadingPage";

export default function () {
  const { params } = useRouteMatch();
  const { eventId } = params;

  return (
    <Event.Provider initialState={eventId}>
      <LookupByEvent eventId={eventId} />
    </Event.Provider>
  );
}

function LookupByEvent({ eventId }) {
  const { eventData, isLoading, isError, fetchEvent } = Event.useContainer();
  const [searchTerm, setSearchTerm] = useState("");
  const [editTerm, setEditTerm] = useState("");
  const [eventInfo, setEventInfo] = useState(null);

  const { searchResults, isLoading: searchLoading } = useEventSearch(
    searchTerm,
    true
  );

  useEffect(() => {
    if (eventId && fetchEvent) {
      fetchEvent().then((data) => {
        setEventInfo(data);
      });
    }
  }, [eventId, fetchEvent]);

  async function refreshEventData() {
    if (fetchEvent) {
      const updated = await fetchEvent();
      setEventInfo(updated);
    }
  }

  return (
    <div>
      <SuggestionInput
        value={editTerm}
        autoFocus
        minLength={3}
        debounceTime={500}
        fetchSuggestions={setSearchTerm}
        suggestions={searchResults}
        isLoading={searchLoading}
        placeholder="Search Events"
        onChange={(e) => setEditTerm(e.target.value)}
      >
        {(item, closePanel) => (
          <div key={item.eventId} className={styles.eventListItem}>
            <div>
              <span>Event:</span>{" "}
              <Link
                to={`/events/scanner/${item.eventId}`}
                onClick={() => closePanel()}
              >
                <span>{item.display}</span>
              </Link>
            </div>
            <Link
              to={`/events/scanner/${item.eventId}`}
              onClick={() => closePanel()}
            >
              <span>View</span>
            </Link>
          </div>
        )}
      </SuggestionInput>

      <div className={styles.details}>
        <DetailsErrorBoundary>
          {eventId ? (
            <Details
              eventInfo={eventInfo}
              isLoading={isLoading}
              refreshEventData={refreshEventData}
            />
          ) : (
            <div className={styles.noSelection}>
              <NoSelection />
            </div>
          )}
        </DetailsErrorBoundary>
      </div>
    </div>
  );
}

class DetailsErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return <h1>An error occurred when looking up this event.</h1>;
    }
    return this.props.children;
  }
}
