import { Auth } from "shared/@auth/Auth";
import { useMutation, useQuery } from "react-query";

export function useSelectUserModal(iboNumber, eventId) {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, isLoading, refetch } = useQuery(
    ["orderAllTickets", iboNumber, eventId],
    () =>
      makeAuthedRequest(
        `v1/events/order/tickets/ibo/${iboNumber}/eventId/${eventId}`,
        {
          method: "GET",
        }
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  const updateTicketUser = useMutation(async (tickets) => {
    const res = await makeAuthedRequest(
      `v1/events/order/tickets/ibo/${iboNumber}/eventId/${eventId}`,
      {
        method: "PUT",
        body: JSON.stringify(tickets),
      }
    );

    if (res.ok) {
      return res;
    } else {
      const body = await res.json();
      return body.error?.message.message;
    }
  });

  return {
    data,
    isLoading,
    refetch,
    updateTicketUser,
  };
}
