import { Auth } from "shared/@auth/Auth";
import env from "shared/regionConfig";

const API_BASE_URL = env.apiBase || null;
const PUT_URL = "/v1/events/timezone";

export function useEventInfo() {
  const { makeAuthedRequest } = Auth.useContainer();

  async function updateEventTimezone({ eventId, timeZone }) {
    const payload = {
      eventId,
      timeZone,
    };

    try {
      const response = await makeAuthedRequest(`${API_BASE_URL}${PUT_URL}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to update timezone: ${response.statusText}`);
      }

      return true;
    } catch (error) {
      console.error("Error updating timezone:", error);
      return false;
    }
  }

  return { updateEventTimezone };
}
