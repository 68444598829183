import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import TicketGroup from "Events/Components/TicketGroup";
import AddButton from "Events/Components/AddButton";
import { Modal } from "shared/Modal";
import Input from "shared/@forms/Input";
import Button from "shared/Button";
import styles from "./TicketingV2Form.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment-timezone";
import Loader from "shared/Loader";

/**Area to fill out ticketing information  */
export function TicketingV2Form({
  ticketSets,
  setTicketSets,
  homesitesGroups,
  clearSessionsTicketSet,
  pageMode,
  isLocal,
  isSummerConference,
  isPublished,
  isVirtual,
}) {
  const [showAddTicketSetModal, setShowAddTicketSetModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [name, setName] = useState("");
  const handleAddTicketSet = () => {
    setTicketSets(
      [
        ...ticketSets,
        {
          ticketSet: { saved: name },
          ticketSetType: { saved: "sub" },
          badges: { saved: [] },
          sort: { saved: ticketSets.length },
          tickets: [
            {
              commonItemId: { saved: null },
              name: { saved: name },
              price: { saved: null },
              creditsRequired: { saved: false },
              limitedToGroups: { saved: [] },
              acceptedCreditTypes: { saved: [] },
              purchaseStartDate: { saved: null },
              purchaseEndDate: { saved: null },
              virtualCommonItemId: { saved: null },
            },
          ],
        },
      ],
      true
    );
    setShowAddTicketSetModal(false);
  };
  const handleSetTicketSet = (updatedTicketSet) => {
    const updatedTicketSets = ticketSets.map((ticketSet) =>
      ticketSet.id === updatedTicketSet.id ? updatedTicketSet : ticketSet
    );
    setIsRemoving(false);
    if (!isRemoving) {
      setTicketSets(updatedTicketSets);
    } else {
      setIsRemoving(false);
    }
  };
  const handleRemoveTicketSet = async (setToRemove) => {
    setIsRemoving(true);
    const updatedTicketSets = ticketSets.filter(
      (ticketSet) => ticketSet.id !== setToRemove.id
    );
    setTicketSets(updatedTicketSets, false, setToRemove.ticketSet);
    // clearSessionsTicketSet(setToRemove.ticketSet);
  };
  const handleAddTicket = (ticketSetId, name, price, id) => {
    const updatedTicketSets = ticketSets.map((tktSet) =>
      tktSet.id === ticketSetId
        ? {
            ...tktSet,
            tickets: [
              ...tktSet.tickets,
              {
                commonItemId: null,
                cancelFeeItemId: null,
                name: null,
                price: null,
                creditsRequired: false,
                limitedToGroups: [],
                acceptedCreditTypes: [],
                purchaseStartDate: null,
                purchaseEndDate: null,
                virtualCommonItemId: null,
              },
            ],
          }
        : tktSet
    );
    setTicketSets(updatedTicketSets, true);
  };

  const renderViewBadges = (ticketSet) => {
    const badges = ticketSet.badges.saved?.length
      ? ticketSet.badges.saved
      : ticketSet.badges.value || [];

    if (!badges.length) return "N/A";

    return badges.map((badge) => badge.name || badge.label).join(", ");
  };

  const renderViewAcceptedCreditTypes = (ticket) => {
    if (!ticket.acceptedCreditTypes) return "N/A";

    const creditTypes = ticket.acceptedCreditTypes.saved?.length
      ? ticket.acceptedCreditTypes.saved
      : ticket.acceptedCreditTypes.value || [];

    return creditTypes.length > 0
      ? creditTypes.map((credit, index) => (
          <span key={index} className={styles.creditTypesItem}>
            {credit.creditTypeId}
          </span>
        ))
      : "N/A";
  };

  const renderViewCreditsRequired = (ticket) => {
    var isCreditRequired = "No";

    if (ticket.creditsRequired.saved || ticket.creditsRequired.value) {
      isCreditRequired = "Yes";
      return isCreditRequired;
    } else return isCreditRequired;
  };

  const renderViewGroups = (ticket) => {
    if (!ticket.limitedToGroups) return "N/A";

    const groups = ticket.limitedToGroups.saved?.length
      ? ticket.limitedToGroups.saved
      : ticket.limitedToGroups.value || [];

    return groups.length > 0
      ? groups.map((group, index) => (
          <span key={index} className={styles.groupItem}>
            {group.name}
          </span>
        ))
      : "N/A";
  };

  const renderViewDates = (ticket) => {
    const dateFields = [
      {
        key: "purchaseStartDate",
        label: "Purchase Start Date",
        timeZoneKey: "purchaseStartTimeZone",
      },
      { key: "purchaseEndDate", label: "Purchase End Date", timeZoneKey: null }, // No timezone for this one
      {
        key: "startFeeDate",
        label: "Start Fee Date",
        timeZoneKey: "startFeeTimeZone",
      },
      {
        key: "changeableUntilDate",
        label: "Changeable Until Date",
        timeZoneKey: "changeableUntilTimeZone",
      },
    ];

    return dateFields
      .map(({ key, label, timeZoneKey }) => {
        const dateValue = ticket[key]?.saved || ticket[key]?.value;
        const timeZone = timeZoneKey
          ? ticket[timeZoneKey]?.saved || ticket[timeZoneKey]?.value
          : "";

        if (!dateValue) return null; // Ignore empty dates

        // Convert using dayjs for consistent formatting
        const formattedDate = moment(dateValue).format("MM/DD/YYYY hh:mm A");

        return (
          <span key={key} className={styles.dateItem}>
            <b>{label}:</b> {formattedDate} {timeZone}
          </span>
        );
      })
      .filter(Boolean); // Remove null values
  };

  const handleOrderChange = (sourceIndex, destinationIndex) => {
    // console.log(sourceIndex, destinationIndex);
    if (sourceIndex === destinationIndex || destinationIndex == null) return;

    const ticketSetsClone = [...ticketSets];

    const filteredSets = ticketSetsClone
      .filter((ticketSet) => ticketSet.ticketSetType.saved === "sub")
      .sort((a, b) => a.sort.saved - b.sort.saved);

    const draggedItem = filteredSets[sourceIndex];
    filteredSets.splice(sourceIndex, 1);
    filteredSets.splice(destinationIndex, 0, draggedItem);

    const updatedTicketSets = ticketSetsClone.map((ticketSet) => {
      const indexInFiltered = filteredSets.findIndex(
        (fSet) => fSet.id === ticketSet.id
      );

      return indexInFiltered !== -1
        ? {
            ...ticketSet,
            sort: {
              ...ticketSet.sort,
              saved: indexInFiltered + 1,
            },
          }
        : ticketSet;
    });

    // updatedTicketSets.forEach((set) => console.log(set.ticketSet, set.sort));
    setTicketSets(updatedTicketSets, false);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    handleOrderChange(source.index, destination.index);
  };

  if (pageMode === "view") {
    return (
      <Card>
        <section>
          <div className={styles.bold}>TICKETING</div>
          {ticketSets.map((ticketSet, index) => (
            <div key={index} className={styles.section}>
              <hr className={styles.hr} />
              <div className={styles.ticketGroupContainer}>
                <div className={styles.v2Header}>
                  <span className={styles.viewLabel}>
                    {ticketSet.ticketSet?.saved ||
                      ticketSet.ticketSet?.value ||
                      "N/A"}{" "}
                    Ticket Set
                  </span>
                </div>

                <div className={styles.viewContainer}>
                  <div className={styles.row}>
                    <span className={styles.viewLabel}>Title</span>
                    <span className={styles.viewValue}>
                      {ticketSet.title?.saved ||
                        ticketSet.title?.value ||
                        "N/A"}
                    </span>
                  </div>

                  <div className={styles.row}>
                    <span className={styles.viewLabel}>Sub-Title</span>
                    <span className={styles.viewValue}>
                      {ticketSet.subTitle?.saved ||
                        ticketSet.subTitle?.value ||
                        "N/A"}
                    </span>
                  </div>

                  <div className={styles.row}>
                    <span className={styles.viewLabel}>Icon</span>
                    <span className={styles.viewValue}>
                      {ticketSet.icon?.saved || ticketSet.icon?.value ? (
                        <img
                          className={styles.viewIcon}
                          src={ticketSet.icon?.saved || ticketSet.icon?.value}
                          alt="Ticket Icon"
                        />
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </div>

                  {(ticketSet.ticketSetType?.saved === "main" ||
                    ticketSet.ticketSetType?.value === "main") && (
                    <div className={styles.row}>
                      <span className={styles.viewLabel}>Badges</span>
                      <span className={styles.viewValue}>
                        {renderViewBadges(ticketSet)}
                      </span>
                    </div>
                  )}

                  <div className={styles.body}>
                    <div className={styles.ticketTableHeaderV2}>
                      <span>Name</span>
                      <span>Price</span>
                      <span>Cancel Fee</span>
                      <span>ID</span>
                      <span>Accepted Credit Types</span>
                      <span>Credits Required</span>
                      <span>Groups</span>
                      <span>Dates</span>
                    </div>
                    <div className={styles.ticketTableBody}>
                      {ticketSet.tickets.map((ticket, index) => (
                        <div key={index} className={styles.viewTicketItem}>
                          <span>
                            {ticket.name.saved || ticket.name.value || "N/A"}
                          </span>
                          <span>
                            ${ticket.price.saved || ticket.price.value || "0"}{" "}
                            USD
                          </span>
                          <span>
                            {ticket.cancelFee.saved ||
                              ticket.cancelFee.value ||
                              "0"}
                          </span>
                          <span>
                            {ticket.commonItemId.saved ||
                              ticket.commonItemId.value ||
                              "N/A"}
                          </span>
                          <span>{renderViewAcceptedCreditTypes(ticket)}</span>
                          <span>{renderViewCreditsRequired(ticket)}</span>
                          <span>{renderViewGroups(ticket)}</span>
                          <span>{renderViewDates(ticket)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </Card>
    );
  }

  return (
    <Card
      children={
        <section>
          <div className={styles.bold}>TICKETING</div>
          <hr className={styles.hr} />
          <div className={styles.ticketGroup}>
            {ticketSets
              .filter(
                (ticketSet) =>
                  (ticketSet.ticketSetType.saved ??
                    ticketSet.ticketSetType.value) === "main"
              )
              .map((ticketSet, index) => (
                <TicketGroup
                  key={ticketSet.id}
                  ticketSet={ticketSet}
                  setTicketSet={(newData) => handleSetTicketSet(newData)}
                  removeTicketSet={() => handleRemoveTicketSet(ticketSet)}
                  isMajorV1={false}
                  isV2={true}
                  isLocalV2={isLocal}
                  onAddTicket={() => handleAddTicket(ticketSet.id)}
                  homesitesGroups={homesitesGroups}
                  isSummerConference={isSummerConference}
                  isPublished={isPublished}
                  isVirtual={isVirtual}
                />
              ))}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="ticketSets">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.ticketGroup}
                >
                  {ticketSets
                    .filter(
                      (ticketSet) =>
                        (ticketSet.ticketSetType.saved ??
                          ticketSet.ticketSetType.value) === "sub"
                    )
                    .sort((a, b) => {
                      const sortA = a.sort.saved ?? a.sort.value;
                      const sortB = b.sort.saved ?? b.sort.value;

                      return sortA - sortB;
                    })
                    .map((ticketSet, index) => (
                      <>
                        {!ticketSet.id ? (
                          <Loader />
                        ) : (
                          <>
                            <Draggable
                              key={ticketSet.id || index}
                              draggableId={ticketSet.id?.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TicketGroup
                                    key={`ticketGroup-${ticketSet.id || index}`}
                                    ticketSet={ticketSet}
                                    setTicketSet={(newData) =>
                                      handleSetTicketSet(newData)
                                    }
                                    removeTicketSet={() =>
                                      handleRemoveTicketSet(ticketSet)
                                    }
                                    isMajorV1={false}
                                    isV2={true}
                                    isLocalV2={isLocal}
                                    onAddTicket={() =>
                                      handleAddTicket(ticketSet.id)
                                    }
                                    homesitesGroups={homesitesGroups}
                                    isPublished={isPublished}
                                    isVirtual={isVirtual}
                                  />
                                </div>
                              )}
                            </Draggable>
                          </>
                        )}
                      </>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            className={styles.addBtn}
            onClick={() => setShowAddTicketSetModal(true)}
          >
            <AddButton description={"Add New Ticket Set"} color="#9AB5BB" />
          </div>

          <Modal
            title="Add Ticket Set"
            isOpen={showAddTicketSetModal}
            onClose={() => {
              setName("");
              setShowAddTicketSetModal(false);
            }}
            children={
              <div className={styles.modal}>
                <div className={styles.description}>
                  Please write a name for the new Ticket Set
                </div>
                <div className={styles.inputItem}>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ticket Set Name"
                  />
                </div>
                <div className={styles.addButtonContainer}>
                  <Button
                    className={styles.addButton}
                    children={"Create"}
                    onClick={() => handleAddTicketSet()}
                  />
                </div>
              </div>
            }
          />
        </section>
      }
    />
  );
}

TicketingV2Form.propTypes = {
  /**Array of ticket group objects (name-String, available-Number, limit-String, ticketsPer-string, tickets-Array)  */
  ticketSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      available: PropTypes.number,
      setAvailable: PropTypes.func,
      limit: PropTypes.string,
      setLimit: PropTypes.shape({}),
      ticketsPer: PropTypes.string,
      setTicketsPer: PropTypes.shape({}),
      tickets: PropTypes.array,
      updateTickets: PropTypes.shape({}),
      eventStartDate: PropTypes.string,
    })
  ),
  /**Function that changes the ticket groups */
  setTicketGroups: PropTypes.func,
};
