import { useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import env from "shared/regionConfig";

const API_BASE_URL = env.apiBase || null;

export function useLocation(eventId, scanningLocations = []) {
  const { makeAuthedRequest } = Auth.useContainer();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scanningLocations) {
      setLocations(
        scanningLocations.map((l) => ({
          id: l.Id,
          description: l.Description,
        }))
      );
    }
  }, [scanningLocations]);

  async function fetchUpdatedLocations() {
    try {
      const url = `/v1/events/scanner/event/id/${eventId}`;
      const response = await makeAuthedRequest(`${API_BASE_URL}${url}`, {
        method: "GET",
        headers: { Accept: "application/json" },
      });

      if (response.ok) {
        const updatedEvent = await response.json();
        setLocations(
          updatedEvent.scanningLocations.map((l) => ({
            id: l.Id,
            description: l.Description,
          }))
        );
      }
    } catch (error) {}
  }

  async function createLocation(description) {
    if (!description?.trim()) return;

    const eventData = { eventId, description };

    try {
      const url = "/v1/events/location";
      const response = await makeAuthedRequest(`${API_BASE_URL}${url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });

      if (response.ok) {
        await fetchUpdatedLocations();
      }
    } catch (error) {}
  }

  async function updateLocation(locationId, description) {
    if (!locationId || !description?.trim()) return;

    const eventData = { id: locationId, eventId, description };

    try {
      const url = "/v1/events/location";
      const response = await makeAuthedRequest(`${API_BASE_URL}${url}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });

      if (response.ok) {
        await fetchUpdatedLocations();
      }
    } catch (error) {}
  }

  async function removeLocation(id) {
    try {
      const url = `/v1/events/location/${id}`;
      const response = await makeAuthedRequest(`${API_BASE_URL}${url}`, {
        method: "DELETE",
        headers: { Accept: "application/json" },
      });

      if (response.ok) {
        setLocations((prev) => prev.filter((l) => l.id !== id));
      }
    } catch (error) {}
  }

  return {
    locations,
    loading,
    setLoading,
    fetchUpdatedLocations,
    createLocation,
    updateLocation,
    removeLocation,
  };
}
