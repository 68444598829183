import styles from "./index.module.css";
import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Link from "shared/Link";
import Card from "shared/Card";

import { RECENT_EVENTS_IBOS_KEY } from "../index";
import { descendingBy } from "shared/@utils/sort";
import { useMount } from "react-use";

dayjs.extend(relativeTime);

export default function NoSelection() {
  const [recents, setRecents] = useState([]);

  useMount(() => {
    setRecents(
      JSON.parse(sessionStorage.getItem(RECENT_EVENTS_IBOS_KEY) || "[]").sort(
        descendingBy("timestamp")
      )
    );
  });

  // console.log(JSON.parse(sessionStorage.getItem(RECENT_EVENTS_IBOS_KEY)));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>No IBO selected</h1>
        <p>But you can use the search box above to find one.</p>
      </div>

      <Card className={styles.tips}>
        <header>
          <h3>Search Tips</h3>
        </header>

        <section>
          <h4 className={styles.tipLabel}>Search by name</h4>
          <p className={styles.tipText}>
            Enter <code>{"contact:<name>"}</code> or just the name.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by IBO number</h4>
          <p className={styles.tipText}>
            Enter <code>{"ibo:<number>"}</code> or just an IBO number.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by email address</h4>
          <p className={styles.tipText}>
            Enter <code>{"email:<address>"}</code> or just an email address.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by phone number</h4>
          <p className={styles.tipText}>
            Enter <code>{"phone:<number>"}</code>, for example:{" "}
            <code>{"phone:2223334444"}</code>.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by Kate number</h4>
          <p className={styles.tipText}>
            Enter <code>{"kate:<number>"}</code>, for example:{" "}
            <code>{"kate:2223334444"}</code>.
          </p>
        </section>
      </Card>

      {recents.length > 0 && (
        <section className={styles.recents}>
          <header>
            <h2>Recently Viewed</h2>
          </header>

          <ol className={styles.recentsList}>
            {recents.map((item) => (
              <li key={item.iboNumber}>
                <Link to={`/events/orders/legacy/ibo/${item.iboNumber}`}>
                  {item.iboNumber} {item.name}
                </Link>{" "}
                ({dayjs(item.timestamp).fromNow()})
              </li>
            ))}
          </ol>
        </section>
      )}
    </div>
  );
}
