import React from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import InputItem from "Events/Components/InputItem";
import TextArea from "shared/@forms/TextArea";
import Button from "shared/Button";
import AddButton from "Events/Components/AddButton";
import styles from "./ADAV2Form.module.css";
import { ImagePicker } from "Events/Components/ImagePicker/ImagePicker";

export function ADAV2Form({ entries, setEntries }) {
  const getFieldValue = (field) => {
    if (!field) return "";
    return field.saved ?? field.value ?? "";
  };

  const handleTextAreaChange = (newValue, entry) => {
    const newEntries = entries.map((e) =>
      e.id === entry.id
        ? {
            ...e,
            param1: {
              ...e.param1,
              saved: newValue,
            },
          }
        : e
    );
    setEntries(newEntries);
  };

  const handleIconChange = (icon, entry) => {
    if (!icon || typeof icon === "string") {
      // Handle existing Base64 string or null values directly
      const newEntries = entries.map((e) =>
        e.id === entry.id
          ? {
              ...e,
              icon: {
                ...e.icon,
                saved: icon || e.icon?.saved || null,
              },
            }
          : e
      );
      // setEntries(newEntries); //Calls on page load - base
      return;
    }

    // If it's a file, convert it to Base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof Blob)) {
          reject(new TypeError("The provided file is not a Blob or File."));
          return;
        }
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    convertToBase64(icon)
      .then((base64Image) => {
        const newEntries = entries.map((e) =>
          e.id === entry.id
            ? {
                ...e,
                icon: {
                  ...e.icon,
                  saved: base64Image,
                },
              }
            : e
        );
        setEntries(newEntries);
      })
      .catch((error) => {
        console.error("Error converting icon to Base64:", error);
      });
  };

  const handleInputChange = (newValue, paramName, entryId) => {
    const updatedEntry = entries.find((entry) => entry.id === entryId);
    const updatedParam = {
      ...updatedEntry[paramName],
      saved: newValue,
    };

    setEntries(
      entries.map((e) =>
        e.id === entryId
          ? {
              ...e,
              [paramName]: updatedParam,
            }
          : e
      )
    );
  };

  const handleOrderChange = (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex || destinationIndex == null) return;

    const entriesClone = [...entries];

    // Identify the description entry
    const descriptionIndex = entriesClone.findIndex(
      (entry) => entry.component?.value === "description"
    );

    // Prevent moving the description entry
    if (
      sourceIndex === descriptionIndex ||
      destinationIndex === descriptionIndex
    ) {
      console.warn("Description entry order cannot be changed.");
      return;
    }

    // Remove the dragged item from the array
    const [movedItem] = entriesClone.splice(sourceIndex, 1);

    // Insert the dragged item at the new index
    entriesClone.splice(destinationIndex, 0, movedItem);

    // Reassign order values and update `order.saved` for all entries
    const updatedEntries = entriesClone.map((entry, index) => ({
      ...entry,
      order: {
        ...entry.order,
        saved: index, // Update the `order.saved` field
      },
    }));

    setEntries(updatedEntries);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return; // If dropped outside a valid drop zone
    handleOrderChange(source.index, destination.index);
  };

  const addNew = () => {
    // Temporary entry until backend assigns an ID
    const newEntry = {
      component: { saved: "Checkbox" },
      icon: { saved: null },
      order: { saved: entries.length },
      param1: { saved: "" },
      param2: { saved: "" },
      id: null,
    };

    // Update state with the new entry
    setEntries([...entries, newEntry], true);
  };

  const removeEntry = (entry) => {
    const newEntries = entries.filter((e) => e.id !== entry.id);
    setEntries(newEntries);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return "";
    if (str == "asl") return "ASL";
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const renderParams = (entry) => {
    if (entry.component?.value === "description") {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItemDescription}>
            <div className={styles.type}></div>
            <div className={styles.iconContainer} />
            <div className={styles.fieldDescription}>
              <TextArea
                value={getFieldValue(entry.param1)}
                onInput={(e) => handleTextAreaChange(e.target.value, entry)}
                placeholder="Click to type"
              />
            </div>
          </div>
        </div>
      );
    } else if (entry.component?.value == "mobility") {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>
              Option 1 {entry?.component?.required ? " *" : ""}{" "}
            </div>
            <div className={styles.iconContainer} />
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param1)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry.id)
                }
              />
            </div>
          </div>
          <div className={styles.paramItem}>
            <div className={styles.type}>
              Option 2 {entry?.component?.required ? " *" : ""}{" "}
            </div>
            <div className={styles.iconContainer} />
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param2)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param2", entry.id)
                }
              />
            </div>
          </div>
        </div>
      );
    } else if (
      entry.component?.value == "checkbox" ||
      entry.component?.saved == "checkbox"
    ) {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>
              Text {entry?.component?.required ? " *" : ""}{" "}
            </div>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, entry)}
                isADA={true}
                imgSrc={getFieldValue(entry.icon)}
              />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param1)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry.id)
                }
              />
            </div>
          </div>
          <div className={styles.paramItem}>
            <div className={styles.type}>Sub-Text</div>
            <div className={styles.iconContainer} />
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param2)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param2", entry.id)
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>
              Text {entry.param1?.required ? " *" : ""}{" "}
            </div>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, entry)}
                isADA={true}
                imgSrc={getFieldValue(entry.icon)}
              />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param1)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry.id)
                }
              />
            </div>
          </div>
          <div className={styles.paramItem}>
            <div className={styles.type}>Sub-Text</div>
            <div className={styles.iconContainer} />
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Click to type"}
                value={getFieldValue(entry.param2)}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param2", entry.id)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Card
        children={
          <section className={styles.container}>
            <div className={styles.title}>ADA</div>
            <hr className={styles.hr} />
            <Droppable droppableId="entries">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.entriesContainer}
                >
                  {entries
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((entry, index) => (
                      <Draggable
                        key={entry.id}
                        draggableId={entry.id?.toString()}
                        index={index}
                        isDragDisabled={
                          entry.component?.value === "description"
                          // || entry.order === null
                        }
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className={styles.entry}>
                              <div className={styles.entryTitle}>
                                {capitalizeFirstLetter(
                                  entry.component?.saved ??
                                    entry.component?.value
                                )}{" "}
                                {entry.component?.value === "description"
                                  ? "*"
                                  : ""}
                              </div>
                              <div className={styles.entryParamsContainer}>
                                {renderParams(entry)}
                              </div>
                              <div
                                className={styles.entryRemoveButtonContainer}
                              >
                                {entry.component?.value !== "description" && (
                                  <Button
                                    children="Remove"
                                    className={styles.removeButton}
                                    onClick={() => removeEntry(entry)}
                                    size="small"
                                  />
                                )}
                              </div>
                            </div>
                            <hr className={styles.hr} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <AddButton
              description="Add another ADA Option"
              color="#9AB5BB"
              onClick={() => addNew()}
            />
          </section>
        }
      />
    </DragDropContext>
  );
}

ADAV2Form.propTypes = {
  /**Array of objects in the format:
   * [
   *    {
   *        question: "do you require x y z?",
   *        options: ["yes", "no", "etc"],
   *        id: 1 (unique id per question),
   *    }, and so on
   * ]
   */
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function to handle setting the entries */
  setEntries: PropTypes.func,
};
