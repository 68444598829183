import React, { useEffect, useMemo, useState, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./CartItemNew.module.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 } from "uuid";

import Select from "Events/Components/Select";
import Loader from "shared/Loader";
import Checkbox from "shared/@forms/Checkbox";

import SpecialNeedsModalNew from "Events/Components/SpecialNeedsModalNew";
import { useOrderInfo } from "./useCartItem";

/**
 * Interactive component.
 * Allows the user to select/purchase tickets for a specific event.
 */
export function CartItemNew({
  event,
  id,
  iboNumber,
  setCartLoading,
  setTicketWarnings,
  //setCartWarnings,
  setSubtotal,
  // setTax,
  setCreditCards,
  setShowCreditCards,
  setShowButtons,
  setDisplayAddToCart,
  itemTotalPrices,
  setItemTotalPrices,
  doRefetch,
  updateTicketData,
  updateTicketWarnings,
  testTicket,
  setTestTicket,
  userOptions,
  setADA,
}) {
  const {
    data: orderInfoData,
    isLoading: orderInfoLoading,
    refetch: orderInfoRefetch,
  } = useOrderInfo(iboNumber, id);

  const [iboA, setIboA] = useState({ ...userOptions.iboA, adaValues: [] });
  const [iboB, setIboB] = useState({ ...userOptions.iboA, adaValues: [] });
  useEffect(() => {
    setIboA({ ...userOptions.iboA, adaValues: iboA.adaValues || [] });
    setIboB({ ...userOptions.iboB, adaValues: iboB.adaValues || [] });
  }, [userOptions]);

  useEffect(() => {
    if (orderInfoData) {
      setCartLoading(orderInfoLoading);
    }
  }, [orderInfoData]);

  const [isRefetchComplete, setIsRefetchComplete] = useState(false);
  useEffect(() => {
    setCartLoading(true);

    orderInfoRefetch().then(() => {
      setCartLoading(false);
      setIsRefetchComplete(true);
    });
  }, [id, doRefetch]);

  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    if (tickets && tickets.length !== 0) {
      updateTicketData(id, tickets);
    }
  }, [tickets]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [warnings, setWarnings] = useState([]);

  const [taxRate, setTaxRate] = useState(0);
  // const [creditCards, setCreditCards] = useState([]);
  const [ticketQuantity, setTicketQuantity] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [prevSubtotal, setPrevSubtotal] = useState(0);
  const [prevTax, setPrevTax] = useState(0);

  useEffect(() => {
    if (tickets.length > 0) {
      const newSubtotal = calculateSubtotal();
      // const newTax = (newSubtotal * taxRate) / 100;

      const subtotalDiff = newSubtotal - prevSubtotal;
      // const taxDiff = newTax - prevTax;

      setSubtotal((prevSubtotal) => prevSubtotal + subtotalDiff);
      // setTax((prevTax) => prevTax + taxDiff);

      setPrevSubtotal(newSubtotal);
      // setPrevTax(newTax);

      setItemTotalPrices((prevItemTotalPrices) =>
        prevItemTotalPrices.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              price: newSubtotal, // Update price with the new subtotal
              taxRate: orderInfoData.taxRate,
            };
          }
          return item;
        })
      );
    }
  }, [tickets, taxRate, id]);

  useEffect(() => {
    if (orderInfoData && isRefetchComplete) {
      if (orderInfoData.error && orderInfoData.error.message) {
        updateTicketWarnings(
          id,
          { id: "AddingError", message: orderInfoData.error.message },
          "add"
        );
      } else {
        setTicketOptions(
          orderInfoData.tickets.map((ticket) => ({
            id: ticket.id,
            name: ticket.title,
            price: ticket.amount,
            warning: ticket.warning,
            taxRate: ticket.taxRate || orderInfoData.taxRate || 0,
            ticketId: ticket.ticketId,
            tandemTicketId: ticket.tandemTicketId || null,
            tandemEventId: ticket.tandemEventId || null,
            eventId: ticket.eventId || null,
          }))
        );

        if (event.ticketData.length > 0) {
          setTickets(event.ticketData);
        }

        if (tickets.length < 1 && event.ticketData.length < 1) {
          addNewTicketType(iboA.contactPK, iboB.contactPK);
        }

        const warningsWithId = orderInfoData.warnings.map((warning) => ({
          id: null,
          message: warning || "",
          cartItemId: id,
        }));

        updateTicketWarnings(id, undefined, "clear");

        warningsWithId.forEach((warning) => {
          updateTicketWarnings(id, warning, "add");
        });

        setIsRefetchComplete(false);

        // setTax(orderInfoData.taxRate);
        setCreditCards((prevCreditCards) => [
          ...prevCreditCards,
          {
            id: id,
            creditCards: orderInfoData.creditCards,
          },
        ]);

        setItemTotalPrices((prev) => [
          ...prev,
          {
            id: id,
            price: 0,
            taxRate: orderInfoData.taxRate,
          },
        ]);
      }
    }
  }, [orderInfoData, isRefetchComplete]);

  const [addTicket, setAddTicket] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accomodations, setAccommodations] = useState([]);
  const [ticketType, setTicketType] = useState("");

  useEffect(() => {
    if (addTicket) {
      setTimeout(() => setAddTicket(false), 500);
    }
  }, [addTicket]);

  const currentKeyRef = useRef();

  const handleOptionChange = (option) => {
    setAccommodations((prev) => {
      if (prev.includes(option)) {
        return prev.filter((opt) => opt !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const addNewTicketType = (contactId, contactIdB) => {
    const newTicket = {
      id: v4(),
      name: "",
      quantity: 1,
      price: 0,
      adaValues: [],
      addAccommodation: false,
      ticketId: "",
      eventId: null,
      tandemEventId: null,
      tandemTicketId: null,
      contactId: contactId,
    };

    if (contactIdB) {
      const newTicketB = {
        id: v4(),
        name: "",
        quantity: 1,
        price: 0,
        adaValues: [],
        addAccommodation: false,
        ticketId: "",
        eventId: null,
        tandemEventId: null,
        tandemTicketId: null,
        contactId: contactIdB,
      };
      setTickets((prevTickets) => [...prevTickets, newTicket, newTicketB]);
    } else {
      setTickets((prevTickets) => [...prevTickets, newTicket]);
    }

    setDisplayAddToCart(id, false);
  };

  const calculatePrice = (ticket) => {
    const price = parseFloat(ticket.price);
    const qty = parseInt(ticket.quantity, 10);

    if (isNaN(price) || isNaN(qty) || qty <= 0) return "0.00";

    const totalPrice = (price * qty).toFixed(2);
    return totalPrice;
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedTickets = tickets.map((ticket, i) =>
      i === index ? { ...ticket, quantity } : ticket
    );

    setTickets(updatedTickets);
  };

  const [modalState, setModal] = useState({ visible: false, children: [] });

  function handleSave(newIBOInfo, contactId) {
    if (contactId == iboA.contactPK) {
      setIboA({
        ...newIBOInfo,
        showAdaModal: false,
        adaValues: newIBOInfo.adaValues,
      });
      setADA({
        iboA: {
          ...newIBOInfo,
          showAdaModal: false,
          adaValues: newIBOInfo.adaValues,
        },
        iboB: iboB,
      });
    } else {
      setIboB({
        ...newIBOInfo,
        showAdaModal: false,
        adaValues: newIBOInfo.adaValues,
      });
      setADA({
        iboA: iboA,
        iboB: {
          ...newIBOInfo,
          showAdaModal: false,
          adaValues: newIBOInfo.adaValues,
        },
      });
    }

    setOpenModal(false);
  }

  function onModalCloseClick(contactId) {
    if (contactId == iboA.contactPK) {
      setIboA({ ...iboA, showAdaModal: false });
    } else {
      setIboB({ ...iboB, showAdaModal: false });
    }

    setOpenModal(false);
  }

  const handleTicketChange = (index, updatedTicket) => {
    // Step 1: Update the ticket at the given index
    const updatedTickets = tickets.map((ticket, idx) =>
      idx === index ? updatedTicket : ticket
    );

    // Step 2: Filter out tickets with a quantity of 0
    const filteredTickets = updatedTickets.filter(
      (ticket) => ticket.quantity > 0
    );

    // Step 3: Update the state with the filtered tickets
    return filteredTickets;
  };

  const handleRemoveTicket = (index, ibo) => {
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.filter((_, i) => i !== index);

      const allTicketsSelected = updatedTickets.every(
        (ticket) => ticket.name && ticket.name !== "Select Ticket Type"
      );

      setDisplayAddToCart(id, allTicketsSelected);

      return updatedTickets;
    });

    updateTicketWarnings(id, { id: tickets[index].ticketId }, "delete");
  };

  const handleAddAccommodation = (contactId) => {
    if (contactId == iboA.contactPK) {
      setIboA({ ...iboA, showAdaModal: true });
    } else {
      setIboB({ ...iboB, showAdaModal: true });
    }

    setOpenModal(true);
  };

  const calculateSubtotal = () => {
    return tickets.reduce((total, ticket) => {
      const price = parseFloat(ticket.price) || 0;
      const quantity = parseInt(ticket.quantity, 10) || 0;
      return total + price * quantity;
    }, 0);
  };

  return (
    <>
      {!event?.ticketWarnings.find(
        (warning) => warning.id == "AddingError"
      ) && (
        <>
          <div className={styles.container}>
            <div className={styles.event}>
              <p className={styles.eventName}>{event.eventName}</p>
              <p>{event.location}</p>
              <p>{event.dates}</p>
            </div>
            <div className={styles.iboContainer}>
              <div className={styles.ibo}>
                <p className={styles.iboAName}>
                  IBO A: {userOptions.iboA?.firstName}{" "}
                  {userOptions.iboA?.lastName}
                </p>

                <div className={styles.accommodation}>
                  {iboA.adaValues.length > 0 && (
                    <span className={styles.light}>
                      Accommodation Requested:
                    </span>
                  )}

                  {iboA.adaValues.length > 0 &&
                    iboA.adaValues.map((label) => (
                      <div className={styles.list}>
                        <span className={styles.light}>
                          {label.parameter1 || label.parameter2}
                        </span>
                      </div>
                    ))}
                  {iboA.showAdaModal && openModal && (
                    <div className={styles.modalOverlay}>
                      <div className={styles.specialNeedsModal}>
                        <SpecialNeedsModalNew
                          ticket={iboA}
                          onCancel={() => onModalCloseClick(iboA.contactPK)}
                          onSave={(iboInfo) =>
                            handleSave(iboInfo, iboA.contactPK)
                          }
                          iboNum={iboNumber}
                          adaDescription={orderInfoData.adaDescription}
                          adaOptions={orderInfoData.adaOptions}
                          eventId={event.eventId}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {orderInfoData && orderInfoData.adaOptions?.length > 0 && (
                  <div className={classNames(styles["accomodation-checkbox"])}>
                    <label htmlFor={`add-accomodation-checkbox-iboA`} hidden>
                      Add Accommodation
                    </label>
                    <Checkbox
                      className={styles.checkbox}
                      name={`add-accomodation-checkbox-iboA`}
                      checked={iboA.showAdaModal || false}
                      onChange={() => handleAddAccommodation(iboA.contactPK)}
                    />

                    <span className={styles.light}>Add Accommodation</span>
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  styles["list-container"],
                  tickets.length > 0 ? styles["has-ticket"] : ""
                )}
              >
                {tickets.filter((tkt) => tkt.contactId == iboA.contactPK)
                  .length > 0 && (
                  <TransitionGroup component="ul">
                    {tickets.map((ticket, index) => (
                      <CSSTransition
                        timeout={300}
                        classNames={{
                          enterActive: styles["fade-enter-active"],
                          enter: styles["fade-enter"],
                          exit: styles["fade-exit"],
                          exitActive: styles["fade-exit-active"],
                        }}
                        key={index}
                      >
                        <>
                          {tickets[index]?.contactId == iboA.contactPK && (
                            <div className={styles["ticket-container"]}>
                              <label
                                htmlFor={`ticket-quantity-${index}`}
                                hidden
                              >
                                Ticket Quantity {index}
                              </label>
                              <div
                                className={classNames(
                                  styles["number-input-container"]
                                )}
                              >
                                <input
                                  className={styles["number-input"]}
                                  name={`ticket-quantity-${index}`}
                                  type="number"
                                  min={1}
                                  value={tickets[index].quantity}
                                  onClick={(e) => e.target.select()}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      index,
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                  onKeyDown={({ key }) =>
                                    (currentKeyRef.current = key)
                                  }
                                />
                              </div>
                              <label htmlFor={`ticket-input-${index}`} hidden>
                                Select Ticket Type {index}
                              </label>
                              <div className={styles.tickets}>
                                <Select
                                  options={
                                    ticketOptions.map((option) => option.name)
                                    // .filter(
                                    //   (optionName) =>
                                    //     !tickets.some((ticket) => ticket.name === optionName)
                                    // )
                                  }
                                  onChange={(selectedTypeName) => {
                                    const selectedIndex =
                                      ticketOptions.findIndex(
                                        (option) =>
                                          option.name === selectedTypeName
                                      );

                                    if (selectedIndex === -1) {
                                      console.error(
                                        "Selected type not found:",
                                        selectedTypeName
                                      );
                                      return;
                                    }

                                    const selectedPrice = parseFloat(
                                      ticketOptions[selectedIndex].price
                                    );
                                    const selectedWarning =
                                      ticketOptions[selectedIndex].warning;
                                    const selectedTicketId =
                                      ticketOptions[selectedIndex].ticketId;
                                    const selectedEventId =
                                      ticketOptions[selectedIndex].eventId;
                                    const selectedTandemTicketId =
                                      ticketOptions[selectedIndex]
                                        .tandemTicketId;
                                    const selectedTandemEventId =
                                      ticketOptions[selectedIndex]
                                        .tandemEventId;

                                    const updatedTicket = {
                                      ...tickets[index],
                                      name: selectedTypeName,
                                      price: selectedPrice,
                                      ticketId: selectedTicketId,
                                      eventId: selectedEventId,
                                      tandemTicketId: selectedTandemTicketId,
                                      tandemEventId: selectedTandemEventId,
                                      quantity: tickets[index].quantity
                                        ? tickets[index].quantity
                                        : 1,
                                    };

                                    const updatedTickets = tickets.map(
                                      (ticket, i) =>
                                        i === index ? updatedTicket : ticket
                                    );

                                    setTickets(updatedTickets);

                                    setTestTicket({
                                      cartItemId: id,
                                      id: selectedTicketId,
                                      message:
                                        ticketOptions[selectedIndex].warning,
                                    });

                                    if (
                                      selectedTypeName !== "Select Ticket Type"
                                    ) {
                                      setShowCreditCards(true);
                                      setShowButtons(true);
                                    }

                                    const allTicketsSelected =
                                      updatedTickets.every(
                                        (ticket) =>
                                          // ticket.contactId == iboA.contactPK &&
                                          ticket.name &&
                                          ticket.name !== "Select Ticket Type"
                                      );

                                    setDisplayAddToCart(id, allTicketsSelected);
                                  }}
                                  placeholder={
                                    tickets[index]?.name || "Select Ticket Type"
                                  }
                                  name={`ticket-input-${index}`}
                                />
                              </div>

                              <div className={styles["price-remove-container"]}>
                                <span
                                  className={classNames({
                                    [styles.hidden]: !tickets[index].name,
                                  })}
                                >
                                  $
                                  {isNaN(calculatePrice(tickets[index]))
                                    ? 0
                                    : calculatePrice(tickets[index])}
                                </span>
                              </div>

                              <div className={styles.removeContainer}>
                                {
                                  //   .filter(
                                  //   (tkt) => tkt.contactId == iboA.contactPK
                                  // )
                                  tickets.length > 1 && (
                                    <button
                                      className={styles.remove}
                                      onClick={() =>
                                        handleRemoveTicket(index, iboA)
                                      }
                                    >
                                      Remove
                                    </button>
                                  )
                                }
                              </div>
                            </div>
                          )}
                        </>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                )}

                <div
                  className={
                    tickets.filter((tkt) => tkt.contactId == iboA.contactPK)
                      .length > 1
                      ? styles["add-ticket-checkbox"]
                      : tickets.filter((tkt) => tkt.contactId == iboA.contactPK)
                          .length == 0
                      ? styles["add-ticket-checkbox-alt2"]
                      : styles["add-ticket-checkbox-alt"]
                  }
                >
                  <label htmlFor="add-ticket-checkbox" hidden>
                    Add Ticket
                  </label>
                  <Checkbox
                    className={styles.checkbox}
                    name="add-ticket-checkbox"
                    checked={false}
                    onChange={() => {
                      if (addTicket === false) {
                        setAddTicket(true);
                        addNewTicketType(iboA.contactPK);
                      }
                    }}
                  />
                  <span className={styles.light}>Add Ticket</span>
                </div>
              </div>
            </div>

            {iboB.contactPK && (
              <div className={styles.iboContainer}>
                <div className={styles.ibo}>
                  <p className={styles.iboBName}>
                    IBO B: {userOptions.iboB?.firstName}{" "}
                    {userOptions.iboB?.lastName}
                  </p>

                  <div className={styles.accommodation}>
                    {iboB.adaValues.length > 0 && (
                      <span className={styles.light}>
                        Accommodation Requested:
                      </span>
                    )}

                    {iboB.adaValues.length > 0 &&
                      iboB.adaValues.map((label) => (
                        <div className={styles.list}>
                          <span className={styles.light}>
                            {label.parameter1 || label.parameter2}
                          </span>
                        </div>
                      ))}
                    {iboB.showAdaModal && openModal && (
                      <div className={styles.modalOverlay}>
                        <div className={styles.specialNeedsModal}>
                          <SpecialNeedsModalNew
                            ticket={iboB}
                            onCancel={() => onModalCloseClick(iboB.contactPK)}
                            onSave={(iboInfo) =>
                              handleSave(iboInfo, iboB.contactPK)
                            }
                            iboNum={iboNumber}
                            adaDescription={orderInfoData.adaDescription}
                            adaOptions={orderInfoData.adaOptions}
                            eventId={event.eventId}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {orderInfoData && orderInfoData.adaOptions?.length > 0 && (
                    <div
                      className={classNames(styles["accomodation-checkbox"])}
                    >
                      <label htmlFor={`add-accomodation-checkbox-iboB`} hidden>
                        Add Accommodation
                      </label>
                      <Checkbox
                        className={styles.checkbox}
                        name={`add-accomodation-checkbox-iboB`}
                        checked={iboB.showAdaModal || false}
                        onChange={() => handleAddAccommodation(iboB.contactPK)}
                      />

                      <span className={styles.light}>Add Accommodation</span>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    styles["list-container"],
                    tickets.length > 0 ? styles["has-ticket"] : ""
                  )}
                >
                  {tickets.filter((tkt) => tkt.contactId == iboB.contactPK)
                    .length > 0 && (
                    <TransitionGroup component="ul">
                      {tickets.map((ticket, index) => (
                        <CSSTransition
                          timeout={300}
                          classNames={{
                            enterActive: styles["fade-enter-active"],
                            enter: styles["fade-enter"],
                            exit: styles["fade-exit"],
                            exitActive: styles["fade-exit-active"],
                          }}
                          key={index}
                        >
                          <>
                            {tickets[index]?.contactId == iboB.contactPK && (
                              <div className={styles["ticket-container"]}>
                                <label
                                  htmlFor={`ticket-quantity-${index}`}
                                  hidden
                                >
                                  Ticket Quantity {index}
                                </label>
                                <div
                                  className={classNames(
                                    styles["number-input-container"]
                                  )}
                                >
                                  <input
                                    className={styles["number-input"]}
                                    name={`ticket-quantity-${index}`}
                                    type="number"
                                    min={1}
                                    value={tickets[index].quantity}
                                    onClick={(e) => e.target.select()}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        index,
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                    onKeyDown={({ key }) =>
                                      (currentKeyRef.current = key)
                                    }
                                  />
                                </div>
                                <label htmlFor={`ticket-input-${index}`} hidden>
                                  Select Ticket Type {index}
                                </label>
                                <div className={styles.tickets}>
                                  <Select
                                    options={
                                      ticketOptions.map((option) => option.name)
                                      // .filter(
                                      //   (optionName) =>
                                      //     !tickets.some((ticket) => ticket.name === optionName)
                                      // )
                                    }
                                    onChange={(selectedTypeName) => {
                                      const selectedIndex =
                                        ticketOptions.findIndex(
                                          (option) =>
                                            option.name === selectedTypeName
                                        );

                                      if (selectedIndex === -1) {
                                        console.error(
                                          "Selected type not found:",
                                          selectedTypeName
                                        );
                                        return;
                                      }

                                      const selectedPrice = parseFloat(
                                        ticketOptions[selectedIndex].price
                                      );
                                      const selectedWarning =
                                        ticketOptions[selectedIndex].warning;
                                      const selectedTicketId =
                                        ticketOptions[selectedIndex].ticketId;
                                      const selectedEventId =
                                        ticketOptions[selectedIndex].eventId;
                                      const selectedTandemTicketId =
                                        ticketOptions[selectedIndex]
                                          .tandemTicketId;
                                      const selectedTandemEventId =
                                        ticketOptions[selectedIndex]
                                          .tandemEventId;

                                      const updatedTicket = {
                                        ...tickets[index],
                                        name: selectedTypeName,
                                        price: selectedPrice,
                                        ticketId: selectedTicketId,
                                        eventId: selectedEventId,
                                        tandemTicketId: selectedTandemTicketId,
                                        tandemEventId: selectedTandemEventId,
                                        quantity: tickets[index].quantity
                                          ? tickets[index].quantity
                                          : 1,
                                      };

                                      const updatedTickets = tickets.map(
                                        (ticket, i) =>
                                          i === index ? updatedTicket : ticket
                                      );

                                      setTickets(updatedTickets);

                                      setTestTicket({
                                        cartItemId: id,
                                        id: selectedTicketId,
                                        message:
                                          ticketOptions[selectedIndex].warning,
                                      });

                                      if (
                                        selectedTypeName !==
                                        "Select Ticket Type"
                                      ) {
                                        setShowCreditCards(true);
                                        setShowButtons(true);
                                      }

                                      const allTicketsSelected =
                                        updatedTickets.every(
                                          (ticket) =>
                                            // ticket.contactId == iboB.contactPK &&
                                            ticket.name &&
                                            ticket.name !== "Select Ticket Type"
                                        );

                                      setDisplayAddToCart(
                                        id,
                                        allTicketsSelected
                                      );
                                    }}
                                    placeholder={
                                      tickets[index]?.name ||
                                      "Select Ticket Type"
                                    }
                                    name={`ticket-input-${index}`}
                                  />
                                </div>

                                <div
                                  className={styles["price-remove-container"]}
                                >
                                  <span
                                    className={classNames({
                                      [styles.hidden]: !tickets[index].name,
                                    })}
                                  >
                                    $
                                    {isNaN(calculatePrice(tickets[index]))
                                      ? 0
                                      : calculatePrice(tickets[index])}
                                  </span>
                                </div>

                                <div className={styles.removeContainer}>
                                  {
                                    //   .filter(
                                    //   (tkt) => tkt.contactId == iboB.contactPK
                                    // )
                                    tickets.length > 1 && (
                                      <button
                                        className={styles.remove}
                                        onClick={() =>
                                          handleRemoveTicket(index, iboB)
                                        }
                                      >
                                        Remove
                                      </button>
                                    )
                                  }
                                </div>
                              </div>
                            )}
                          </>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  )}

                  <div
                    className={
                      tickets.filter((tkt) => tkt.contactId == iboB.contactPK)
                        .length > 1
                        ? styles["add-ticket-checkbox"]
                        : tickets.filter(
                            (tkt) => tkt.contactId == iboB.contactPK
                          ).length == 0
                        ? styles["add-ticket-checkbox-alt2"]
                        : styles["add-ticket-checkbox-alt"]
                    }
                  >
                    <label htmlFor="add-ticket-checkbox" hidden>
                      Add Ticket
                    </label>
                    <Checkbox
                      className={styles.checkbox}
                      name="add-ticket-checkbox"
                      checked={false}
                      onChange={() => {
                        if (addTicket === false) {
                          setAddTicket(true);
                          addNewTicketType(iboB.contactPK);
                        }
                      }}
                    />
                    <span className={styles.light}>Add Ticket</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

CartItemNew.propTypes = {
  event: PropTypes.shape({
    eventName: PropTypes.string,
    location: PropTypes.string,
    ruleset: PropTypes.string,
    dates: PropTypes.string,
    ticketsInfo: PropTypes.shape({
      name: PropTypes.string,
      quantity: PropTypes.number,
      adaValues: PropTypes.number,
      type: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
  id: PropTypes.string,
  iboNumber: PropTypes.string,
  onRemove: PropTypes.func,
  getTicketInfo: PropTypes.func,
};
